// prettier-ignore-start
import ActionMessageForm    from "./Base/Forms/ActionMessageForm";
import ActionAppDataPayment from "./Base/ActionAppDataPayment";
import ActionFormType       from "./ActionFormType";


const ActionMessageFormAppDataPayment = () => {

    const data     = ActionAppDataPayment().data;
    const formCols = ActionAppDataPayment().formCols;

    const form = (props) => ActionMessageForm().form(props, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAppDataPayment;
