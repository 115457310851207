// prettier-ignore-start
import React from "react";

import {getPath, Rating, SystemRoles} from "../../../api/methods";
import { T }      from "../../../lang/ru";

import List       from "../../../components/Lists/List";
import LIF        from "../../../components/Lists/ListItemFormats";
import LIT        from "../../../components/Lists/ListItemTypes";
import {useHistory} from "react-router-dom";
import {PATHS} from "../../../constants/RoutesPaths";


const SettingsListsRatingsList = () => {

    const history = useHistory();

    const formatRole     = (id, item, itemProps) => item.role ? item.role.title + ' (' + item.role.sysname + ')' : '';
    const formatValueMax = (id, item, itemProps) => item.rating_scores
        ? item.rating_scores.reduce(
            (sumScore, score) => sumScore + score.values.reduce(
                (maxValue, value) => Math.max(maxValue, value.value),
                0
            ),
            0
        )
        : 0;

    const optionsRole = (success) => SystemRoles.select({}, ({data}) => success(data));


    return (
        <List
            title        = {'Рейтинги'}
            popupPrefix  = {'settingsRatings'}
            getListData  = {Rating.list}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'  , title: 'активен'                    , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0   , align: 'center' },
                {name: 'title'      , title: 'название'                   , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   , isValid: (val) => val !== "" },
                {name: 'description', title: 'описание'                   , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   },
                {name: 'sysname'    , title: 'системное имя'              , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   },
                {name: 'value_max'  , title: 'максимальная сумма рейтинга', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: 0   , align: 'center' },
                {name: 'value_max_2', title: 'расчетный максимум'         , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, default: null, align: 'center' , customFormatHandler: formatValueMax },
                {name: 'role_id'    , title: 'роль'                       , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true , default: null, align: 'left'   , getOptions: optionsRole, customFormatHandler: formatRole },

                {name: 'rating_scores_count', title: 'критериев'          , format: LIF.usageCounter},
            ]}

            actions = {[
                {title: 'критерии', onClick: (rating_id) => history.push(getPath(PATHS.settings.listsRatingScores, {rating_id}))},
                //{title: 'роли'    , onClick: (rating_id) => history.push(getPath(PATHS.settings.listsRatingRoles , {rating_id}))},
            ]}

            onNew        = {Rating.create}
            onEdit       = {Rating.update}
            onDelete     = {Rating.delete}

            //canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteRating}<b>{item?.title ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsRatingsList;
