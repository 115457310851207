// prettier-ignore-start
import React, { useEffect, useState } from 'react';

import { Application }              from '../../../api/methods';
import { useFilter }                from "../../../hooks/useFilter";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";
//import { formatDateToApi } from '../../../functions/common/common';

import { Preloader }                from "../../../components/UI-kit/Preloader/Preloader";
import { Filter }                   from './Filter/Filter';
import OrdersListTable              from './OrdersListTable';


const OrdersListFiltered = () => {

    const userAccess = useUserAccessRightChecks();
    const filters    = useFilter([
        'application_id',
        'unread_chat_msg',
        'status_id',
        'category_id',
        'executor_id',
        'expert_id',
        'user_id',
        'payer_id',
    ]);

    const [doFetch        , setDoFetch        ] = useState(false);

    const [pagination     , setPagination     ] = useState(null);
    const [isValidDateFrom, setIsValidDateFrom] = useState(true);
    const [isValidDateTo  , setIsValidDateTo  ] = useState(true);
    const [dateChanged    , setDateChanged    ] = useState(false);
    const [type           , setType           ] = useState('active');
    const [isLoading      , setIsLoading      ] = useState(false);
    const [isReady        , setIsReady        ] = useState(false);
    const [isListReady    , setIsListReady    ] = useState(false);
    const [reachedBottom  , setReachedBottom  ] = useState(false);
    const [appData        , setAppData        ] = useState([]);

    const [intervalDate   , setIntervalDate   ] = useState({
        dateFrom: '',
        dateTo  : '',
    });

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);

        return function () {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        setIsReady(isListReady && ! isLoading);
    }, [isListReady, isLoading]);

    useEffect(() => {
        if (doFetch) {
            fetchApplications();
            setDoFetch(false);
        }
    }, [doFetch]);

    useEffect(() => {
        const isValidDate          = isValidDateFrom && isValidDateTo;
        const isHasNextPage        = (! pagination) || (pagination && (! pagination.is_last) && (pagination.next_key !== null));
        const isScrolledToNextPage = reachedBottom && isHasNextPage;

        const isNeedFetchApplications = (
            (! isLoading) &&
            ((isValidDate && filters.isNeedToUpdate()) || isScrolledToNextPage)
        );

        if (isNeedFetchApplications) {
            if (filters.isNeedToUpdate()) {
                setAppData   ([]);
                setPagination(null);
            }

            if (! isScrolledToNextPage) setIsLoading(true);

            setDoFetch(true);
        }
    }, [dateChanged, type, filters.data, reachedBottom]);

    const scrollHandler = (e) => {
        const MINIMUM_DELTA_TO_BOTTOM = 200;
        const { scrollHeight, scrollTop } = e.target.documentElement;

        const userScrollDeltaToBottom = scrollHeight - (scrollTop + window.innerHeight);
        const isUserScrolledToBottom  = userScrollDeltaToBottom < MINIMUM_DELTA_TO_BOTTOM;

        if (isUserScrolledToBottom && !isLoading && !reachedBottom) setReachedBottom(true);
    };

    const fetchApplications = async () => {
        const pageAfterKey =
            pagination && pagination.next_key > 0 ? pagination.next_key : 0;

        const data = {
            application_id : filters.getValue('application_id' ),
            status_id      : filters.getValue('status_id'      ),
            expert_id      : filters.getValue('expert_id'      ),
            executor_id    : filters.getValue('executor_id'    ),
            user_id        : filters.getValue('user_id'        ),
            category_id    : filters.getValue('category_id'    ),
            payer_id       : filters.getValue('payer_id'       ),
            unread_chat_msg: filters.getValue('unread_chat_msg'),

            pageLimit      : 25,
            pageAfter      : pageAfterKey,
      /*
            start_time :
              intervalDate.dateFrom === ''
                ? '1920-11-10 00:00:00'
                : await formatDateToApi(intervalDate.dateFrom),
            end_time   :
              intervalDate.dateTo === ''
                ? '2090-11-10 00:00:00'
                : await formatDateToApi(intervalDate.dateTo),
      */
        };

        Application.list(data, getAllApplicationsSuccess);
    };

    const getAllApplicationsSuccess = ({ data }) => {
        setIsLoading    (false);
        setReachedBottom(false);
        setPagination   (data.pagination);

        setAppData((prev) => {
            return [...prev, ...data.data];
        });
    };


    return (<>
        {userAccess.isAllowAppListFilter && <Filter
            filtersInfo      = {filters.data}
            onChangeCheckbox = {filters.onChangeCheckbox}
            onChangeSelect   = {filters.onChangeSelect}
        />}

        {! isReady && <Preloader />}

        <OrdersListTable
            data     = {appData}
            setData  = {setAppData}
            ready    = {isReady}
            setReady = {setIsListReady}
        />
    </>);

};

export default OrdersListFiltered;

