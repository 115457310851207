// prettier-ignore-start
import React, { useState } from "react";

import { Application              } from "../../../api/methods";
import { isEmpty                  } from "../../../functions/common";
import { getFileExtension         } from "../../../functions/getFileExtension";
import { useUserAccessRightChecks } from "../../../hooks/useUserAccessRightChecks";

import FileHandler                  from "../../../components/File/FileHandler";
import { Empty                    } from "../../../components/Empty";
import Text                         from "../../../components/UI-kit/Text";


const OrderInfoSignedDocuments = ({applicationData}) => {

    const userAccess = useUserAccessRightChecks();

    const [isErrorUploadFile, setIsErrorUploadFile] = useState(false)

    const {
        id,
        contract_number,
        signed_contract,
        signed_act,
    } = applicationData;

    const contractFileName = isEmpty(signed_contract) ?  null : `Подписанный договор к заявке №${contract_number}.${getFileExtension(signed_contract)}`;
    const actFileName      = isEmpty(signed_act     ) ?  null : `Подписанный акт к заявке №${contract_number}.${getFileExtension(signed_act)}`;


    const doUpload = (data, success, fail, load) => {
        const handleSuccess = (res) => {
            success(res)
            setIsErrorUploadFile(false)
        }

        const handleFail = () => {
            if (fail) fail();
            setIsErrorUploadFile(true)
        }

        Application.loadSignedDocument(data, handleSuccess, handleFail, load );
    }

    const doDownload = (data, success, fail, load) => {
        Application.getSignedDocument(data, success, fail, load);
    }

    const doDelete = (data, success) => {
        Application.deleteSignedDocument(data, success);
    }

    //userCanDelete = {false}
    //userCanUpload = {true}

    if (! userAccess.isAllowAppDataDocsSigned) return null;
    if (isEmpty(signed_contract) && isEmpty(signed_act) && (! userAccess.isAllowAppDataDocsSignedCreate)) return <Empty text='отсутствуют' />
    return (
        <>
            <FileHandler
                data            = {{id, type: 'contract'}}
                fileName        = {contractFileName}
                userCanDelete   = {userAccess.isAllowAppDataDocsSignedDelete}
                userCanUpload   = {userAccess.isAllowAppDataDocsSignedCreate}

                isDeleteBlocked = {! userAccess.isAllowAppDataDocsSignedDelete}

                onUpload        = {doUpload  }
                onDownload      = {doDownload}
                onDelete        = {doDelete  }

                downloadButtonText = "Договор"
                subjectIm          = "договор"
                subjectRod         = "договора"
                emptyText          = ' '
            />
            <br />
            <FileHandler
                data            = {{id, type: 'act' }}
                fileName        = {actFileName}

                userCanDelete   = {userAccess.isAllowAppDataDocsSignedCreate}
                userCanUpload   = {userAccess.isAllowAppDataDocsSignedCreate}

                isDeleteBlocked = {! userAccess.isAllowAppDataDocsSignedDelete}

                onUpload        = {doUpload  }
                onDownload      = {doDownload}
                onDelete        = {doDelete  }

                downloadButtonText = "Акт"
                subjectIm          = "акт"
                subjectRod         = "акта"
                emptyText          = ' '
            />
            {isErrorUploadFile && <Text style={{ padding: 0, border: "none" }}>Не удалось загрузить файл. Допустимый формат .pdf и .png. Вес файла менее 1МБ</Text>}
        </>
    );

};

export default OrderInfoSignedDocuments;
