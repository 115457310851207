// prettier-ignore-start
import React from "react";

import {useHistory, useParams} from "react-router-dom";
import {getPath, Rating, RatingScore} from "../../../api/methods";
import { T }              from "../../../lang/ru";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import { PATHS }          from "../../../constants/RoutesPaths";
import { useDataRequest } from "../../../hooks/useDataRequest";


const SettingsListsRatingScores = () => {

    const history       = useHistory();
    const { rating_id } = useParams ();

    const rating = useDataRequest({
        autoLoad    : true,
        request     : Rating.read,
        requestData : { id: rating_id },
    });

    const formatValueMin = (id, item, itemProps) => item.values.reduce( (minValue, value) => Math.min(minValue, value.value), 0 );
    const formatValueMax = (id, item, itemProps) => item.values.reduce( (maxValue, value) => Math.max(maxValue, value.value), 0 );


    return (
        <List
            title        = {'Критерии рейтинга: ' + (rating.data.title ?? '-')}
            popupPrefix  = {'settingsRatingScores'}
            getListData  = {(data, s, f) => RatingScore.list({ rating_id }, s, f)}

            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: PATHS.settings.listsRatings},
                ]},
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'is_active'       , title: 'активен'     , format: LIF.check   , type: LIT.check , canAddAndEdit: true , default: 0   , align: 'center' },
                {name: 'order_id'        , title: 'порядок'     , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true , default: 0   },
                {name: 'title'           , title: 'название'    , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   , isValid: (val) => val !== "" },
                {name: 'description'     , title: 'описание'    , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   },
                {name: 'calc_action_name', title: 'имя процесса', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true , default: ''  , align: 'left'   },

                {name: 'value_min'       , title: 'мин'         , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, default: null, align: 'center' , customFormatHandler: formatValueMin },
                {name: 'value_max'       , title: 'макс'        , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, default: null, align: 'center' , customFormatHandler: formatValueMax },

                {name: 'values_count'    , title: 'значений'    , format: LIF.usageCounter},
            ]}

            actions = {[
                {title: 'значения', onClick: (rating_score_id) => history.push(getPath(PATHS.settings.listsRatingScoreValues, {rating_score_id}))},
            ]}

            onNew       = {(data, success, fail) => RatingScore.create({...data, rating_id}, success, fail)}
            onEdit       = {RatingScore.update}
            onDelete     = {RatingScore.delete}

            //canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteRatingScore}<b>{item?.title ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsRatingScores;
