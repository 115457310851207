import styled from "styled-components";
import { accent, gray } from "../../../../../constants/Colours";

const TopBarItem = styled.span`
  color: ${gray};
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: ${accent};
  }
`;

export default TopBarItem;
