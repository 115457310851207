import styled from "styled-components";

// prettier-ignore
export const statusColors = {
  0 : "white",  // 0 = "Не заполнена"
  1 : "red",    // 1 = "Подана заявка" // красная
  2 : "red",    // 2 = "Требуется уточнение" // красная
  3 : "white",  // 3 = "Исходные данные приняты" // белая
  4 : "white",  // 4 = "Оплата произведена"
  5 : "yellow", // 5 = "В работе" // желтая
  6 : "red",    // 6 = "Уточнение" // красная
  7 : "green",  // 7 = "Готово" // зеленая
  8 : "red",    // 8 = "Направлено на пересчет" // красная
  9 : "green",  // 9 = "Пересчет готов" // зеленая
  10: "green",  // 10 = "Принято заказчиком" // зеленая
  11: "grey",   // 11 = "Передано исполнителю" // серая
  12: "grey",   // 12 = "Не можем выполнить" // серая
  20: "grey"    // 20 = "Передано в архив" // серая
}

const defaultStatusColor = statusColors[0];

export const getRowBgColor = (status) => {
  return statusColors[status] ?? defaultStatusColor;
}

export const getRowBgColorStyle = (status) =>
    ({ background: "var(--"+getRowBgColor(status)+")" })

export const TrColored = styled.tr`
  ${(props) => props.bgColor
    ? `
        td { background: var(--${props.bgColor}); }
      `
    : null
  }
`;
