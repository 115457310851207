import styled from "styled-components";

// prettier-ignore
export const TrHead = styled.tr`
    & th {
        text-align: center !important;;
    }
    & th:first-child {
        text-align   : left !important;;
        border-radius: 10px 0px 0px 10px;
        //border-top : 5px solid grey;
    }
    & th:last-child {
        border-radius: 0px 10px 10px 0px;
        border-right : 0px;
    }
`;
