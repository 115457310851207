import {AnyAction} from "redux";
import {types} from "./types";


export type popUpType = {
    visible: boolean,
    reload: boolean,
    msg: string,
    title: string
    executeFunc: boolean
}
const initialState = {
    visible: false,
    reload: false,
    msg: "",
    title: "",
    executeFunc: true
}


export default function bonusesPopUpSlice(state: Partial<popUpType> = initialState, action: AnyAction) {
    switch (action.type) {
        case types.SET_VALUES:
            return {...state, ...action.payload}
        default:
            return state
    }
}