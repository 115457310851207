import { AnyAction } from '@reduxjs/toolkit';
import { LoaderState, SET_LOADER_STATE } from './types';


const initialState: LoaderState = "DEFAULT"


export function loaderReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_LOADER_STATE:
      return action.payload;
    default:
      return state;
  }

}
