// prettier-ignore-start
import LIT      from "../../../../components/Lists/ListItemTypes";
import { User } from "../../../../api/methods";


const ActionMessageFormAssignExpert = () => {

    const title     = 'эксперт';
    const role      = 'expert';

    const fieldName = role + '_id';

    const data = (app) => ({
        [fieldName] : app?.expert_id,
    });

    const setUsersOptions = (success) => User.listUsersByRoleSysnameSelect({role_sysname: role, hasUnset: 1}, ({data}) => success(data));

    const formCols = (props) => ([
        { name: fieldName, title: title, type: LIT.select, getOptions: setUsersOptions },
    ]);

    return {
        data     : data,
        formCols : formCols,
    }

};

export default ActionMessageFormAssignExpert;
