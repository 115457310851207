// prettier-ignore-start
import React, {useEffect, useState} from "react";

import { useDispatch }        from "react-redux";
import styled                 from "styled-components";

import { openPopup }          from "../../store/popups/actions";
import { usePreLoader }       from "../../hooks/usePreLoader";
import BackButton             from "../../routes/Orders/BackButton";

import { megaUltraLightgray } from "../../constants/Colours";
import ButtonGroup            from "../UI-kit/Buttons/ButtonGroup";
import Button                 from "../UI-kit/Buttons/Button";
import SecondOrderButton      from "../UI-kit/Buttons/SecondOrderButton";
import Title                  from "../UI-kit/Title";
import CustomerMainTable      from "../UI-kit/Table/CustomerMainTable";
import Text                   from "../UI-kit/Text";
import { CheckBoxLabel }      from "../UI-kit/CheckBoxLabel";
import LIF                    from "./ListItemFormats";
import PopupEdit              from "./PopupEdit";
import PopupDelete            from "./PopupDelete";
import { TrHead }             from "./Parts/TrHead";
import { TrBody }             from "./Parts/TrBody";


const TBody = styled.tbody`
    & ${TrBody}:nth-child(even) {
        background-color: ${megaUltraLightgray};
    }
`;

const defaultData = [];

const List = ({
    title       = '',
    popupPrefix = '',
    keyName    = 'id',
    cols        = [],
    buttons     = [],
    actions     = [],
    getListData       = () => [],
    canDelete         = (item) => true,
    onNew             = (data, success, fail) => {},
    onEdit            = (data, success, fail) => {},
    onDelete          = (data, success, fail) => {},
    textOnDelete      = (item) => '',
}) => {
    const dispatch  = useDispatch();
    const preloader = usePreLoader( true );

    const [data      , setData      ] = useState(defaultData);

    const [newItem   , setNewItem   ] = useState(null);
    const [editItem  , setEditItem  ] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const isSetNewItem    = newItem    !== null;
    const isSetEditItem   = editItem   !== null;
    const isSetDeleteItem = deleteItem !== null;

    const popupNew    = popupPrefix + 'New';
    const popupEdit   = popupPrefix + 'Edit';
    const popupDelete = popupPrefix + 'Delete'


    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (isSetNewItem   ) dispatch( openPopup( popupNew    ) );
        if (isSetEditItem  ) dispatch( openPopup( popupEdit   ) );
        if (isSetDeleteItem) dispatch( openPopup( popupDelete ) );
    }, [newItem, editItem, deleteItem]);


    const getData = () => {
        preloader.activate();
        getListData({}, getDataSuccess, getDataFail);
    };

    const getDataSuccess = ({ data }) => {
        setData(data);
        preloader.deactivate();
    };

    const getDataFail = ({ data }) => {
        setData(defaultData);
        preloader.deactivate();
    };

    const getItemById = (id) => {
        const index = data.findIndex( (item) => Number(item.id) === Number(id) );

        return { ...data[index], ...getCalculatedItemData(data[index]) };
    }

    const newFormCols  = cols.filter((col) => (col['canAddAndEdit'] ?? false) || (col['canAdd' ] ?? false));
    const editFormCols = cols.filter((col) => (col['canAddAndEdit'] ?? false) || (col['canEdit'] ?? false));

    const defaultFormState = (id) => {
        let res = {};

        newFormCols.forEach((col) => res = { ...res, [col.name]: col.default ?? '' });

        return res;
    };

    const getCalculatedItemData = (item) => newFormCols.reduce( (result, col) => ({
        ...result,
        ...(col.value
            ? { [col.name]:
                (typeof (col.value) == 'function')
                    ? col.value(item)
                    : col.value
            }
            : {}
        )
    }), {} );

    const onClickAdd    = (  ) => setNewItem   ( defaultFormState() );
    const onClickEdit   = (id) => setEditItem  ( getItemById(id)    );
    const onClickDelete = (id) => setDeleteItem( getItemById(id)    );

    const is0 = (count) => Number(count) === 0;

    const getTextItem = (value) => {
        return value == null ? '' : value;
    }

    const getLinkEditItem = (value, id) => {
        return (
            <SecondOrderButton
                onClick={(e) => onClickEdit(id)}
                style={{textAlign: "left"}}
            >
                {value}
            </SecondOrderButton>
        );
    }

    const getLinkGoToItem = (value, id, props) => {
        return (
            <SecondOrderButton
                onClick={(e) => props.goTo(id)}
                style={{textAlign: "left"}}
            >
                {value}
            </SecondOrderButton>
        );
    }

    const getCheckItem = (value, id, onChange) => {
        return (
            <CheckBoxLabel style={{width: '100%'}}>
                <input
                    type     = 'checkbox'
                    checked  = {value ? 1 : 0}
                    onChange = {() => onChange(id, value) }
                    style    = {{width:'18px', height: '18px'}}
                    disabled = {! onChange}
                />
            </CheckBoxLabel>
        );
    }

    const getUsageCounterItem = (value) => {
        const style = {border:"0px", padding:"0px", margin:"0px", color: "lightgrey"};

        return is0(value) ? <small style={style}>нет</small> : value ;
    }

    const getByItemFormat = (id, item, itemProps) => {
        const {
            name,
            format,
            onChange,
            customFormatHandler,
            props
        } = itemProps;

        const value = (customFormatHandler)
            ? customFormatHandler(id, item, itemProps)
            : item[name];

        switch (format) {
            case LIF.text         : return getTextItem        (value);
            case LIF.usageCounter : return getUsageCounterItem(value);
            case LIF.linkEdit     : return getLinkEditItem    (value, id);
            case LIF.linkGoTo     : return getLinkGoToItem    (value, id, props);
            case LIF.check        : return getCheckItem       (value, id, onChange);

            default               : return value;
        }
    }

    const actionButtonStyle = {padding: '3px 15px 3px 15px'};


    const getActionsList = (id, item) => {
        const listSeparator = <span style={{color: '#CCCCCC'}}>|</span>;

        let actionsList = [];

        const addListSeparator = () => { if (actionsList.length) actionsList.push( listSeparator ); }

        actions.map( ({ title, onClick, canDo, className }) => {
            const canDoIsSet = canDo !== undefined;
            const doIt       = (! canDoIsSet) || (canDoIsSet && (((typeof(canDo) === 'function') ? canDo(item) : canDo)) );

            if ( doIt ) {
                addListSeparator();
                actionsList.push(<>
                    <SecondOrderButton
                        style     = {actionButtonStyle}
                        marginLeft
                        className = {className ?? 'button'}
                        onClick   = {(e) => onClick(id, item)}
                        key       = {title+id}
                    >
                        {title}
                    </SecondOrderButton>
                </>
                );
            }
        } );

        if ( canDelete(item) ) {
            addListSeparator();
            actionsList.push(
                <SecondOrderButton
                    style      = {actionButtonStyle}
                    marginLeft
                    className  = "delete-button"
                    onClick    = {(e) => onClickDelete(id)}
                >
                    &times;
                </SecondOrderButton>
            );
        }

        return actionsList;
    }

    const outActionsList = (id, item) => {
        return getActionsList(id, item).map((action) => { return action; });
    }

    const outButtons = (buttons, isInGroup = false) => {
        let counter = 0;

        const buttonsList = buttons.map((button) => {
            const {type} = button;
            const style    = {marginLeft: ((counter>0) && (! isInGroup)) ? '10px' : '0px'};
            const disabled = (button.isDisabled) ? button.isDisabled(data) : false;

            counter++;

            switch (type) {
                case 'back'  : return <BackButton buttonType={0} to={button.to} style={style} />;
                case 'group' : return <ButtonGroup style={style}>{outButtons(button.buttons, true)}</ButtonGroup>
                case 'new'   : return <Button style={style} onClick={onClickAdd} disabled={disabled}>+</Button>  //margin={"10px 15px 25px 0px"}
                case 'custom': return button.out ?? null;

                default      : return <Button style={style} onClick={button.onClick} disabled={disabled}>{button.title}</Button>  //margin={"10px 15px 25px 0px"}
            }
            counter--;
        })

        return ( isInGroup )
            ? buttonsList
            : <div style={{display: 'flex'}}>
                {buttonsList}
            </div>;
    }

    const getColStyle = (props) => {
        let styleProp = props.style ?? {};

        if (props?.align !== undefined) styleProp = {...styleProp, textAlign: props.align};

        return styleProp;
    }

    return (
        <>
            {title !== '' && <div style={{width: '100%'}}><Title>{title}</Title></div>}

            {outButtons(buttons)}

            { preloader.isActive
            ? preloader.wait()
            : data.length === 0
                ? (
                    <Text><big><br/>cписок пуст<br/><br/></big></Text>
                ) : (<>
                    <CustomerMainTable style={{borderCollapse: "separate", minWidth: "70%"}}>
                        <thead>
                            <TrHead>
                                {cols.map(({ title, format, style }, index) => (format !== LIF.hidden)
                                    ? (<th style={style} key={'th_'+(index.toString())}>{title}</th>)
                                    : null
                                )}
                                <th key='th_actions'>...</th>
                            </TrHead>
                        </thead>
                        <TBody>
                            {data.map((item, rowIndex) => {
                                const id = item[keyName];
                                const actionsStyle = (actions.length > 0) ? {textAlign:'left'} : {};
                                //const isUseed = ! is0(item.applications_count);

                                return (
                                    <TrBody key={'tr_'+(rowIndex.toString())}>
                                        {cols.map( (props, colIndex) => {
                                            if (props.format === LIF.hidden) return null;

                                            const res = getByItemFormat(id, item, props);

                                            return (<td style={getColStyle(props)} key={'td_'+(rowIndex.toString())+'_'+(colIndex.toString())}>{res}</td>);
                                        } )}
                                        <td nowrap='nowrap' style={actionsStyle} key={'td_'+(rowIndex.toString())+'_actions'}>
                                            {outActionsList(id, item)}
                                        </td>
                                    </TrBody>
                                );
                            })}
                        </TBody>
                    </CustomerMainTable>
                </>)
            }
            <PopupEdit
                isNewPopup  = {true}
                popupName   = {popupNew}
                getData     = {getData}
                item        = {newItem}
                setItem     = {setNewItem}
                cols        = {newFormCols}
                action      = {onNew}
            />
            <PopupEdit
                popupName   = {popupEdit}
                getData     = {getData}
                item        = {editItem}
                setItem     = {setEditItem}
                cols        = {editFormCols}
                action      = {onEdit}
            />
            <PopupDelete
                popupName   = {popupDelete}
                getData     = {getData}
                item        = {deleteItem}
                setItem     = {setDeleteItem}
                getTextBody = {(item) => textOnDelete(item)}
                action      = {onDelete }
            />
        </>
    );
};

export default List;