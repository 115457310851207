// prettier-ignore-start
import React from "react";

import settingsTabType                  from "../tabType";
import SettingsSystemAccessRightsList   from "./SettingsSystemAccessRightsList";
import SettingsSystemAccessRightValues  from "./SettingsSystemAccessRightValues";
import SettingsSystemAccessRightTargets from "./SettingsSystemAccessRightTargets";


const SettingsSystemAccessRights = ({ sub = settingsTabType.systemAccessRights }) => {

    if (sub === settingsTabType.systemAccessRightValues ) return <SettingsSystemAccessRightValues  />
    if (sub === settingsTabType.systemAccessRightTargets) return <SettingsSystemAccessRightTargets />

    return <SettingsSystemAccessRightsList />

};

export default SettingsSystemAccessRights;
