
import { Children } from "react";
import styled from "styled-components";
import Button from "./Button";
type Props = {
  activeIndex: string;
};
const ButtonGroup = styled.div`
  display: flex;
  ${Button} {
    border-radius: 0;
  }
  ${Button}:last-child {
    position: relative;
    left: -1px;
    border-radius: 0 8px 8px 0;
  }
  ${Button}:first-child {
    border-radius: 8px 0 0 8px;
  }
  ${Button} + ${Button} {
    margin-left: -1px;
    margin-right: -1px;
  }
`;
export default ButtonGroup;