// prettier-ignore-start
import React from "react";

import { getPath }                         from "../../../api/methods";
import { PATHS }                           from "../../../constants/RoutesPaths";
import SettingsSystemStatusRoleActionsList from "./SettingsSystemStatusRoleActionsList";


const SettingsSystemStatusRoleActions = () => {

    return <SettingsSystemStatusRoleActionsList
        getTitle = {(roleStatus) => (<>Роль "{roleStatus?.role?.title ?? '-'}" / статус "{roleStatus?.title ?? '-'}" / Действия:</>)}
        getBack  = {(roleStatus) => (roleStatus?.id && getPath(PATHS.settings.systemRoleStatuses, {role_id: roleStatus?.role_id}))}
    />

};

export default SettingsSystemStatusRoleActions;
