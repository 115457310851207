import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'styled-bootstrap-grid';

import { gray } from '../../../../../constants/Colours';

import Text from '../../../../../components/UI-kit/Text';
import Button from '../../../../../components/UI-kit/Buttons/Button';
import FloatingInput from '../../../../../components/UI-kit/FloatingInput';
import InputButtonGroup from '../../../../../components/UI-kit/InputButtonGroup';

import { getPreviewMode } from '../../../../../store/previewMode/selectors';
import Checkbox from '../../../../../components/UI-kit/Checkbox';
import { getUserAnswers } from '../../../../../store/userAnswers/selectors';
import { setUserAnswer } from '../../../../../store/userAnswers/actions';
import { CheckBoxLabel } from '../../../../../components/UI-kit/CheckBoxLabel';
import { CRUDStructElement } from '../../CRUDStructElement';
import { useParams } from 'react-router-dom';
import { API_URL, Application } from '../../../../../api/methods';
import questionnaireFunc from '../../../../../functions/questionnaireFunc';
import FileHandler from '../../../../../components/File/FileHandler';
import { downloadFile } from '../../../../../components/File/DownloadFile';
import hooks from '../../../../../functions/hooks';

const UploadFilePart = ({ struct, element, addStruct, deleteStruct }) => {
  const { id, name } = element;
  const key = id;

  const { application_id } = useParams();

  const dispatch = useDispatch();
  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);

  const isEmpty = hooks.useIsFieldEmpty(id);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [state, setState] = useState({ value: '' });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const answerObj = userAnswers[key]?.answer;
  const isChecked = answerObj ? Object.values(answerObj)[0] : false;

  const fileHref = typeof answerObj === 'string' ? answerObj : '';
  const isLinkInUserAnswer = fileHref.length > 0;
  const fileURL = isLinkInUserAnswer
    ? API_URL.replace('/api/', '') + fileHref
    : '';

  useEffect(() => {
    setIsAddButtonDisabled(state.value == '');
  }, [state.value]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ state, value: '' });
  };

  const changeUserAnswerValue = (e) => {
    const { name, checked } = e.target;
    let currentValue = userAnswers[key]?.answer;

    currentValue =
      currentValue === undefined || typeof currentValue == 'string'
        ? {}
        : currentValue;

    currentValue[name] = checked;

    const userAnswer = questionnaireFunc.getAnswerInfo(element, currentValue);
    dispatch(setUserAnswer(key, userAnswer));
  };

  const handleCheckboxChange = (e) => {
    setState({ ...state, checked: e.target.checked });
  };

  const handleCheckboxClick = isPreviewMode
    ? changeUserAnswerValue
    : handleCheckboxChange;

  const filterArrayByDuplicatesIds = (arr) =>
    arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const filteredStruct = filterArrayByDuplicatesIds(element.struct);

  // file handling
  const doUpload = (data, success, fail, load) => {
    Application.uploadFile(
      data,
      (response) => {
        const userAnswer = questionnaireFunc.getAnswerInfo(
          element,
          response.data.link
        );
        dispatch(setUserAnswer(key, userAnswer));
        success(response);
      },
      fail,
      load
    );
  };

  const doDownload = (data, success, fail, load) => {
    downloadFile(fileURL, success, fail, load);
  };

  const doDelete = (data, success) => {
    const userAnswer = questionnaireFunc.getAnswerInfo(element.name, null);
    dispatch(setUserAnswer(key, userAnswer));
    success();
  };

  return (
    <>
      {(isPreviewMode && !isChecked) || isLinkInUserAnswer ? (
        <>
          <FileHandler
            id={id}
            isEmpty={isEmpty}
            data={{ application_id }}
            isUploaded={isLinkInUserAnswer}
            userCanDelete={true}
            userCanUpload={true}
            onUpload={doUpload}
            onDownload={doDownload}
            onDelete={doDelete}
            subjectIm="файл"
            subjectRod="файла"
            uploadButtonText="Прикрепить файл"
            downloadButtonText="Файл прикреплен"
            deleteButtonText="Отменить загрузку"
            deleteQuestionText="Вы действительно хотите отменить загрузку файла?"
            reloadPageAfterChange={false}
          />

          <div
            style={{
              fontSize: 'smaller',
              display: 'block',
              marginBottom: '20px',
            }}
          >
            {
              '* можно прикрепить 1 (один) файл. Если нужно прикрепить несколько файлов, заархивируйте их и прикрепите архив.'
            }
          </div>
        </>
      ) : null}

      {isLinkInUserAnswer || isFileUploaded ? null : (
        <Text style={{ marginTop: 5, marginBottom: 10, color: gray }}>
          {isPreviewMode
            ? element.struct.length === 0
              ? ''
              : 'Если у вас отсутствует файл, выберите ответ:'
            : 'Вариант ответа, если пользователь не будет загружать файл'}
        </Text>
      )}

      {element.struct.length !== 0 || isPreviewMode ? null : (
        <Col>
          <Row>
            <InputButtonGroup>
              <FloatingInput
                name     = "value"
                onChange = {handleChange}
                value    = {state.value}
                label    = {"Название элемента" }
              ></FloatingInput>
              <Button
                disabled = {isAddButtonDisabled}
                onClick  = {handleAddStruct}
                margin   = {"10px 0"}
              >
                Добавить ответ
              </Button>
            </InputButtonGroup>
          </Row>
        </Col>
      )}

      {isLinkInUserAnswer || !isPreviewMode
        ? ''
        : filteredStruct.map(({ name, id }) => {
            const isChecked = userAnswers[key]?.answer
              ? userAnswers[key]?.answer[name]
              : false;
            return (
              <CheckBoxLabel key={id}>
                <Checkbox
                  name={name}
                  checked={isChecked}
                  onChange={handleCheckboxClick}
                />
                <span style={{ marginLeft: 13 }}>{name}</span>
              </CheckBoxLabel>
            );
          })}

      {!isPreviewMode ? (
        <CRUDStructElement
          elementId={element.id}
          struct={filteredStruct}
          deleteStruct={deleteStruct}
        />
      ) : null}
    </>
  );
};

export default UploadFilePart;
