const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
const dataReg =
  /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;
const phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const isValidEmailCheck = (email: string): boolean =>
  email.match(emailReg) ? true : false;

export const isValidPhoneCheck = (phone: string): boolean =>
  phone.match(phoneReg) || !phone ? true : false;

export const isValidDateCheck = (date: string): boolean => {
  if (date === null) {
    return true;
  }
  return date.match(dataReg) || !date ? true : false;
};
