import React from "react";

import { Link } from "react-router-dom";

import styles from "./YoutubeLink.module.css";

import youtube from "../../../assets/svg/youtube.svg";

export const YoutubeLink = () => {
  return (
    <a
      href={
        "https://www.youtube.com/watch?v=vHHw3wYJjkQ&list=PLMELFmFLNweauJCWlt207KxLtaqRogUVp"
      }
      target="_blank"
      rel="noopener"
      className={styles.container}
    >
      <img src={youtube} className={styles.icon} />
      <div className={styles.text}>
        Видеоинструкция по регистрации и заполнению заявки
      </div>
    </a>
  );
};
