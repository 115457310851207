// prettier-ignore-start
import React from "react";

import settingsTabType     from "./tabType";
import SettingsCompanyForm from "./SettingsCompanyForm";
import SettingsCompanyList from "./SettingsCompanyList";


const SettingsCompany = ({ sub = settingsTabType.companyList }) => {

    if (sub === settingsTabType.companyNew ) return <SettingsCompanyForm mode='new'  />
    if (sub === settingsTabType.companyEdit) return <SettingsCompanyForm mode='edit' />

    return <SettingsCompanyList />;

};

export default SettingsCompany;
