import React from "react";
import {WaitSpinner} from "../components/UI-kit/Preloader/WaitSpinner";
import {useActive} from "./useActive";

// prettier-ignore
export function useWait(state) {

  const active = useActive(state);

  const wait = () => <WaitSpinner />

  return {
    ...active,

    wait       : wait,
  };
}
