import styled from "styled-components";
import { ultraLightgray } from "../../../constants/Colours";

const Card = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  max-width: 350px;
  background-color: ${ultraLightgray};
  padding: 20px;
  margin-right: 30px;
  // height: fit-content;
`;
export default Card;
