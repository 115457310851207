import React from "react";


// prettier-ignore-start

const OrdersListTableRowHead = ({ children, ...props }) => <th {...props}>{children}</th>;

export default OrdersListTableRowHead;

// prettier-ignore-end
