// prettier-ignore-start
import React from "react";

import { useParams }     from "react-router-dom";

import { Application }   from "../../../api/methods";
import List              from "../../../components/Lists/List";
import LIF               from "../../../components/Lists/ListItemFormats";
import LIT               from "../../../components/Lists/ListItemTypes";
import ListFormat_2Lines from "../../../components/Lists/Parts/ListFormat_2Lines";
import {
    dateTranslate,
    isNotEmpty,
    timeTranslate
}                        from "../../../functions/common";


const OrderExecutorDistributionUsers = () => {

    const { order_id } = useParams();

    const fioTitle     = ListFormat_2Lines('фамилия имя отчество', 'email, телефон');
    const fioJoin      = (item) => [item['surname'], item['name'], item['middlename']].join(' ');

    const formatCreatedAt    = (id, item, itemProps) => ListFormat_2Lines( dateTranslate(item['created_at']), timeTranslate(item['created_at']) );
    const formatGroup        = (id, item, itemProps) => item?.executor_group ? item?.executor_group['name'] : '-';
    const formatFio          = (id, item, itemProps) => ((item['user'] !== undefined) && ListFormat_2Lines(fioJoin(item['user']), [item['user']['email'], item['user']['phone']].filter((val) => (val && val !== '')).join(', '), true) );
    const formatRefuseStatus = (id, item, itemProps) => (isNotEmpty(item['refuse_at']) ? ListFormat_2Lines( 'отказ', <>{dateTranslate(item['refuse_at'])} в {timeTranslate(item['refuse_at'])}</>) : 'рассмотрение');


    return <List
        title        = 'Статус поиска исполнителя'
        popupPrefix  = {'orderDistributionList'}
        getListData  = {(data, success, fail) => Application.distribution({ app_id: order_id }, success, fail)}

        buttons      =  {[
            {type: 'back'},
        ]}

        cols         = {[
            { name: 'created_at', title: 'доступно с', format: LIF.date, type: LIT.text, canAddAndEdit: false, customFormatHandler: formatCreatedAt    },
            { name: 'group'     , title: 'группа'    , format: LIF.text, type: LIT.text, canAddAndEdit: false, customFormatHandler: formatGroup       , align: 'left' },
            { name: 'fio'       , title: fioTitle    , format: LIF.text, type: LIT.text, canAddAndEdit: false, customFormatHandler: formatFio         , align: 'left' },
            { name: 'status'    , title: 'статус'    , format: LIF.text, type: LIT.text, canAddAndEdit: false, customFormatHandler: formatRefuseStatus, align: 'left' },
        ]}

        canDelete    = {(item) => false}
    />

};

export default OrderExecutorDistributionUsers;
