// prettier-ignore-start
import React, {useState} from "react";

import {Promocode, User} from "../../../api/methods";
import { T }             from "../../../lang/ru";
import {formatDate}      from "../../../functions/common/common";

import {yellow}          from "../../../constants/Colours";
import List              from "../../../components/Lists/List";
import LIF               from "../../../components/Lists/ListItemFormats";
import LIT               from "../../../components/Lists/ListItemTypes";
import Text              from "../../../components/UI-kit/Text";


const selectList = {
    data     : [],
    // eslint-disable-next-line no-undef
    defaultId: function()   { return this.data[0].id; },
    getById  : function(id) { return (this.data.find( (item) => (item.id === id) )).name; },
}

const promocodeTypes = {
    ...selectList,
    data: [
        {id: 0, name: 'одноразовый' },
        {id: 1, name: 'многоразовый'},
    ],
};

const promocodeValueTypes = {
    ...selectList,
    data: [
        {id: 0, name: '%'},
        {id: 1, name: '₽'},
    ],
};


const SettingsListsPromocodes = () => {
    const [data, setData] = useState([]);

    const valueFormat  = (id, item, itemProps) => <>{item['value']}&nbsp;{item['value_type_id'] === 0 ? '%' : '₽'}</>;

    const typeFormat   = (id, item, itemProps) => <>
        {promocodeTypes.getById(item['type_id'])}
        {item['user_id'] && <>
            <small style={{color: 'grey'}}><br/>{item['user_fio']}</small>
        </>}
    </>;

    const validFormat  = (id, item, itemProps) => {
        const after  = item['valid_after' ];
        const before = item['valid_before'];

        let result = 'не ограниченно';
        if ( after &&  before) result = formatDate(after) +' - '+ formatDate(before);
        if ( after && !before) result = 'после '+ formatDate(after);
        if (!after &&  before) result = 'до '   + formatDate(before);

        return result
    }

    const isDataHasDuplicates = data.find(item1 => data.find(item2 => item1.id !== item2.id && item1.name === item2.name))
    const onLoadDataSuccess = (success, data) => {
        success(data)
        setData(data.data)
    }

    return (<>
        {isDataHasDuplicates && <Text color={yellow} style={{paddingTop:'15px'}}>Осторожно! У вас есть дубликаты (идентичные названия)!</Text>}
        <List
            //title        = {T.editPromocodes}
            popupPrefix  = {'promocode'}
            getListData  = {(data, success, fail) => Promocode.getAll({}, (data) => onLoadDataSuccess(success,data), fail)}
            cols         = {[
                {name: 'is_active'    , title: 'активен'         , format: LIF.check   , type: LIT.check , canAddAndEdit: true, align: 'center', default: 1 },
                {name: 'name'         , title: 'название'        , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, align: 'left'  , isValid: (val) => val !== ""},

                {name: 'value'        , title: 'скидка'          , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true, align: 'right' , customFormatHandler: valueFormat , isValid: (val) => val !== "" && Number(val)>=0 },
                {name: 'value_type_id', title: 'тип скидки'      , format: LIF.hidden  , type: LIT.select, canAddAndEdit: true, getOptions: (success) => success(promocodeValueTypes.data), default: promocodeValueTypes.defaultId() },

                {name: 'type_id'      , title: 'тип промокода'   , format: LIF.linkEdit, type: LIT.select, canAddAndEdit: true, align: 'left'  , customFormatHandler: typeFormat, getOptions: (success) => success(promocodeTypes.data), default: promocodeTypes.defaultId() },
                {name: 'user_id'      , title: 'для пользователя', format: LIF.hidden  , type: LIT.select, canAddAndEdit: true, getOptions: (success) => User.getUsersSelect({hasUnset: true}, ({data}) => success(data)), default: null },

                {name: 'valid'        , title: 'действителен'    , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, customFormatHandler: validFormat },
                {name: 'valid_after'  , title: 'действителен с ' , format: LIF.hidden  , type: LIT.date  , canAddAndEdit: true },
                {name: 'valid_before' , title: 'действителен по' , format: LIF.hidden  , type: LIT.date  , canAddAndEdit: true },

                {name: 'comment'      , title: 'комментарий'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, align: 'left', default: '' },

                {name: 'applications_count', title: 'заявок'          , format: LIF.usageCounter},
            ]}
            buttons      =  {[
                {type: 'new'},
            ]}

            onNew        = {Promocode.add}
            onEdit       = {Promocode.update}
            onDelete     = {Promocode.delete}

            canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deletePromocode}<b>{item?.name ?? ""}</b>?</>}
        />
    </>);
};

export default SettingsListsPromocodes;
