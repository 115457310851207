// prettier-ignore-start
import React, { useEffect, useState }    from 'react';

import { useDispatch }                   from 'react-redux';
import { useHistory }                    from 'react-router-dom';
import { Auth }                          from '../api/methods';
import { T }                             from '../lang/ru';
import { setIsHiddenMobileButton }       from '../store/isHiddenMobileButton/action';
import {closePopup, openPopup} from "../store/popups/actions";
import { gray }                          from '../constants/Colours';
import { INPUT_CONFIGS }                 from '../constants/InputConfigs';
import { PATHS }                         from '../constants/RoutesPaths';
import { formDataFromInputInfoForState } from '../functions/common/common';
import { isValidEmailCheck }             from '../functions/validators';
import hooks                             from '../functions/hooks';
import Button                            from '../components/UI-kit/Buttons/Button';
import Checkbox                          from '../components/UI-kit/Checkbox';
import { LinkButton }                    from '../components/UI-kit/Buttons/LinkButton';
import { CheckBoxLabel }                 from '../components/UI-kit/CheckBoxLabel';
import FloatingInput                     from '../components/UI-kit/FloatingInput';
import Text                              from '../components/UI-kit/Text';
import Title                             from '../components/UI-kit/Title';
import Alert                             from "../components/Alert/Alert";
import ButtonsBlock                      from "../components/Alert/ButtonsBlock";


const Register: React.FC = () => {

    const history  = useHistory ();
    const dispatch = useDispatch();

    const initialData = formDataFromInputInfoForState(INPUT_CONFIGS.register);

    const [data        , changeDataField] = useState(initialData);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [error       , setError       ] = useState('');

    const [isAgree, switchIsAgree] = hooks.useCheckbox();

    useEffect(() => {
        dispatch( setIsHiddenMobileButton(false) );
    }, []);

    const onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        const { value, name } = e.target;

        changeDataField({ ...data, [name]: value });
    };

    const checkEmailValid: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
        setError('');
        setIsValidEmail(isValidEmailCheck(e.target.value));
    };

    const registerUser: () => void = () =>
        Auth.register(
            {
                name      : data.name,
                surname   : data.surname,
                middlename: data.middlename,
                phone     : data.phone,
                email     : data.email,
                password  : data.password,
            },
            goToRegisterSuccess,
            handleRegisterError
        );

    const goToRegisterSuccess = () => history.push(PATHS.registerSuccess);

    const handleRegisterError = (err: string) => {
        const isEmailError = err.toString().includes('400');

        if (isEmailError) showRepeatedEmailMessage();
    };

    const showRepeatedEmailMessage = () => {
        setError('Пользователь с таким Email уже существует!');
        dispatch( openPopup('popupRegisterError') );
    }

    const checkEmptyFields = (data: any) => {
        let isRequiredEmpty = false;

        const requiredKeys = INPUT_CONFIGS.register.map(({ required, key }) => {
            if (required) return key;
        });

        requiredKeys.map((key) => {
            if (! key) return;

            const isEmpty = data[key] == '';

            if (isEmpty) isRequiredEmpty = true;
        });

        return isRequiredEmpty;
    };

    const isWrongPassword         = data['password'] !== data['passwordRepeat'];
    const isWrongEmail            = !isValidEmail || error.length > 0;
    const isEmptyFieldsData       = checkEmptyFields(data);

    const isDisableRegisterButton = isEmptyFieldsData || isWrongEmail || isWrongPassword || !isAgree;


    return <>
        <Title>{T.registerOfAnApplication}</Title>
        <Text>{T.registerInfo}</Text>
        <Text color={gray}>{T.registerTooltip}</Text>

        <FloatingInput
            placeholder  = {'Фамилия'}
            key          = {'surname'}
            name         = {'surname'}
            value        = {data['surname']}
            onChange     = {onChangeHandler}
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Имя'}
            key          = {'name'}
            name         = {'name'}
            value        = {data['name']}
            onChange     = {onChangeHandler}
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Отчество'}
            key          = {'middlename'}
            name         = {'middlename'}
            value        = {data['middlename']}
            onChange     = {onChangeHandler}
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Телефон'}
            key          = {'phone'}
            name         = {'phone'}
            value        = {data['phone']}
            onChange     = {onChangeHandler}
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Добавочный номер'}
            key          = {'additionalNumber'}
            name         = {'additionalNumber'}
            value        = {data['additionalNumber']}
            onChange     = {onChangeHandler}
            required     = {false}
        />
        <FloatingInput
            placeholder  = {'Email'}
            wrong        = {isWrongEmail}
            key          = {'email'}
            name         = {'email'}
            value        = {data['email']}
            onBlur       = {checkEmailValid}
            onChange     = {onChangeHandler}
            wrongMessage = { (error !== '') ? error : T.inCorrectEmail }
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Пароль'}
            key          = {'password'}
            name         = {'password'}
            type         = {'password'}
            value        = {data['password']}
            onChange     = {onChangeHandler}
            wrong        = {isWrongPassword}
            required     = {true}
        />
        <FloatingInput
            placeholder  = {'Повторите пароль'}
            key          = {'passwordRepeat'}
            name         = {'passwordRepeat'}
            type         = {'password'}
            value        = {data['passwordRepeat']}
            onChange     = {onChangeHandler}
            wrongMessage = {T.inCorrectPassword}
            wrong        = {isWrongPassword}
            required     = {true}
        />

        <div style={{ margin: '13px 0' }}>
            <CheckBoxLabel>
            <Checkbox
                className = "isAgree"
                name      = "isAgree"
                checked   = {isAgree}
                onChange  = {switchIsAgree}
            />
            <span style={{ marginLeft: 13 }}>
                Соглашаюсь на обработку
                <LinkButton
                    target = {'_blank'}
                    style  = {{ display: 'inline-flex' }}
                    to     = {PATHS.agreement}
                >
                    персональных данных
                </LinkButton>
            </span>
            </CheckBoxLabel>
        </div>

        <Button
            style    = {{ marginRight: 25 }}
            disabled = {isDisableRegisterButton}
            onClick  = {isDisableRegisterButton ? () => {} : registerUser}
        >
            {T.send}
        </Button>

        <Alert
            title   = ''
            isShow  = { (error !== '') }
            buttons = {
                <ButtonsBlock style={{ paddingTop:'20px' }}>
                    <Button
                        onClick = { () => {
                            dispatch(closePopup('popupRegisterError'));
                            setError('');
                        } }
                        margin  = {"0px 0px"}
                    >
                        Ok
                    </Button>
                </ButtonsBlock>
            }
        >
            {error}
        </Alert>
    </>;

};

export default Register;
