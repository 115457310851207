import {downloadData} from "./DownloadData";

export const downloadFile = (url, success = undefined, fail = undefined, load = undefined) => {
  downloadData(url + '/name', "json", (response) => {
    successDownloadFile(response, url, success, fail, load);
  } );
};

const successDownloadFile = (response_name, url, success = undefined, fail = undefined, load = undefined) => {
  const filename = (response_name.data) ? response_name.data.file_name : '' ;
  downloadFileName(url, filename, success, fail, load);
}

export const downloadFileName = (url, fileName, success = undefined, fail = undefined, load = undefined) => {
  downloadData(url, "blob", (response) => {
    successDownloadFileName(response, fileName, success);
  }, fail, load);
};

export const successDownloadFileName = (response, fileName, success = undefined) => {
  const link = document.createElement("a");

  link.href     = window.URL.createObjectURL(new Blob([response.data]));
  link.download = fileName;

  //link.target = '_blank';
  //document.body.appendChild(link);

  link.click();

  if (typeof success === "function") success(response);
}

