// prettier-ignore-start
import React, { useState, useEffect } from 'react';

import { Col, Row }            from 'styled-bootstrap-grid';

import {
  isValidEmailCheck,
  isValidPhoneCheck,
  isValidDateCheck,
}                              from '../../../../functions/validators';
import { checkFullnessFields } from '../../../../functions/common/common';
import { T }                   from '../../../../lang/ru';

import FloatingInput           from '../../../../components/UI-kit/FloatingInput';
import Subtitle2               from '../../../../components/UI-kit/Subtitle2';


const IndividualForm = ({
    setInputsValue,
    inputsValue = {},
    setIsDisabled,
}) => {
    const {
        surname,
        adress,
        name,
        phone,
        middlename,
        email,
        serial,
        number,
        who_give,
        date_get,
        inn,
    } = inputsValue;

    const requiredFields = [surname, name, middlename, adress, email, phone];

    const checkFields = [
        surname,
        name,
        middlename,
        adress,
        email,
        phone,
        inn,
        serial,
        number,
        who_give,
        date_get,
    ];

    const [isNotValid, setIsNotValid] = useState({
        serial  : false,
        number  : false,
        inn     : false,
        email   : false,
        phone   : false,
        date_get: false,
    });

    useEffect(() => {
        checkDisabledButton();
    }, [checkFields]);

    const checkDisabledButton = () => {
        // обязательные поля не должны быть пустыми
        const isHasEmptyRequiredFields = checkFullnessFields(requiredFields); // Есть ли пустые поля/ false – нет пустых, true есть пустые

        // не обязательные поля - могут быть либо пустыми, либо валидными (не валидное, но пустое - это норма)
        const isHasWrongValue = Object.values(isNotValid).includes(true);

        const isDisabled = isHasEmptyRequiredFields || isHasWrongValue;

        setIsDisabled(isDisabled);
    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        checkValid(e);
        setInputsValue({ ...inputsValue, [name]: value });
    };

    const checkValid = (e) => {
        const { value, name } = e.target;
        const notValid = validators[name];

        if (notValid) setIsNotValid({ ...isNotValid, [name]: notValid(value) });
    };

    const validators = {
        // true - значение не верное, false - значение верно
        email   : (value) => !isValidEmailCheck(value),
        phone   : (value) => !isValidPhoneCheck(value),
        inn     : (value) => value !== '' && !value.match('^([0-9]{10,12})$'),
        serial  : (value) => value !== '' && !value.match('^([0-9]{4})$'),
        number  : (value) => value !== '' && !value.match('^([0-9]{6})$'),
        date_get: (value) => value !== '' && !isValidDateCheck(value),
    };

    return <>
        <Subtitle2 style={{ marginTop: 30 }}>
            {T.enterCustomerInformation}
        </Subtitle2>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "surname"
                    value       = {surname}
                    required    = {true}
                    placeholder = {T.surname}
                    onChange    = {onChangeInput}
                    tabIndex    = {101}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name        = "adress"
                    value       = {adress}
                    required    = {true}
                    placeholder = {T.adress}
                    onChange    = {onChangeInput}
                    tabIndex    = {105}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "name"
                    value       = {name}
                    required    = {true}
                    placeholder = {T.name}
                    onChange    = {onChangeInput}
                    tabIndex    = {102}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name         = "phone"
                    value        = {phone}
                    required     = {true}
                    placeholder  = {T.phone}
                    wrong        = {isNotValid.phone}
                    wrongMessage = {T.inCorrectPhone}
                    onChange     = {onChangeInput}
                    tabIndex     = {106}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "middlename"
                    value       = {middlename}
                    required    = {true}
                    placeholder = {T.middleName}
                    onChange    = {onChangeInput}
                    tabIndex    = {103}
                />
            </Col>

            <Col col="auto">
                <FloatingInput
                    name         = "email"
                    value        = {email}
                    required     = {true}
                    placeholder  = {T.email}
                    wrong        = {isNotValid.email}
                    wrongMessage = {T.inCorrectEmail}
                    onChange     = {onChangeInput}
                    tabIndex     = {107}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name         = "inn"
                    value        = {inn}
                    required     = {true}
                    placeholder  = {T.INN}
                    wrong        = {isNotValid.inn}
                    wrongMessage = {'Формат (Например, 998877777766)'}
                    onChange     = {onChangeInput}
                    onBlur       = {checkValid}
                    tabIndex     = {104}
                />
            </Col>
        </Row>

        <Subtitle2 style={{ marginTop: 40 }}>{T.enterPasportData}</Subtitle2>
        <Row>
            <Col>
                <FloatingInput
                    name         = "serial"
                    value        = {serial}
                    required     = {true}
                    placeholder  = {T.series}
                    wrong        = {isNotValid.serial}
                    wrongMessage = {'Формат серии паспорта (Например, 9988)'}
                    onChange     = {onChangeInput}
                    onBlur       = {checkValid}
                    tabIndex     = {111}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name         = "number"
                    value        = {number}
                    required     = {true}
                    placeholder  = {T.number}
                    wrong        = {isNotValid.number}
                    wrongMessage = {'Номер паспорта (Например, 999888)'}
                    onChange     = {onChangeInput}
                    onBlur       = {checkValid}
                    tabIndex     = {112}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name        = "who_give"
                    value       = {who_give}
                    required    = {true}
                    placeholder = {T.issuedBy}
                    onChange    = {onChangeInput}
                    tabIndex    = {113}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name         = "date_get"
                    value        = {date_get}
                    required     = {true}
                    placeholder  = {T.dateOfIssueInput}
                    wrong        = {isNotValid.date_get}
                    wrongMessage = {T.inCorrectDate}
                    onChange     = {onChangeInput}
                    tabIndex     = {114}
                />
            </Col>
        </Row>
    </>

};

export default IndividualForm;
