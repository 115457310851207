import T from "./types";

const initialState = 0;

export default function activeTab(state = initialState, action) {
  switch (action.type) {
    case T.SET_ACTIVE_TAB_ADMIN:
      return action.activeTab;
    default:
      return state;
  }
}
