// prettier-ignore-start
import ActionMessageForm  from "./Base/Forms/ActionMessageForm";
import ActionFile         from "./Base/ActionFile";
import ActionFormType     from "./ActionFormType";


const ActionMessageFormAppDataFile = () => {

    const actionFile = ActionFile();

    const data     = actionFile.data;
    const formCols = actionFile.formCols;

    const form = (props) => ActionMessageForm().form({ ...props, runText: 'Загрузить'}, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAppDataFile;
