import React from "react";

import Button from "./UI-kit/Buttons/Button";
import Title from "./UI-kit/Title";
import { Row } from "styled-bootstrap-grid";

import ButtonFixedGroup from "./UI-kit/Buttons/ButtonsFixedGroup";
import {useHistory} from "react-router-dom";

// prettier-ignore
const Tabbed = ({ title = '', tab = '', tabs = {} }) => {

    const history = useHistory();

    const tabKeys       = Object.keys(tabs);

    const isTitleEmpty  = title === '';

    const defaultTab    = tabKeys[0];
    const isTabSelected = tab && (tab !== '') && tabs[tab];
    const activeTab     = isTabSelected ? tab : defaultTab;

    const tabContent    = React.createElement(tabs[activeTab].component, tabs[activeTab].componentProps ?? null);

    const goToTab = (tab) => history.push(tabs[tab].route);

    const splitTabsInGroups = (tabKeys) => {
        let groups = [];
        let group  = [];

        tabKeys.forEach((key) => {
            if (tabs[key].title === '') {
                groups.push(group);
                group = [];
            } else {
                group.push(key);
            }
        });

        if (group.length > 0) groups.push(group);

        return groups;
    }

    const outTabGroupTabs = (tabKeys) => (
        tabKeys.map((key) => (
            <Button
                //setActiveTab
                onClick   = {() => goToTab(key)}
                key       = {key}
                className = {key === activeTab ? "active" : ""}
            >
                {tabs[key].title}
            </Button>
        ))
    )

    const tabGroups = splitTabsInGroups(tabKeys);

    const outTabGroup = (key) => {
        const tabGroup = tabGroups[key];

        return (tabGroup.length > 1)
            ? <ButtonFixedGroup margin="0 16px 0 0" key={key}>
                {outTabGroupTabs(tabGroup)}
              </ButtonFixedGroup>
            : <span style={{margin: '0 16px 0 0'}} key={key}>
                {outTabGroupTabs(tabGroup)}
              </span>;
    }


    return (
        <>
            {! isTitleEmpty &&
                <Row style={{alignItems: "center"}}>
                    <Title margin="30px 30px 15px 15px">{title}</Title>
                </Row>
            }

            <Row style={{margin:'0 0 0 0px'}}>
                {Object.keys(tabGroups).map((key) => outTabGroup(key))}
            </Row>

            {tabContent}
        </>
    );
};

export default Tabbed;
