import { combineReducers } from 'redux';
import { loaderReducer } from './loader/loader';
import emptyFieldsIds from './emptyFieldsIds/emptyFieldsIds';
import activeTab from './activeTab/activeTab';
import activeStep from './activeStep/activeStep';
import elements from './elements/elements';
import isPreviewMode from './previewMode/previewMode';
import userAnswers from './userAnswers/userAnswers';
import favoriteElements from './favoriteElements/favoriteElements';
import isHiddenMobileButton from './isHiddenMobileButton/isHiddenMobileButton';
import popups from './popups/popups';
import bonuses from './bonuses/slice';
import answersInViewport from './answersInViewport/answersInViewport';
import bonusesPopUp from "./bonusesPopUp/slice";
import {configureStore} from '@reduxjs/toolkit'
import isAutosave from "./autosave/autosave";


const reducers = combineReducers({
  activeTab,
  bonuses,
  bonusesPopUp,
  favoriteElements,
  loaderReducer,
  elements,
  isPreviewMode,
  isAutosave,
  userAnswers,
  activeStep,
  isHiddenMobileButton,
  popups,
  emptyFieldsIds,
  answersInViewport,
});

const store = configureStore({reducer: reducers})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

