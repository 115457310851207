import {storageData} from "../functions/auth";
import {AccessRightType, useAccessRights, UseAccessRightsType} from "./useAccessRights";
import {isNotEmpty} from "../functions/common";


export const useUserAccessRights: (defaultAccessRightValue: AccessRightType) => UseAccessRightsType = (defaultAccessRightValue) => {

  const data = localStorage.getItem(storageData.accessRights);
  const dataJSON = isNotEmpty(data) ? JSON.parse(String(data)) : {};

  const accessRights: UseAccessRightsType = useAccessRights(
      dataJSON,
      defaultAccessRightValue
  );


  return {
    ...accessRights
  };

};
