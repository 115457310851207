import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../constants/Breakpoints';
import {
  accent,
  gray,
  green,
  lightgray,
  ultraLightgray,
  yellow,
} from '../../constants/Colours';
import { T } from '../../lang/ru';
import Tooltip from './Tooltip';
import view from '../../assets/svg/view.svg';
import noview from '../../assets/svg/no-view.svg';

type ShowPasswordProps = {
  isVisible: boolean;
};

const ShowPassword = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -35px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  opacity: 30%;
  background: url(${(props: ShowPasswordProps) =>
      props.isVisible ? view : noview})
    0 0 no-repeat;
`;

type WrapperProps = {
  wrong: boolean;
  wrongMessage: string;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 200ms linear;
  margin-top: ${(props: WrapperProps) =>
    props.wrong === true && props.wrongMessage !== '' ? '10px' : '0px'};
`;

type InputProps = {
  wrong: boolean;
  correct: boolean;
  disabled: boolean;
};

export const Input = styled.input`
  font-size: 16px;
  padding: 15px 15px 15px 20px;
  margin: 10px 0;
  display: block;
  width: 300px;
  background-color: ${(props: InputProps) =>
    props.disabled ? ultraLightgray : 'white'};
  border: 1px solid
    ${(props: InputProps) =>
      props.disabled === true
        ? lightgray
        : props.wrong === true
        ? yellow
        : props.correct === true
        ? green
        : lightgray};
  border-radius: 4px;
  &:disabled {
    color: ${gray};
  }
  &:focus {
    border-color: ${accent};
    outline: none;
  }
`;

type LabelProps = {
  isOnTop: boolean;
};

const Label = styled.label`
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;

  top: ${(props: LabelProps) => (props.isOnTop ? '12px' : '25px')}};
  font-size: ${(props: LabelProps) => (props.isOnTop ? '12px' : '18px')}};
  color: ${gray};
  
  transition: 0.2s ease all;
  
  ${Input}:focus ~ & {
    top: 11px;
    font-size: 12px;
    width: 230px;
    background: white;
  }
`;

const WrongLabel = styled.label`
  position: absolute;
  left: 20px;
  top: -10px;
  font-size: 12px;
  color: ${yellow};
`;

const RequiredSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${yellow};
  border: 1px solid ${yellow};
  border-radius: 100px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  ::after {
    content: '!';
  }
  @media ${device.tablet} {
    display: none;
  }
`;

type FloatingPasswordProps = {
  name: string;
  value: string;
  onChange?: (e: any) => void;
  required?: boolean;
  onBlur?: (e: any) => void;
  placeholder?: string;
  wrong?: boolean;
  disabled?: boolean;
  wrongMessage?: string;
  style?: any;
  width?: string;
  onKeyPress?: any;
};

const FloatingPassword: React.FC<FloatingPasswordProps> = ({
  required = false,
  placeholder = '',
  value = '',
  onChange,
  name = '',
  onBlur = () => {},
  wrong = false,
  wrongMessage = '',
  disabled = false,
  style = {},
  onKeyPress = () => {},
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const onClickVisible = () => {
    setIsVisible(!isVisible);
  };

  const getType = () => {
    return isVisible ? 'text' : 'password';
  };

  return (
    <Wrapper wrong={wrong} wrongMessage={wrongMessage}>
      {wrong === true ? <WrongLabel>{wrongMessage}</WrongLabel> : null}

      <Input
        onKeyPress={onKeyPress}
        correct={value !== ''}
        wrong={wrong}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        value={value}
        type={getType()}
        name={name}
        style={style}
      />
      <ShowPassword onClick={onClickVisible} isVisible={isVisible} />

      <Label isOnTop={value !== ''}>{placeholder}</Label>
      {required === true ? (
        <Tooltip margin={{ left: 15 }} label={T.fieldRequired}>
          <RequiredSign />
        </Tooltip>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(FloatingPassword);
