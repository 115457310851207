// prettier-ignore-start
import React from "react";

import { T }             from "../../../../../lang/ru";
import { isNotEmpty }    from "../../../../../functions/common";
import { defaultResult } from "./Common";
import {useUserAccessRightChecks} from "../../../../../hooks/useUserAccessRightChecks";


export const Status = () => {
    const userAccess = useUserAccessRightChecks();

    return {
        head: (rowData) => T.status,

        body: (rowData) => {
            const {status_text='', status_comment='',old_status_text='', old_status_id=''} = rowData;

            return <>
                {status_text}
                {isNotEmpty(status_comment) && <><br /><em style={{padding:'0px 0px 0px 0px', whiteSpace: 'normal', textIndent: 'unset'}}>&quot;{status_comment}&quot;</em></>}
                {(userAccess.isAllowAppListStatusOld) && <>
                    <br/>[{old_status_text} ({old_status_id})]
                </>}
            </>
        },

        headProps: (rowData) => ({className: "fixed-row short"}), //fixed-col

        bodyProps: (rowData) => ({
            ...defaultResult.bodyProps(rowData),

            isFixed: false
        }),
    };
}