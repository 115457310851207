// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";
import {TableSubTitle} from "../TableSubTitle";
import {NumbToLocale} from "../../../../../functions/common";
import {Empty} from "../../../../../components/Empty";


export const Promocodes = () => {

/*
  <th>{T.promo}</th>
      <td>
        {hasPromocode && <br />}
        <OrderTableSelect
          name          = "promocode_id"
          selectedValue = {parseInt(hasPromocode ? promocode_id : '0')}
          options       = {promocodesOptions}
          onChange      = {updateSelect}
          placeholder   = {T.empty.promocode}
        />
        {hasPromocode && (
          <>
            <br />
            <TableSubTitle>
              {NumbToLocale(price_details.promocode_price)} ₽ {getPromocodeValueText()}
            </TableSubTitle>
          </>
        )}
      </td>
*/


    const getPromocodeValueText = (id, value, value_type_id, price_value) => {
        if (!!id) return '';

        switch (value_type_id) {
            case 0  : return '('+ value +'%)';
            case 1  : return (value === price_value) ? '' : '('+ NumbToLocale(value) +' ₽)';

            default : return '';
        }
    };


    return {
        head : (rowData) => T.promo,

        body : (rowData) => {
            const {promocode_id=0, promocode_name=0, promocode_value=0, promocode_value_type_id=0, price_details={}} = rowData;
            const {promocode_price=0} = price_details;

            const hasPromocode = !!promocode_id;

            return (! hasPromocode)
                ? <Empty text='нет' />
                : <>
                    <br />
                    {promocode_name}<br />
                    <TableSubTitle>
                        {NumbToLocale(promocode_price)} ₽ {getPromocodeValueText(promocode_id, promocode_value, promocode_value_type_id, promocode_price)}
                    </TableSubTitle>
            </>
        },
    };

};