import styled from 'styled-components';
import {
  accent,
  gray,
  green,
  lightgray,
  red,
  white,
  yellow,
} from '../../../constants/Colours';

type ButtonProps = {
  disabled?: boolean;
  margin?: string;
  success?: boolean;
  isDanger?: boolean;
  empty?: boolean;
  private?: boolean;
};

const Button = styled.button`
  padding: 16px 22px;
  //max-height: 54px;
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.empty || props.private ? yellow : accent)};
  color: ${(props) => (props.empty || props.private ? yellow : accent)};
  background-color: ${white};
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  margin: ${(props: ButtonProps) => (props.margin ? props.margin : '10px 0')};
  background-position: center;
  transition: all 0.2s;

  ${(props: ButtonProps) => {
    const { isDanger = false } = props;
    if (isDanger)
      return `
      border-color: ${red} !important;
      color: ${red} !important;
      &:hover {
        background: ${red} radial-gradient(circle, transparent 1%, ${red} 1%)
          center / 15000% !important;
      color: ${white} !important;

      }
    `;
  }};

  ${(props: ButtonProps) =>
    props.disabled === true
      ? `{
          border-color: ${gray};
          background-color: ${lightgray};
          color: ${gray};
          &:hover{
            border-color: ${gray} !important;
            background-color: ${lightgray} !important;
            color: ${gray} !important;
          }
 }`
      : `
      &:hover {
        background: ${
          props.private ? yellow : accent
        } radial-gradient(circle, transparent 1%, ${
          props.private ? yellow : accent
        } 1%)
          center / 15000%;
        color: ${white};
      }
      :active,
      :focus {
        background-color:  ${props.private ? yellow : '#6eb9f7'};
        color: ${white};
        border-color: transparent;
        background-size: 100%;
        transition: background 0s;
      }
    
      &.active {
        cursor: default;
        color: ${white};
        background: ${accent};
        //color: ${lightgray};
        //border-color: ${lightgray};
        // z-index: -1;
        &:hover,
        &:focus,
        &:active {
          //color: ${lightgray};
          //border-color: ${lightgray};
          //background: transparent 50%;
        }
      }
      `};

  ${(props: ButtonProps) =>
    props.success === true &&
    `{
              border-color: ${green};
              background-color: ${white};
              color: ${green};
              &:hover {
                background: ${green} radial-gradient(circle, transparent 1%, ${green} 1%)
                  center / 15000%;
                color: ${white};
              }
              :active,
              :focus {
                background-color: #6eb9f7;
                color: ${white};
                border-color: transparent;
                background-size: 100%;
                transition: background 0s;
              }
            
              &.active {
                cursor: default;
                color: ${lightgray};
                border-color: ${lightgray};
                // z-index: -1;
                &:hover,
                &:focus,
                &:active {
                  color: ${lightgray};
                  border-color: ${lightgray};
                  background: transparent;
                }
              }
        }
     `};
`;

export default Button;
