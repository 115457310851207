// prettier-ignore-start
import LIT            from "../../../../components/Lists/ListItemTypes";
import { isNotEmpty } from "../../../../functions/common";


const ActionFile = () => {

    const fieldName = 'file';

    const data = (app, field = null) => ({
        fieldName : null,
    });

    const formCols = (props, field = null, titleComment = null) => {

        const fields = {
            result         : 'result_file_name',
            signed_contract: 'signed_contract',
            signed_act     : 'signed_act',
            payment_order  : 'payment_order',
            // contract,act,invoice,invoice_prepay,invoice_postpay
        }
        const $fileUploaded = isNotEmpty(props?.app[fields[props?.props]] ?? null)

        return [
            ...( ($fileUploaded) ? [{ name: '', title: 'Файл уже загружен.', type: ''}, { name: '', title: 'Выбранный файл заменит загруженный ранее.', type: ''}] : []),

            { name: fieldName, title: (titleComment ?? 'Файл'), type: LIT.file },
        ];
    }


    return {
        data     : data,
        formCols : formCols
    }

};

export default ActionFile;
