// prettier-ignore-start
import React, { useState } from "react";

import { useHistory }    from "react-router-dom";
import ym                from "react-yandex-metrika";
import ReactGA           from "react-ga";
import { Row, Col }      from "styled-bootstrap-grid";

import { T }             from "../../../lang/ru";
import { Payers }        from "../../../api/methods";
import { PATHS }         from "../../../constants/RoutesPaths";
import hooks             from "../../../functions/hooks";

import Title             from "../../../components/UI-kit/Title";
import Subtitle2         from "../../../components/UI-kit/Subtitle2";
import Radio             from "../../../components/UI-kit/Radio";
import Button            from "../../../components/UI-kit/Buttons/Button";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import { CheckBoxLabel } from "../../../components/UI-kit/CheckBoxLabel";
import Checkbox          from "../../../components/UI-kit/Checkbox";
import { LinkButton }    from "../../../components/UI-kit/Buttons/LinkButton";
import IndividualForm    from "./OrderNewSt1SetPayerNew/IndividualForm";
import EntityForm        from "./OrderNewSt1SetPayerNew/EntityForm";
import IeForm            from "./OrderNewSt1SetPayerNew/IeForm";


const OrderNewSt1SetPayerNew = () => {

    const history = useHistory();

    const [formName  , setFormName  ] = useState("1" );
    const [isDisabled, setIsDisabled] = useState(true);

    const [isAgree   , switchIsAgree] = hooks.useCheckbox();

    const [individualInputs, setIndividualInputs] = useState({
        type         : "1",
        surname      : "",
        adress       : "",
        name         : "",
        phone        : "",
        middlename   : "",
        email        : "",
        inn          : "",
        serial       : "",
        number       : "",
        who_give     : "",
        date_get     : "",
    });

    const [entityInputs, setEntityInputs] = useState({
        type         : "2",
        name_org     : "",
        legal_adress : "",
        deliveryAddressMatches: false,
        inn          : "",
        kor          : "",
        kpp          : "",
        ras          : "",
        bank         : "",
        phone        : "",
        bik_bank     : "",
        email        : "",
        position     : "",
        base         : "",
        surname      : "",
        name         : "",
        middlename   : "",
    });

    const [ieInputs, setIeInputs] = useState({
        type         : "3",
        name_ip      : "",
        legal_adress : "",
        deliveryAddressMatches: false,
        inn          : "",
        kor          : "",
        ogrn         : "",
        ras          : "",
        bank         : "",
        phone        : "",
        bik_bank     : "",
        email        : "",
        surname      : "",
        name         : "",
        middlename   : "",
    });

    const addNewPayer = () => {
        const newPayer =
            formName === "1"
                ? individualInputs
                : formName === "2"
                    ? entityInputs
                    : ieInputs;

        Payers.add({ ...newPayer }, goToRequestCustomers);
    };

    const goToRequestCustomers = () => {
        ym("reachGoal", "save");
        ReactGA.ga("send", "event", "save", "submit");

        history.push(PATHS.orderNewSt1SetPayer);
    };

    const isPhysicalPerson = formName === "1";
    const isDisabledNext = isDisabled || (!isAgree && isPhysicalPerson);


    return <>
        <Title>{T.addNewCustomer}</Title>

        <Subtitle2>{T.addNewCustomerText}</Subtitle2>
        <Radio
            radios     = {T.addNewCustomerRadio}
            nameRadio  = "сustomer"
            setValue   = {setFormName}
            radioValue = {formName}
            tabIndex   = {10}
        />

        {isPhysicalPerson ? (
            <IndividualForm
                setInputsValue = {setIndividualInputs}
                inputsValue    = {individualInputs}
                setIsDisabled  = {setIsDisabled}
            />
        ) : formName === "2" ? (
            <EntityForm
                setInputsValue = {setEntityInputs}
                inputsValue    = {entityInputs}
                setIsDisabled  = {setIsDisabled}
            />
        ) : (
            <IeForm
                setInputsValue = {setIeInputs}
                inputsValue    = {ieInputs}
                setIsDisabled  = {setIsDisabled}
            />
        )}

        {isPhysicalPerson && <div style={{ margin: "13px 0" }}>
            <CheckBoxLabel>
                <Checkbox
                    className = "isAgree"
                    name      = "isAgree"
                    checked   = {isAgree}
                    onChange  = {switchIsAgree}
                    tabIndex  = "1001"
                />
                <span style={{ marginLeft: 13 }}>
                    Соглашаюсь на обработку
                    <LinkButton
                        target   = {"_blank"}
                        style    = {{ display: "inline-flex" }}
                        to       = {PATHS.agreement}
                        tabIndex = "1002"
                    >
                        персональных данных
                    </LinkButton>
                </span>
            </CheckBoxLabel>
        </div>}

        <Row>
            <Col>
                <Button onClick={addNewPayer} disabled={isDisabledNext} tabIndex={1010}>
                    {T.saveAndContinue}
                </Button>

                <SecondOrderButton onClick={goToRequestCustomers} tabIndex={1011} marginLeft>
                    {T.back}
                </SecondOrderButton>
            </Col>
        </Row>
    </>

};

export default OrderNewSt1SetPayerNew;
