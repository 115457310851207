// prettier-ignore-start
import React from "react";

import { useHistory }    from "react-router-dom";
import {isEmpty}         from "../../functions/common";
import SecondOrderButton from "../../components/UI-kit/Buttons/SecondOrderButton";
import Button            from "../../components/UI-kit/Buttons/Button";


const BackButton = ({buttonType = 0, to = '' }) => {
    const history = useHistory();

    const goTo = () => {
        if (isEmpty(to)) {
            history.goBack();
        } else {
            history.push(to);
        }
    };

    if (buttonType === 0) return <Button onClick={goTo}>&lt;&lt;</Button>;

    return <SecondOrderButton onClick={goTo}>Вернуться к заявкам</SecondOrderButton>;

};

export default BackButton;
