// prettier-ignore-start
import React from "react";

import {Application             } from "../../../api/methods";
import {isEmpty                 } from "../../../functions/common";
import {getFileExtension        } from "../../../functions/getFileExtension";

import FileHandler                from "../../../components/File/FileHandler";
import {Empty                   } from "../../../components/Empty";
import {useAppAccessRightChecks} from "../../../hooks/useAppAccessRightChecks";


const OrderInfoResult = ({applicationData}) => {

    const appAccess  = useAppAccessRightChecks(applicationData?.id);

    const {
        id,
        contract_number  = '',
        result_file_name = null,
    } = applicationData;

    const userFileName = isEmpty(result_file_name) ?  null : `Результат к заявке №${contract_number}.${getFileExtension(result_file_name)}`;

    const doUpload   = (data, success, fail, load) => Application.loadResults (data, success, fail, load);
    const doDownload = (data, success, fail, load) => Application.getResult   (data, success, fail, load);
    const doDelete   = (data, success            ) => Application.deleteResult(data, success);

    //isDeleteBlocked  = {parseInt(is_result_checked) === 1}
    //userCanDelete    = {status !== "CUSTOMER"}
    //userCanUpload    = {status !== "CUSTOMER"}

    if (! appAccess.isAllowAppDataDocsResult) return null;
    if (isEmpty(result_file_name) && (! appAccess.isAllowAppDataDocsResultCreate)) return <Empty text='отсутствует' />

    return (
        <FileHandler
            data             = {{id}}
            fileName         = {userFileName}
            userCanDelete    = {appAccess.isAllowAppDataDocsResultCreate}
            userCanUpload    = {appAccess.isAllowAppDataDocsResultCreate}

            isDeleteBlocked  = {! appAccess.isAllowAppDataDocsResultCreate}

            onUpload         = {doUpload}
            onDownload       = {doDownload}
            onDelete         = {doDelete}

            uploadButtonText = "Загрузить результаты заказчику"
            subjectIm        = "результаты"
            subjectRod       = "результатов"
        />
    );

};

export default OrderInfoResult;