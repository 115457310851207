import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { accent } from '../../constants/Colours';


export class RadioGroup extends React.Component {
  render() {
    const {
      Component,
      name,
      selectedValue,
      onClickRadioButton,
      children,
      ...rest
    } = this.props;
    return (
      <Component role="radiogroup" {...rest} name={name}>
        {React.Children.map(children, (element) =>
          React.cloneElement(element, {
            ...element.props,
            checked: selectedValue === element.props.labelText,
            onChange: () => onClickRadioButton(element.props.labelText),
            name,
          })
        )}
      </Component>
    );
  }
}

RadioGroup.propTypes = {
  name     : PropTypes.string,
  children : PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

RadioGroup.defaultProps = {
  name         : "",
  selectedValue: "",
  Component    : "div",
};

// eslint-disable-next-line react/no-multi-comp
export class Radio extends React.Component {
  render() {
    const { onChange, value, labelText, checked, name } = this.props;

    return (
      <Root>
        <label>
          {labelText}
          <Input
            type         = "radio"
            onChange     = {onChange}
            name         = {name}
            value        = {value}
            checked      = {checked}
            aria-checked = {checked}
          />
          <Fill />
          {this.props.children}
        </label>
      </Root>
    );
  }
}

Radio.propTypes = {
  onChange : PropTypes.func,
  value    : PropTypes.string,
  labelText: PropTypes.string,
};

Radio.defaultProps = {
  onChange : () => {},
  value    : "",
  labelText: "",
};

const Root = styled.div`
  margin: 5px;
  margin-bottom: 13px;
  cursor: pointer;
  position: relative;
  label {
    cursor: pointer;
    padding-left: 30px;
    display: flex;
  }
  &::before {
    content: '';
    border-radius: 100%;
    height: 22px;
    width: 22px;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : accent)};
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : '#fff'};
    position: absolute;
    top: 2px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  background: ${(props) => (props.fillColor ? props.fillColor : accent)};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 13px;
  left: 11px;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: 7px;
    position: absolute;
    height: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid
      ${(props) => (props.borderActive ? props.borderActive : accent)};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: 8px;
      height: 8px;
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;
