// prettier-ignore-start
import React, { useEffect } from "react";

import { useParams }                 from "react-router-dom";

import { User, Bonuses }             from "../../../../../api/methods";
import { useDataRequest }            from "../../../../../hooks/useDataRequest";

import { PATHS }                     from "../../../../../constants/RoutesPaths";
import Title                         from "../../../../../components/UI-kit/Title";
import Text                          from "../../../../../components/UI-kit/Text";
import Stack                         from "../../../../../components/UI-kit/layout/Stack"
import { Preloader }                 from "../../../../../components/UI-kit/Preloader/Preloader";
import OrderInfoSpecialistAdminTable from "../../../../Payer/PersonTable";
import History                       from "../../../../Bonuses/History";
import EditUserHistory               from "../../../../Bonuses/EditUserHistory";
import BackButton                    from "../../../BackButton";


const UserBonuses: React.FC<any> = () => {

    const { user_id }:any = useParams();

    const userInfo: any = useDataRequest({
        request      : User.getById,
        requestData  : { id: user_id },
        defaultResult: {},
        autoLoad     : true,
    });

    const historyItems:any = useDataRequest({
        request      : Bonuses.getUserHistory,
        defaultResult: [],
    });

    useEffect(() => {
        if (userInfo.data?.id ?? false)
            historyItems.load({ id: userInfo.data.id });
    }, [ userInfo.data?.id ])


    const names = {
        id                       : "ID",
        surname                  : "Фамилия",
        name                     : "Имя",
        middlename               : "Отчество",
        email                    : "Email",
        phone                    : "Телефон",
        is_bonus_program_member  : "Участник бонусной программы",
        is_bonus_program_autoset : "Автоназначение программы",
        bonus_program_status_id  : "Статус бонусной программы",
        bonuses_count            : "Баланс",
        bonuses_reserved_count   : "Количество заблокированных бонусов",
    };

    const listDisabledToUpdate = [
        "id",
        'surname',
        'name',
        'middlename',
        'email',
        'phone',
        "bonuses_count",
        'bonuses_reserved_count'
    ];

    const listToHide = [
        "bonus_program_status_id"
    ];

    const listToShow = [
        "is_bonus_program_member",
        "is_bonus_program_autoset"
    ];


    const handleInfoChange = (e: any) => {
        const { value, name } = e.target;
        userInfo.setData({ ...userInfo, [name]: value });
    };

    const balance = historyItems[0]?.balance || 0;


    return <>
        <Stack style={{margin: "30px 0 15px"}}>
            <BackButton buttonType={0} to={PATHS.users} />
            <Title margin="0">Информация о бонусной программе</Title>
        </Stack>

        {userInfo.isLoading
            ? <Preloader />
            : <>
                <OrderInfoSpecialistAdminTable
                    handleChange         = {handleInfoChange}
                    titles               = {names}
                    listDisabledToUpdate = {listDisabledToUpdate}
                    infoCustomer         = {userInfo.data}
                    listToHide           = {listToHide}
                    loadUserInfo         = {userInfo.reload}
                    listToShow           = {listToShow}
                    saveFunc             = {User.update}
                />

                {/* <ExecutorInfoTable data={T} infoCustomer={infoExecutor} /> */}

                {userInfo.data?.is_bonus_program_member
                    ? <>
                        <EditUserHistory
                            id      = {userInfo.data?.id ?? 0}
                            balance = {balance}
                        />
                        {userInfo.data?.id && <History
                            id      = {userInfo.data.id}
                            history = {historyItems.data}
                        />}
                    </>
                    : <Text>Пользователь не является участником бонусной программы</Text>
                }
            </>
        }
    </>

};

export default UserBonuses;
