import T from './types';
import { AnyAction } from 'redux';

const answersInViewport = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case T.IS_IN_VIEWPORT:
      return {
        ...state,
        [action.payload.id]: action.payload.inViewport,
      };
    default:
      return state;
  }
};

export default answersInViewport;
