import React, { useState } from 'react'
import Button from '../../components/UI-kit/Buttons/Button'
import FloatingInput from '../../components/UI-kit/FloatingInput'
import { Bonuses } from '../../api/methods'
import Subtitle from '../../components/UI-kit/Subtitle'
import styled from 'styled-components'
import {useAppDispatch} from "../../hooks/useRedux";
import {setPopUpFields} from "../../store/bonusesPopUp/actions";
import Stack from "../../components/UI-kit/layout/Stack"


const AdminUserHistoryForm = styled.form`
    width: 60%;
    input, textarea {
        width: 100%;
    }
`

export default function EditUserHistory({balance, id}: {balance: number, id: string}) {
    const dispatch = useAppDispatch()
    const [data   , setValue] = useState<{value: string, userMsg: string, adminMsg: string}>({value: "", userMsg: "", adminMsg: ""})
    const [isWrong, setWrong] = useState<boolean>(false)

    const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setValue(prev => ({...prev, [name]: value}))
    }
    const onBlur = () => {
        if (isNaN(+data.value) || 
            !data.value ||  
            +data.value === 0) {
            setWrong(true)
        } else {
            setWrong(false)
        }
    }


    const onFinish = (msg: string, reload: boolean = true) => {
        dispatch(setPopUpFields(
            {
                title: "Изменения баланса",
                msg,
                reload,
                visible: true
            }
        ))
    }


    const submit = (e: any) => {
        e.preventDefault()
        const value = (+data.value < 0 && Math.abs(+data.value) > balance)
            ? -balance : data.value

        if (!isWrong) {
            Bonuses.editUserHistory(
                {id, 
                count: value, 
                description: data.userMsg,
                admin_comment: data.adminMsg},
                () => onFinish("Операция прошла успешно"),
                () => onFinish("Не удалось изменить баланс", false))
        }
    }


    return (
        <>
            <Subtitle style={{margin: "30px 0 0"}}>Начислить бонусы:</Subtitle>
            <AdminUserHistoryForm > 
                <FloatingInput 
                    name         = "value"
                    value        = {data.value}
                    onChange     = {changeValue}
                    onBlur       = {onBlur}
                    wrong        = {isWrong}
                    wrongMessage = "Введите корректную сумму"
                    placeholder  = "Сумма бонусов"
                    />
                <Stack stretchItems>
                    <FloatingInput
                        name        = "userMsg"
                        value       = {data.userMsg}
                        placeholder = "Сообщение пользователю..."
                        onChange    = {changeValue}
                        isTextarea/>
                    <FloatingInput
                        name        = "adminMsg"
                        value       = {data.adminMsg}
                        placeholder = "Внутренний комментарий..."
                        onChange    = {changeValue}
                        isTextarea/>
                </Stack>


                <Button onClick={submit} type="submit">Провести операцию</Button>
            </AdminUserHistoryForm>
        </>
        
  )
}
