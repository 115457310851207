import T from '../emptyFieldsIds/types';

export const setEmptyFields = (fields: string[]) => ({
  type: T.ADD_EMPTY_FIELDS_IDS,
  payload: fields,
});

export const deleteEmptyField = (id: string) => ({
  type: T.REMOVE_FIELD,
  payload: id,
});

export const addEmptyField = (id: string) => ({
  type: T.ADD_FIELD,
  payload: id,
});
