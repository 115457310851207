// prettier-ignore-start
import React, {useState} from "react";

import FloatingSelect     from "../components/UI-kit/FloatingSelect";
import { useDataRequest } from "./useDataRequest";


export function useInputSelect({
    name = '',
    title = '',
    defaultValue = null,
    getOptions = (d, s, f) => s(d),
    getOptionsData = [],
    width = 500,
    onChanged = (value) => {},
    defaultResult = [],
}) {

    const options = useDataRequest({
        autoLoad     : true,
        request      : getOptions,
        requestData  : getOptionsData,
        defaultResult: defaultResult,
    });

    const [value, setValue] = useState( defaultValue );

    const defaultSelectProps = {
        key           : name,
        name          : name,
        value         : -1,
        title         : title,
        onChange      : () => {},
        disabled      : true,
        options       : [],
        containerStyle: {width: width+'px'},
        style         : {width: width+'px'}
    }

    const outLoading =
        <FloatingSelect
            {...defaultSelectProps}
            placeholder = {'загрузка...'}
        />

    const outEmpty =
        <FloatingSelect
            {...defaultSelectProps}
            placeholder = {'(список пуст)'}
        />

    const outLoaded =
        <FloatingSelect
            {...defaultSelectProps}

            placeholder    = {'выберите...'}
            value          = {value}
            onChange       = {(e) => { setValue(e.target.value); onChanged(e); } }
            disabled       = {false}
            options        = {options.data}
        />

    const outSelect = <>
        {options.isLoading
        ? outLoading
        : (options.hasResult
            ? outLoaded
            : outEmpty
        )}
    </>;


    return {
        isLoading : options.isLoading,
        isLoaded    : options.isLoaded,

        load      : options.load,

        value     : value,
        setValue  : setValue,

        options   : options.data,

        hasOptions: options.hasResult,
        hasSet    : value !== defaultValue,

        select    : outSelect,
    };
}

// prettier-ignore-end