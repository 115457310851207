// prettier-ignore-start
import React from "react";

import settingsTabType                      from "../tabType";
import SettingsListsRatingsList             from "./SettingsListsRatingsList";
import SettingsListsRatingRoles             from "./SettingsListsRatingRoles";
import SettingsListsRatingScores            from "./SettingsListsRatingScores";
import SettingsListsRatingScoreValues       from "./SettingsListsRatingScoreValues";


const SettingsListsRatings = ({ sub = settingsTabType.ratings }) => {

    if (sub === settingsTabType.ratingRoles      ) return <SettingsListsRatingRoles       />
    if (sub === settingsTabType.ratingScores     ) return <SettingsListsRatingScores      />
    if (sub === settingsTabType.ratingScoreValues) return <SettingsListsRatingScoreValues />

    return <SettingsListsRatingsList />

};

export default SettingsListsRatings;
