// prettier-ignore-start
import React from "react";

import ActionFormType from "./ActionFormType";


const ActionSplitter = () => {

    return {
        type    : ActionFormType._spliter,

        data    : () => ({}),
        form    : () => <></>,
    }

};

export default ActionSplitter;
