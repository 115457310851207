// prettier-ignore-start
import {T} from "../../../../../lang/ru";


export const PaymentStatus = () => {

/*
  <th>{T.paymentStatus}</th>

    <td style={{ cursor: 'pointer' }}>{payedStatus}</td>
*/

    return {
        head : (rowData) => T.paymentStatus,

        body : (rowData) => rowData.payment_status_text,

        headProps : (rowData) => ({ className: "fixed-row short" }),
    };

};