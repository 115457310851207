import React from "react";

import Text from "./UI-kit/Text";


const Divider = () => {
    return <Text style={{ display: "inline", padding: 5, border: 0, color: "lightgrey"}}> | </Text>;
};

export default Divider;
