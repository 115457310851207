// prettier-ignore-start
import React from "react";

import Table               from "../../../../../components/UI-kit/Table/Table";
import {TrHead}            from "../../../../../components/Lists/Parts/TrHead";
import FormsTableRow       from "./FormsTableRow";
import { FormsTableProps } from "./Types";


const FormsTable: React.FC<FormsTableProps> = ({
    forms     = [],
    loadForms = () => {},
}) => {
    if ((! forms) || (forms?.length === 0)) return <>Список форм пуст</>;

    return (
      <>
          <Table style={{borderCollapse: "separate", width: "100%"}}>
              <thead>
                  <TrHead>
                      <th style={{width:"65%"}}>Название формы</th>
                      <th style={{width:"35%"}}>...</th>
                  </TrHead>
              </thead>
              <tbody>
                  {forms.map(({ id, name, is_published }) => (
                      <FormsTableRow
                          key         = {id}
                          id          = {id}
                          name        = {name}
                          loadForms   = {loadForms}
                          isPublished = {is_published}
                      />
                  ))}
              </tbody>
          </Table>
      </>
    );
};

export default FormsTable;
