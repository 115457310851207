// prettier-ignore-start
import LIT from "../../../../components/Lists/ListItemTypes";
import {dateToday} from "../../../../functions/common";


const ActionAppDataAct = () => {

    const f_app_id              = 'id';                    // id заявки
    const f_act_date            = 'act_date';              // Дата акта


    const data = (app, field = null) => ({
        [f_app_id  ] : app[f_app_id  ] ?? 0,
        [f_act_date] : app[f_act_date] ?? dateToday(),
    })

    const formCols = (props) => [
        { name: f_act_date, title: 'дата акта', type: LIT.date},
    ]

    return {
        data     : data,
        formCols : formCols,
    }

};

export default ActionAppDataAct;
