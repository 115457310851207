import T from "./types";

export const autosaveON = () => ({
    type: T.AUTOSAVE_ON,
});

export const autosaveOFF = () => ({
    type: T.AUTOSAVE_OFF,
});

export const autosaveSet = (value) => ({
    type   : T.AUTOSAVE_SET,
    payload: value,
});
