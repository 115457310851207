import { useState } from "react";
import {useActive} from "./useActive";

// prettier-ignore-start
const defaultProps = {
  loaded  : 0,
  total   : 0,
}

export function useLoading() {

  const active = useActive(false);

  const [props, setProps] = useState( defaultProps );

  const activate   = () => {
    active.activate();
    setProps( defaultProps );
  }

  const onProgress = (event) => {
    const hasGetResponseHeader = !!(event.target.getResponseHeader);

    const contentLengthV1 = hasGetResponseHeader ? event.target.getResponseHeader('content-length'               ) : 0;
    const contentLengthV2 = hasGetResponseHeader ? event.target.getResponseHeader('x-decompressed-content-length') : 0;

    const total = event.lengthComputable
        ? event.total
        : contentLengthV1 || contentLengthV2;

    setProps( {
      loaded: event.loaded,
      total
    });
  }

  return {
    ...active,
    activate: activate,

    props      : {...props},
    setProps   : setProps,

    onProgress : onProgress,
  };
}

// prettier-ignore-end