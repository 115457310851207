// prettier-ignore-start
import React from 'react';

import { Tooltip as TooltipLightweight } from 'react-lightweight-tooltip';
import { black, ultraLightgray } from '../../constants/Colours';

type MarginsType = {
    left?  : number;
    right? : number;
    top?   : number;
    bottom?: number;
};

type TooltipProps = {
    children   : React.ReactNode;
    label      : string;
    margin?    : MarginsType;
    place?     : 'left' | 'right' | 'center';
    background?: string;
    position?  : 'up' | 'down' | 'left' | 'right ';
};

const defaultMargins: MarginsType = { left: 0, right: 0, top: 0, bottom: 0 };


const Tooltip: React.FC<TooltipProps> = ({
    label = '',
    children,
    margin = defaultMargins,
    place = 'center',
    position = 'up',
}) => {

    const placeOffset      = place === 'left' ? 90 : place === 'right' ? 0  : 50;
    const placeArrowOffset = place === 'left' ? 90 : place === 'right' ? 10 : 50;

    const margins = { ...defaultMargins, ...margin };

    return (
        // @ts-ignore
        <TooltipLightweight
            content = { label }
            styles  = { {
                wrapper: {
                    position       : 'relative',
                    display        : 'inline-block',
                    zIndex         : 98,
                    color          : '#555',
                    cursor         : 'default',
                    marginLeft     : margins.left,
                    marginRight    : margins.right,
                    marginBottom   : margins.bottom,
                    marginTop      : margins.top,
                },
                tooltip: {
                    position       : 'absolute',
                    zIndex         : 99,
                    left           : placeArrowOffset + '%',
                    top            : position === 'down' ? '100%' : 'unset',
                    bottom         : position === 'up'   ? '100%' : 'unset',
                    marginTop      : position === 'down' ? '10px' : 'unset',
                    marginBottom   : position === 'up'   ? '10px' : 'unset',
                    padding        : '5px',
                    background     : `${ultraLightgray}`,
                    WebkitTransform: 'translateX(-' + placeOffset + '%)',
                    msTransform    : 'translateX(-' + placeOffset + '%)',
                    OTransform     : 'translateX(-' + placeOffset + '%)',
                    transform      : 'translateX(-' + placeOffset + '%)',
                    borderRadius   : 4,
                },
                content: {
                    background     : 'none', //"#eee",
                    color          : black,
                    position       : 'relative',
                    bottom         : '1px',
                    lineHeight     : '28px',
                    fontSize       : '14px',
                    padding        : '.3em 1em',
                    whiteSpace     : 'nowrap',
                },
                arrow: {
                    position       : 'absolute',
                    width          : '0',
                    height         : '0',
                    top            : position === 'down' ? '-5px' : 'unset',
                    bottom         : position === 'up'   ? '-5px' : 'unset',
                    borderTop      : position === 'up'   ? `solid ${ultraLightgray} 5px` : 'unset',
                    borderBottom   : position === 'down' ? `solid ${ultraLightgray} 5px` : 'unset',
                    left           : placeArrowOffset + '%',
                    marginLeft     : '-5px',
                    borderLeft     : 'solid transparent 5px',
                    borderRight    : 'solid transparent 5px',
                },
                gap: {
                    position       : 'absolute',
                    width          : '100%',
                    height         : '20px',
                    bottom         : position === 'up'   ? '-20px' : 'unset',
                    top            : position === 'down' ? '-20px' : 'unset',
                },
            } }
        >
           { children }
        </TooltipLightweight>
    );
};

export default Tooltip;
