// prettier-ignore-start
import React from "react";

import {PATHS}                       from "../../../constants/RoutesPaths";

import tabType                       from "../tabType";

import Tabbed                        from "../../../components/Tabbed";
import SettingsListsBonuses          from "./SettingsListsBonuses";
import SettingsListsPromocodes       from "./SettingsListsPromocodes";
import SettingsListsSources          from "./SettingsListsSources";
import SettingsListsDeliveries       from "./SettingsListsDeliveries";
import SettingsListsSoftwareProducts from "./SettingsListsSoftwareProducts";
import SettingsListsRatings          from "./SettingsListsRatings";


const SettingsLists = ({ tabs = [] }) => {

    return <Tabbed
        title = ''
        tab   = {tabs[0] ?? tabType.promocodes}
        tabs  = {{
            [tabType.promocodes      ]: { title: 'Промокоды'           , route: PATHS.settings.listsPromocodes      , component: SettingsListsPromocodes       },
            [tabType.bonuses         ]: { title: 'Бонусы'              , route: PATHS.settings.listsBonuses         , component: SettingsListsBonuses          },
            [tabType.sources         ]: { title: 'Источники'           , route: PATHS.settings.listsSources         , component: SettingsListsSources          },
            [tabType.delivery        ]: { title: 'Доставка'            , route: PATHS.settings.listsDelivery        , component: SettingsListsDeliveries       },
            [tabType.softwareProducts]: { title: 'Программные продукты', route: PATHS.settings.listsSoftwareProducts, component: SettingsListsSoftwareProducts },
            [tabType.ratings         ]: { title: 'Рейтинги'            , route: PATHS.settings.listsRatings         , component: SettingsListsRatings         , componentProps: {sub: tabs[1] ?? ''} },
        }}
    />

};

export default SettingsLists;
