// prettier-ignore-start
import React from "react";
import {T} from "../../../../../lang/ru";


export const RoomsCount = () => {

/*
  {!isProPBSystem && (
      <th className="short">

      </th>
  )}
      {!isProPBSystem && (
        <td className="input short">
          <OrderTableInput
            name        = "rooms_count"
            value       = {application.rooms_count}
            onChange    = {handleChangeInput}
            onBlur      = {updateInput}
            width       = {150}
            placeholder = {0}
          />
        </td>
      )}

*/


    return {
        head : (rowData) => <>{T.roomsCount}<br />{T.roomsCount2}</>,

        body : (rowData) => rowData.rooms_count ?? 0,

        headProps : (rowData) => ({ className: "fixed-row short" }),

    };

};