// prettier-ignore-start
import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
//import { useDispatch }         from "react-redux";

import { T }                 from "../../../lang/ru";
import { Category, getPath } from "../../../api/methods";
import { PATHS }             from "../../../constants/RoutesPaths";
import { useDataRequest }    from "../../../hooks/useDataRequest";
//import { setActiveStep } from "store/activeStep/actions";

import InputsGrid            from "../../../components/UI-kit/InputsGrid";
import { Preloader }         from "../../../components/UI-kit/Preloader/Preloader";
import BackTitleButton       from "../BackTitleButton";
import ChoiceCard            from "./OrderNewSt2SetCategory/ChoiceCard";


const OrderNewSt2SetCategory = () => {

      const { payer_id } = useParams();
      const history      = useHistory();
      //const dispatch     = useDispatch();

      const content = useDataRequest({
          request       : Category.getAllSelect,
          defaultResult : [],
      });

      useEffect(() => {
          content.load();
        //dispatch(setActiveStep(2));
      }, []);

      const onClickCategory = (id) => history.push( getPath(PATHS.orderNewSt3SetForm, {category_id: id, payer_id}) );


      return <>
          <BackTitleButton title={T.chooseCategory} backTo={PATHS.orderNewSt1SetPayer}/>

          {content.isLoading && <Preloader />}
          {content.isLoaded  && <InputsGrid
            maxWidth="1000px"
            columns="1fr 1fr 1fr"
            gap="0 20px"
            margin="20px 0 0"
          >
            {content.data.map( ({id, name}) => <ChoiceCard key={id} title={name} onClick={() => onClickCategory(id)}/> )}
          </InputsGrid>}
      </>

};

export default OrderNewSt2SetCategory;
