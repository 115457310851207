import React from "react";

import {fieldType} from "../constants";
import FormInput from "./FormInput";
import FormFileImage from "./FormFileImage";
import FormGroup from "./FormGroup";

// prettier-ignore-start

// вывод всех полей формы
const FormFieldsList = ({
    fieldsStruct = [],

    data = [],
    updateData = (newData) => {}, // внесение изменений в данные: {...data, ...newData}

    onChange = () => {},
    onBlur = () => {}, //
}) => {

    return Object.values(fieldsStruct).map(( field ) => {
        const props = {
            key        : field.name,
            name       : field.name,
            dataName   : field.dataName,
            text       : field.text ?? '',
            props      : field.props ?? {},
            data       ,
            onChange   ,
            onBlur     ,
            updateData ,
        };

        switch ( field.type ) {
            case fieldType.input     : return <FormInput     {...props}/>
            case fieldType.fileImage : return <FormFileImage {...props}/>
            case fieldType.group     : return <FormGroup     {...props}/>
        }

    });

}

export default FormFieldsList;