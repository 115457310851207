import styled from "styled-components";
import Button from "./Buttons/Button";

const InputButtonGroup = styled.div`
  display: flex;
  & input {
    border-radius: 4px 0 0 4px;
  }

  & ${Button} {
    position: relative;
    left: -1px;
    border-radius: 0 4px 4px 0;
  }
`;

export default InputButtonGroup;
