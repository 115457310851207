import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { accent, darkaccent } from "../../../constants/Colours";

export const Btn = styled.a`
  color: ${accent};
  padding: 5px 5px;
  border: none !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  :hover {
    background: transparent;
    color: ${darkaccent};
  }
  :active,
  :focus {
    background-color: "transparent";
    background-size: 100%;
    color: ${darkaccent};
    transition: background 0s;
  }
`;

export const LinkButton = ({
  to = "",
  href = "",
  download = false,
  children,
  padding = "5px 5px",
  style = {},
  target = "",
  tabIndex = 0,
}) => {
  return download ? (
    <Btn
        href     = {href}
        style    = {{ padding }}
        tabIndex = {tabIndex}
        download
    >
      {children}
    </Btn>
  ) : (
    <Link
      to       = {to}
      style    = {{ textDecoration: "none", padding: 0, border: "none", ...style }}
      target   = {target}
      tabIndex = {tabIndex}
    >
      <Btn
          style    = {{ padding }}
          tabIndex = {tabIndex}
      >
        {children}
      </Btn>
    </Link>
  );
};
