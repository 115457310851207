import styled from "styled-components";
import {
  accent,
  gray,
  green,
  lightgray,
  red,
  white,
} from "../../../../constants/Colours";

const bgAccent = "#E9F2FF";
const bgAccentHover = "#D1E4FF";
const newAccent = "#4182E2";
const newAccentHover = "#2D6CCA";

export const NewButton = styled.button`
  display:flex;
  align-items:center;
  justify-content:center;
  padding: 20px 26px 22px !important;
  border:none;
  font-size:16px;
  font-weight: 500;
  background-color: ${bgAccent};
  color: ${newAccent};
  border-radius:10px;
  cursor:pointer;
  transition: all 100ms linear;
  &:hover{
    background-color: ${bgAccentHover};
  }
  * {
    text-decoration:none;
  }
  ${(props) => {
    let additionStyles = ``

    if (props.type == "text")
      additionStyles += `
        background-color: transparent !important;
        &:hover{
          color: ${newAccentHover};
        }
      `
    return additionStyles
  }}
`;

