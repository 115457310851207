import React, { useState, useRef } from 'react';
import { Element } from '../../../api/methods';
import { SmallInput } from '../../../components/UI-kit/SmallInput';

export default function SmallInputElement({
  name,
  deleteStruct,
  id,
  struct,
  elementId,
}) {
  const [error, setError] = useState(false);
  const [value, setValue] = useState(name);
  const ref = useRef(null);
  const handleUpdate = () => {
    if (struct.filter((i) => i.id !== id).every((i) => i.name !== value)) {
      Element.structUpdate({ id, name });
    } else {
      alert('Вы ввели одинаковые поля!');
      setError(true);
      setValue(struct.find((i) => i.id === id).name);
      ref.current.focus();
    }
  };

  const handleChange = (value) => {
    setValue(value);
    setError(false);
  };
  return (
    <>
      <SmallInput
        ref={ref}
        onBlur={handleUpdate}
        value={value}
        error={error}
        onChange={(e) => handleChange(e.target.value)}
      />
      <a
        style={{ marginLeft: 10, cursor: 'pointer' }}
        onClick={() => deleteStruct(elementId, id)}
      >
        &times;
      </a>
    </>
  );
}
