// prettier-ignore-start
import { isProPBSystem } from '../constants/SystemType';

export const T = {
  loading                     : "загрузка...",
  emailAddress                : isProPBSystem ? "info@pbproject.ru" : "info@fabras.ru",
  altImageLogo                : "Логотип",
  phoneNumber                 : isProPBSystem ? "8 800 2222 037" : "8 800 444 3037",
  callFree                    : "Звонок бесплатный",
  logout                      : "Выход",
  login                       : "Войти",
  send                        : "Отправить",
  loginError                  : "Ошибка авторизации. Проверьте правильность введенных данных или повторите попытку позже",
  inCorrectEmail              : "Неправильный формат Email",
  registerSuccess             : "Вы успешно зарегистрировались в системе «Фабрика расчетов»",
  inCorrectPassword           : "Пароли не совпадают",
  rAccount                    : "Расчетный счет",
  kAccount                    : "Кор. счет",
  firstTimeUsageMessage       : "Если Вы используете данный сервис впервые, нажмите кнопку",
  registerAndFillAppliaction  : "Зарегистрироваться и заполнить заявку",
  fieldRequired               : "Это поле обязательно к заполнению",
  administratorPersonalAccount: "Личный кабинет администратора",
  registerOfOrders            : "Реестр заказов",
  listOfPerformers            : isProPBSystem ? "Список ГИП" : "Список экспертов",
  listOfCustomers             : "Список пользователей",
  listOfUsers                 : "Список пользователей",
  personalArea                : "Личный кабинет",
  active                      : "Активный",
  archive                     : "Архивный",

  filters                     : "Фильтры",
  contractNumber              : "Номер договора",
  setStatus                   : "Назначьте статус",
  contract                    : "Договор",
  orderNumber                 : "Номер заказа",
  contractTableNumber         : "№ Договора",
  num                         : "№",
  term                        : "Срок",
  softwareProduct             : 'Программный продукт',
  roomsCount                  : "Кол-во помещений/",
  roomsCount2                 : "наруж.установок",
  filterNames: {
    source                    : "Источник",
    delivery                  : "Доставка",
    objectType                : "Название анкеты",
  },
  empty: {
    source                    : 'Источник не задан',
    promocode                 : 'Нет промокода',
    softwareProduct           : 'Не задан',
    expert                    : isProPBSystem ? 'Не задан ГИП' : 'Не задан эксперт',
    executor                  : isProPBSystem ? 'Не задан проектировщик' : 'Не задан расчетчик',
  },
  contractDate                : "Дата договора",
  payer                    : "Заказчик",
  expert                      : isProPBSystem ? "ГИП" : "Эксперт",
  executor                    : isProPBSystem ? "Проектировщик" : "Расчетчик",
  getToWork                   : 'Взял в работу',
  status                      : "Статус",
  assessment                  : "Оценка",
  moreDetails                 : "Подробнее",
  moreDetailsAboutExecutor    : "Подробная информация о члене комиссии",
  registerOfAnApplication     : "Регистрация. Оформление заявки.",
  registerInfo                : "Введите информацию о Вас и пароль, который Вы будете использовать для входа в личный кабинет.\n Ваши персональные данные требуются для подготовки Договора на оказание услуг.",
  registerTooltip             : `Безопасность персональных данных при их обработке в информационной системе обеспечивается с помощью системы защиты персональных данных согласно требованиям Федерального закона «О персональных данных» от 27.07.2006 N 152-ФЗ.`,
  loginTitle                  : "Для входа в Личный кабинет введите ваш Email и пароль",
  addNewCustomer              : "Добавление нового заказчика",
  addNewCustomerText          : "Кто по договору является заказчиком (плательщиком)?",
  favorites                   : 'Избранное',
  ordersRegistryRadio: [
    { name: "Активный", value: "active"  },
    { name: "Архивный", value: "archive" },
  ],
  isNeedDeliveryRadio: [
    { name: "Да" , value: "true"  },
    { name: "Нет", value: "false" },
  ],
  isNeedConclusionRadio:  [
    { name: "Да (стоимость Заключения зависит от региона)", value: "TRUE" },
    { name: "Нет"                                         , value: "FALSE" },
  ],
  deliveryTypeRadio: [
    { name: "Почта России"                    , value: "postRus" },
    { name: "Курьером (оплачивается отдельно)", value: "ARCHIVE" },
  ],
  addNewCustomerRadio: [
    { name: "Физическое лицо"               , value: "1" },
    { name: "Юридическое лицо"              , value: "2" },
    { name: "Индивидуальный предприниматель", value: "3" },
  ],
  typeDelivery: [
    { name: "Доставка не требуется", value: "NO_DELIVERY" },
    { name: "Экспресс-доставка"    , value: "EXPRESS"     },
    { name: "Почта России"         , value: "RUS"         },
  ],
  saveAndContinue             : "Сохранить и продолжить",
  save                        : "Сохранить",
  region                      : "Регион",
  back                        : "Назад",
  backToRequests              : "Вернуться к списку заявок",
  edit                        : "Редактировать",
  editForms                   : "Редактор форм",
  delete                      : "Удалить",
  surname                     : "Фамилия",
  name                        : "Имя",
  middleName                  : "Отчество",
  enterCustomerInformation    : "Введите информацию о заказчике",
  enterPasportData            : "Введите паспортные данные",
  series                      : "Серия",
  number                      : "Номер",
  inCorrectPhone              : "Неправильный номер телефона",
  issuedBy                    : "Кем выдан",
  dateOfIssueInput            : "Дата выдачи / дд.мм.гггг",
  inCorrectDate               : "Неправильный формат даты",
  adress                      : "Адрес",
  email                       : "E-mail",
  phone                       : "Телефон",
  enterOrganizatioInformation : "Введите информацию об организации",
  enterIE                     : "Введите информацию об ИП",
  enterLeaderInformation      : "Введите информацию о руководителе",
  nameOrganization            : "Название организации",
  legalAdress                 : "Юридический адрес",
  deliveryAddressMatches      : "Адрес доставки документов совпадает с юридическим адресом",

  deliveryAddressMatchesPhis  : "Адрес доставки документов совпадает с указанным адресом",

  deliveryContactPerson       : "Контактное лицо",
  deliveryContact             : "Ответственный за заявку",
  deliveryContactName         : "ФИО контактного лица",
  deliveryContactPhone        : "Номер телефона",
  deliveryContactEmail        : "Электронная почта",

  documents                   : "Документы",
  INN                         : "ИНН",
  corNumber                   : "Кор.счет",
  KPP                         : "КПП",
  OGRN                        : "ОГРН",
  raschNumber                 : "Расч.счет",
  score                       : "Счет",
  bank                        : "Банк",
  bikBank                     : "БИК Банка",
  position                    : "Должность",
  positionHeadCompany         : "Должность руководителя",
  positionHeadCompanyCase     : "Должность руководителя (род. падеж)",
  mailingAddress              : "Почтовый адрес",
  nameIe                      : "Наименование ИП",
  nameCompany                 : "Наименование компании",
  fullNameCompany             : "Полное наименование компании",
  OGRNIP                      : "ОГРНИП",
  actingOnTheBasis            : "Действующий на основании",
  onTheDocumentBasis          : "Действует на основании документа",
  customerChoice              : "Выбор заказчика",
  next                        : "Далее",
  addNewCustomerButton        : "Добавить нового заказчика",
  addFile                     : "Добавить файл",
  addNewExecutor              : isProPBSystem ? "Добавить нового ГИП" : "Добавить нового эксперта",
  cancelOrderCreation         : "Отменить создание заявки",
  infoAboutCustomer           : "Информация о заказчике",
  infoAboutOrder              : "Информация о заказе",
  customerType                : "Тип заказчика",
  fullName                    : "ФИО",
  fullNameExecuter            : isProPBSystem ? "ФИО ГИП" : "ФИО Эксперта",
  fullNameCustomer            : "ФИО Заказчика",
  documentDeliveryAddress     : "Адрес доставки документов",
  passportNumber              : "Паспорт серия/номер",
  issued                      : "Выдан",
  rating                      : "Рейтинг",
  numberCompletedOrders       : "Количество выполненных заказов",
  numberWorkOrders            : "Колличество заказов в статусе “В работе”",
  averageExecutionTime        : "Среднее время на исполнение",
  complexity                  : "Сложность",
  dateOfIssue                 : "Дата выдачи",
  user                        : "Пользователь",
  dateOfCreation              : "Дата создания",
  legalAddress                : "Юридический адрес",
  dateFrom                    : "Дата с 06.07.2000",
  dateTo                      : "Дата по 09.07.2000",
  addingNewCommissionMember   : "Добавление нового члена комиссии",
  companySettings             : "Настройки компании",
  enterCompanyInformation     : "Общая информация о компании",
  enterInformationHeadCompany : "Руководитель компании",
  addCompanyStamp             : "Печать компании",
  nameHeadCompany             : "Имя Руководителя",
  surnameHeadCompany          : "Фамилия Руководителя",
  middlenameHeadCompany       : "Отчество руководителя",
  nameHeadCompanyRod          : "Имя в родительном падеже",
  surnameHeadCompanyRod       : "Фамилия в родительном падеже",
  middlenameHeadCompanyRod    : "Отчество в родительном падеже",
  addSupervisorSignature      : "Подпись руководителя",
  enterAdditionalCompanyInformation: "Дополнительная информация о компании",
  noCustomers                 : "Заказчики отсутствуют. Добавьте нового заказчика, чтобы продолжить",
  chooseCategory              : "Выберите раздел",
  chooseRegionDelivery        : "Выберите регион доставки",
  chooseForm                  : "Выберите форму",
  chooseTypeDelivery          : "Выберите способ доставки",
  typeDeliveryTitle           : "Способ доставки",
  search                      : "Поиск",
  needDelivery                : "Требуется доставка отчета по расчету?",
  conslusion                  : "Заключение Испытательной пожарной лаборатории МЧС России",
  youHavePromo                : "Если у Вас есть промокод введите его в это поле:",
  promo                       : "Промокод",
  promocodes                  : "Промокоды",
  yourWishes                  : "Ваши пожелания",
  backToChooseCategory        : "К выбору категории",
  backToChoosingCustomer      : "К выбору заказчика",
  chat                        : "Чат",
  infoAboutObject             : "Данные по объекту",
  order                       : "Заказ",
  orderCalculation            : isProPBSystem ? "Заказать новый проект" : "Заказать новый расчёт",
  results                     : "Результаты",
  test                        : "Тест",
  loadResultsCustomer         : "Загрузить результаты заказчику",
  download                    : "Скачать",
  city                        : "Город",
  category                    : "Категория",
  orderObjectInfo: {
    funcPurpose               : "Функциональное назначение объекта",
    buildingHours             : "График работы здания",
    providedSecurityService   : "В здание предусмотренна служба охраны",
    providedWorkers           : "В здание предусмотренны иные ночные/круглосуточные работники",
    walls                     : "Стены",
    partitions                : "Перегородки",
    overlapping               : "Перекрытия",
    coating                   : "Покрытие",
    alarmSystem               : "Автоматическая система пожарной сигнализации",
    warningSystem             : "Система оповещения и управления льдьми при эвакуации",
    ventilationSystem         : "Система протводымной венталяции (не путать с общеобменной вентиляцией)",
    extinguishingSystem       : "Автоматическая система пожатушения",
    fireProtectionSystem      : "Требущиеся, но отсутсвующие на объекте автоматические системы противопожарной защиты ",
    protectionSystemsDeviation: "Автоматические системы противопожарной защиты, смонтированные с отступлением от норм, по которым они были запроектированы",
    numberOfFireExtinguishers : "Количество огнетушителей, пожарных щитов и пожарных кранов",
    distanceToFireStation     : "Расстрояние до ближайшей пожарной части",
    emergencyExits            : "Имеются ли аварийные выходы",
    floorPlan                 : "Поэтажный план и спецификация",
  },
  wishes                      : "Пожелания",
  clientWishes                : "Пожелания заказчика",
  remarks                     : "Замечания",
  noteAdmin                   : "Замечания (Админ)",
  remarksCommon               : "Замечания (Эксперт/Расчетчик)",
  sum                         : "Сумма, ₽",
  deadlineOfExecution         : "Срок для исполнителя",
  deadlineOfContract          : "Срок в договоре",
  paymentStatus               : "Статус оплаты",
  payment                     : "Оплата, ₽",
  prepayment                  : "Аванс, %",
  dateOfPrepayment            : "Дата оплаты аванса",
  dateOfFullPayment           : "Дата полной оплаты",
  dateOfPrepaymentShort       : "Аванс",
  dateOfFullPaymentShort      : "Остаток",
  dateOfPayment               : "Дата оплаты",
  arrears                     : "Долг, ₽",
  bonusesTitle                : "Бонусы",
  bonuses                     : [
    { name: "Нет", value: 'false' },
    { name: "Да" , value: 'true' },
  ],
  deliveryBonuses             : "Использовать бонусы?",
  trackNumber                 : "Трек-номер",
  deliveryTime                : "Срок доставки",
  deliveryPrice               : "Стоимость доставки",
  enterTrackNumber            : "Введите трек-номер",
  changeAccount               : "Изменить счёт",
  paid                        : "Оплачен",
  notPaid                     : "Не оплачен",
  application                 : "Заявка №",
  noMessages                  : "Сообщений нет",
  editDeliveries              : "Редактор доставки",
  editDeliveriesMethods       : "Способы доставки",
  addNew                      : "Добавить",
  deliveryMethod              : "Способ доставки",
  editing                     : "Редактирование",
  replace                     : "Заменить",
  on                          : "на:",
  newName                     : "Новое название",
  cancel                      : "Отмена",
  price                       : "Цена",
  adding                      : "Добавление",
  yes                         : "Да",
  deleteDeliveryMethod        : "Вы действительно хотите удалить способ доставки ",
  deleting                    : "Удаление",
  editSources                 : "Источники",
  title                       : "Название",
  deleteSource                : "Вы действительно хотите удалить источник ",
  deleteCategory              : "Вы действительно хотите удалить категорию ",
  deleteSoftwareProduct       : "Вы действительно хотите удалить ПО ",
  deleteRating                : "Вы действительно хотите удалить рейтинг ",
  deleteRatingRole            : "Вы действительно хотите удалить роль рейтинга ",
  deleteRatingScore           : "Вы действительно хотите удалить критерий рейтинга ",
  deleteRatingScoreValue      : "Вы действительно хотите удалить значение критерия рейтинга ",
  deleteMessage               : "Вы действительно хотите удалить сообщение?",
  deleteExecutorGroup         : "Вы действительно хотите удалить группу ",
  backToArchive               : "Добавить в архив",
  backToActive                : "Вернуть в активные",
  editPromocodes              : "Промокоды",
  value                       : "Значение",
  deletePromocode             : "Вы действительно хотите удалить промокод ",
  deleteBonusStatus           : "Вы действительно хотете удалить этот статус ",
  deleteApplication           : "Вы действительно хотите удалить заказ ",
  addNewPromo                 : "Добавить новый промокод",
  addNewDelivery              : "Добавить новый способ",
  addNewSource                : "Добавить новый источник",
  deleteSelectSource          : "Удалить выбранный источник",
  deleteSelectDelivery        : "Удалить выбранный способ",
  deleteSelectPromo           : "Удалить выбранный промокод",
  editSelectDelivery          : "Редактировать выбранный способ",
  noDelivery                  : "Доставка не требуется",
};
