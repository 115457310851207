// prettier-ignore-start


const tabType = {
    ordersList        : 'ordersList',
    usersList         : 'usersList',
    userCategories    : 'categories',
    userCategoryForms : 'categoryForms',
    userBonuses       : 'bonuses',

    ordersArchiveList  : 'ordersArchiveList',
};

export default tabType;
