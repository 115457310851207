// prettier-ignore-start
import React from "react";

import PopupEdit from "../../../../../components/Lists/PopupEdit";


const CommonMessageForm = ({
    popupName = '',
    title = '',
    runText = '',
    data = {},
    setData = () => {},
    cols = {},
    run = () => {},
    onRun = null, //() => {},
}) => {

    return <PopupEdit
        popupName   = {popupName}
        key         = {popupName}
        title       = {title}
        doText      = {(runText !== '') ? runText : 'Выполнить'}
        getData     = {() => {}}
        item        = {data}
        setItem     = {setData}
        cols        = {cols}
        action      = {(data, success, fail) => run((onRun === null ? data : onRun(data) ), success, fail)}
    />

};

export default CommonMessageForm;
