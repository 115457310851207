// prettier-ignore-start
import React from "react";

import {SystemEmails} from "../../../api/methods";
import List           from "../../../components/Lists/List";
import LIF            from "../../../components/Lists/ListItemFormats";
import LIT            from "../../../components/Lists/ListItemTypes";
import MailTestSend   from "./Components/MailTestSend";


const SettingsSystemMails = () => {

    const testSend = MailTestSend({
        name  : 'systemTestSendMail',
        action: SystemEmails.testSend,
    });


    return ( <>
        <List
            popupPrefix  = {'systemMails'}
            getListData  = {SystemEmails.getAll}

            buttons      =  {[
                {type: 'new'},
            ]}

            cols         = {[
                {name: 'id'                      , title: 'id'              , format: LIF.linkEdit, type: LIT.number  , canAdd: true , canEdit: false},

                {name: 'is_active'               , title: 'активность'      , format: LIF.check   , type: LIT.check   , canAddAndEdit: true , default: 0},
                {name: 'is_system'               , title: 'системное'       , format: LIF.check   , type: LIT.check   , canAddAndEdit: false, default: 0},
                {name: 'sysname'                 , title: 'системное имя'   , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
                {name: 'to'                      , title: 'получатели'      , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
                {name: 'title'                   , title: 'заголовок'       , format: LIF.hidden  , type: LIT.text    , canAddAndEdit: true },
                {name: 'text'                    , title: 'текст'           , format: LIF.hidden  , type: LIT.textarea, canAddAndEdit: true },
                {name: 'add_app_link_text'       , title: 'ссылка на заявку', format: LIF.hidden  , type: LIT.check   , canAddAndEdit: true },
                {name: 'add_app_chat_link_text'  , title: 'ссылка на чат'   , format: LIF.hidden  , type: LIT.check   , canAddAndEdit: true },
                {name: 'add_no_reply_text'       , title: 'no-reply подпись', format: LIF.hidden  , type: LIT.check   , canAddAndEdit: true },

                {name: 'files'                   , title: 'вложения'        , format: LIF.hidden  , type: LIT.text    , canAddAndEdit: true },
                {name: 'description'             , title: 'описание'        , format: LIF.linkEdit, type: LIT.text    , canAddAndEdit: true , default: '', align: 'left'},
                {name: 'assigned_to_status_count', title: 'стат'     , format: LIF.usageCounter},
                {name: 'assigned_to_action_count', title: 'дейст'    , format: LIF.usageCounter},
            ]}

            actions = {[
                {title: 'тест', onClick: (id, item) => testSend.openForm(id, item.to) },
            ]}

            onNew        = {SystemEmails.add   }
            onEdit       = {SystemEmails.update}
            onDelete     = {SystemEmails.delete}

            canDelete    = {(item) => (item['assigned_count'] === 0) && (item['is_system'] === 0)}

            textOnDelete = {(item) => <>Удалить уведомление <b>{item?.sysname ?? ''}</b>?</>}
        />

        {testSend.form}
    </> );


};

export default SettingsSystemMails;
