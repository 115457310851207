// prettier-ignore-start
import React from "react";

import { useHistory }             from 'react-router-dom';
import { ExecutorGroup, getPath } from "../../api/methods";
import { T }                      from "../../lang/ru";
import { useSaving }              from "../../hooks/useSaving";
import { isEmpty }                from "../../functions/common";
import { reloadPage }             from "../../functions/reloadPage";
import { PATHS }                  from "../../constants/RoutesPaths";
import List                       from "../../components/Lists/List";
import LIF                        from "../../components/Lists/ListItemFormats";
import LIT                        from "../../components/Lists/ListItemTypes";


const SettingsExecutorGroupsList = () => {

    const history = useHistory();

    const distribution        = useSaving('распределение..', 'распределено', 'ошибка');
    const distributionText    = isEmpty(distribution.text) ? 'распределить исполнителей' : distribution.spinner();
    const distributionOnClick = () => {
        distribution.activate();

        ExecutorGroup.distribute_users({}, ({data}) => {
            distribution.deactivate();
            reloadPage();
        }, () => {
            distribution.deactivate(false);
        })
    }

    const gotoExecutorGroupUsers = (groupId) => history.push(getPath(PATHS.settings.executorGroupUsers, {group_id: groupId}));

    const formatDistribution = (id, item, itemProps) => (item.is_autoassigned) ? <>{item.distribution_hour_after} &hellip; {item.distribution_hour_before} &rArr; {item.distribution_next_after_hours}</> : '';


    return <List
        popupPrefix  = {'executorGroups'}
        getListData  = {ExecutorGroup.getAll}

        buttons      =  {[
            {type: 'new' },
            {title: distributionText, onClick: distributionOnClick },
        ]}
        cols         = {[
            {name: 'is_active'                    , title: 'активно'                        , format: LIF.check       , type: LIT.check , canAddAndEdit: true , isValid: (val) => true      , align: 'center'},
            {name: 'order_id'                     , title: 'порядок'                        , format: LIF.text        , type: LIT.number, canAddAndEdit: true , isValid: (val) => val !== ''},
            {name: 'name'                         , title: 'название'                       , format: LIF.linkEdit    , type: LIT.text  , canAddAndEdit: true , isValid: (val) => val !== '', align: 'left'  },
            {name: 'rating_min'                   , title: 'мин'                            , format: LIF.text        , type: LIT.number, canAddAndEdit: true , isValid: (val) => val !== ''},
            {name: 'rating_max'                   , title: 'макс'                           , format: LIF.text        , type: LIT.number, canAddAndEdit: true , isValid: (val) => val !== ''},
            {name: 'is_autoassigned'              , title: 'автоназначение'                 , format: LIF.check       , type: LIT.check , canAddAndEdit: true , isValid: ()    => true      },
            {name: 'distribution_hour_after'      , title: 'назначать после, часы'          , format: LIF.hidden      , type: LIT.number, canAddAndEdit: true , isValid: (val) => (val >= 0) && (val <= 24)},
            {name: 'distribution_hour_before'     , title: 'назначать до, часы'             , format: LIF.hidden      , type: LIT.number, canAddAndEdit: true , isValid: (val) => (val >= 0) && (val <= 24)},
            {name: 'distribution_next_after_hours', title: 'на следующую группу через, часы', format: LIF.hidden      , type: LIT.number, canAddAndEdit: true , isValid: (val) => (val >= 0)               },
            {name: 'distribution'                 , title: 'назначать заявку'               , format: LIF.text        , type: LIT.text  , canAddAndEdit: false, customFormatHandler: formatDistribution },

            {name: 'users_count'                  , title: 'исполнителей в группе', format: LIF.usageCounter},
        ]}
        actions = {[
            {title: 'исполнители'   , onClick: gotoExecutorGroupUsers, canDo: () => true },
        ]}

        onNew        = {ExecutorGroup.add   }
        onEdit       = {ExecutorGroup.update}
        onDelete     = {ExecutorGroup.delete}

        canDelete    = {(item) => (item['users_count'] === 0)}

        textOnDelete = {(item) => <>{T.deleteExecutorGroup}<b>{item?.name ?? ''}</b>?</>}
    />

};

export default SettingsExecutorGroupsList;
