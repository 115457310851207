// prettier-ignore-start
import React from "react";

import {
    SystemAccessRights,
    SystemAccessRightsAssigned,
}                        from "../../../api/methods";

import { PATHS }         from "../../../constants/RoutesPaths";
import List              from "../../../components/Lists/List";
import LIF               from "../../../components/Lists/ListItemFormats";
import LIT               from "../../../components/Lists/ListItemTypes";
import ListFormat_2Lines from "../../../components/Lists/Parts/ListFormat_2Lines";


const SettingsSystemAccessRightValuesAssigned = ({
    title = '',
    type = '',
    backTo = PATHS.settings.systemRoles,
    getListData = (d, s) => s(d),
    onNew = (d, s) => s(d),
}) => {

    const formatCommonAccessRight = (data) => ListFormat_2Lines(data?.name, data?.sysname);

    const formatAccessRight      = (id, item, itemProps) => formatCommonAccessRight(item?.access_right);
    const formatAccessRightValue = (id, item, itemProps) =>
        ((item.use_default_value === 1) || (item.access_right_value_id === null))
            ? formatCommonAccessRight(item?.access_right?.default_value)
            : formatCommonAccessRight(item?.access_right_value);

    const optionsAccessRights      = (success         ) => SystemAccessRights.select         ({type                                 }, ({data}) => success(data));
    const optionsAccessRightValues = (success, f, item) => SystemAccessRights.getValuesSelect({id: item.access_right_id, hasUnset: 1}, ({data}) => success(data));


    return <List
        title        = {title}
        popupPrefix  = {'systemAccessRightsAssigned'}
        getListData  = {getListData}

        cols         = {[
            {name: 'id'                   , title: 'id'                                , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: false         , canEdit: false },
            {name: 'is_active'            , title: 'активность'                        , format: LIF.check   , type: LIT.check , canAddAndEdit: true          , default: 1    , align: 'center'},
            {name: 'access_right'         , title: 'доступ'                            , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false                         , align: 'left'  , customFormatHandler: formatAccessRight },
            {name: 'access_right_id'      , title: 'тип доступа'                       , format: LIF.hidden  , type: LIT.select, canAdd: true , canEdit: false, default: null , getOptions: optionsAccessRights },
            {name: 'use_default_value'    , title: 'по-умолчанию'                      , format: LIF.check   , type: LIT.check , canAddAndEdit: true          , default: 0   },
            {name: 'access_right_value'   , title: 'значение'                          , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false                         , align: 'left'  , customFormatHandler: formatAccessRightValue },
            {name: 'access_right_value_id', title: 'значение доступа'                  , format: LIF.hidden  , type: LIT.select, canAdd: false, canEdit: true , default: null , getOptions: optionsAccessRightValues },
            {name: 'props'                , title: 'параметры'                         , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true          , default: ''  },
        ]}
        buttons      =  {[
            {type: 'group', buttons: [
                    {type: 'back', to: backTo},
                ]},
            {type: 'new'},
        ]}

        onNew        = {onNew}
        onEdit       = {SystemAccessRightsAssigned.update}
        onDelete     = {SystemAccessRightsAssigned.delete}

        textOnDelete = {(item) => <>Удалить доступ <b>{item?.access_right?.name ?? ''}</b>?</>}
    />

};

export default SettingsSystemAccessRightValuesAssigned;
