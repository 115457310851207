// prettier-ignore-start
import ActionCommentChatPrivate from "./Base/ActionCommentChatPrivate";
import ActionMessageForm        from "./Base/Forms/ActionMessageForm";
import ActionAssignExecutor     from "./Base/ActionAssignExecutor";
import ActionFormType           from "./ActionFormType";


const ActionMessageFormAssignExecutor = () => {

    const data = (app) => ({
        ...ActionAssignExecutor    ().data(app),
        ...ActionCommentChatPrivate().data(app)
    });

    const formCols = (props) => ([
        ...ActionAssignExecutor    ().formCols(props),
        ...ActionCommentChatPrivate().formCols(props)
    ]);

    const form = (props) => ActionMessageForm().form(props, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAssignExecutor;
