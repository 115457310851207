// prettier-ignore-start
import React, {useEffect} from "react";

import { useDispatch, useSelector } from "react-redux";

import { useSaving }       from "../../../../../hooks/useSaving";
import { getIsShowPopups } from "../../../../../store/popups/selectors";
import { closePopup }      from "../../../../../store/popups/actions";

import SecondOrderButton   from "../../../../../components/UI-kit/Buttons/SecondOrderButton";
import Button              from "../../../../../components/UI-kit/Buttons/Button";
import Alert               from "../../../../../components/Alert/Alert";
import ButtonsBlock        from "../../../../../components/Alert/ButtonsBlock";


const CommonMessageConfirmForm = ({
    popupName = '',
    title = '',
    run    = () => {},
    close  = () => {},
}) => {
    const dispatch       = useDispatch();

    const isShowSelector = useSelector(getIsShowPopups)[popupName];

    useEffect(() => {
        dispatch( closePopup( popupName ));
    }, []);

    const working = useSaving();

    const onClickRunAction = () => {
        working.activate();

        run({}, () => {
            working.deactivate(true);
            onClickCancel();
        }, () => {
            working.deactivate(false);
        });
    }

    const onClickCancel = () => {
        close();
    }

    const formButtons = (
        <ButtonsBlock>
            <Button
                onClick  = {onClickRunAction}
                disabled = {working.isActive}
                margin   = {"0px 0px"}
            >
                {(title ?? 'Выполнить')}
            </Button>
            {working.spinner()}
            <SecondOrderButton
                className = "delete-button"
                onClick   = {onClickCancel}
                style     = {{marginLeft: '10px'}}
            >
                Отмена
            </SecondOrderButton>
        </ButtonsBlock>
    );

    return <Alert
        title   = {''}
        isShow  = {isShowSelector}
        buttons = {formButtons}
    />

};

export default CommonMessageConfirmForm;
