// prettier-ignore
const listItemFormats = {
    text        : 'text',
    date        : 'text',
    linkEdit    : 'linkEdit',
    linkGoTo    : 'linkGoTo',
    usageCounter: 'usageCounter',
    check       : 'check',

    custom      : 'custom',

    hidden      : 'hidden',
};

export default listItemFormats;