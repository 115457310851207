// prettier-ignore-start
import React from "react";

import {PATHS}                    from "../../../../constants/RoutesPaths";
import { T }                      from "../../../../lang/ru";
import {useUserAccessRightChecks} from "../../../../hooks/useUserAccessRightChecks";

import Tabbed                     from "../../../../components/Tabbed";
import OrdersListFiltered         from "../OrdersListFiltered";
import tabType                    from "./OrdersListAdminPanelTabType";
import Users                      from "./Users";


const OrdersListAdminPanel =  ({ tabs = [] }) => {

    const userAccess = useUserAccessRightChecks();

    const tab     = tabs[0] ?? tabType.ordersList;
    const subTabs = tabs.slice(1);

    const tabsList = {
        [tabType.ordersList       ] : { title: T.registerOfOrders    , route: PATHS.orders          , component: OrdersListFiltered },
        //[tabType.ordersArchiveList] : { title: 'Архив заказов'       , route: PATHS.admin.ordersArchiveList   , component: ExecutorsList /*ordersArchiveList*/ },

        ...((!userAccess.isAllowAppListUsers) ? {} : {
            [tabType.usersList + '_']: {title: ''}, // splitter
            [tabType.usersList      ]: {title: T.listOfUsers, route: PATHS.users, component: Users, componentProps: {sub: subTabs[0] ?? tabType.usersList}},
        }),
    };


    return (
        <Tabbed
            title = ''
            tab   = {tab}
            tabs  = {tabsList}
        />
    );

};

export default OrdersListAdminPanel;