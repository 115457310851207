// prettier-ignore-start
import LIT             from "../../../../components/Lists/ListItemTypes";
import { Application } from "../../../../api/methods";
import { isNotEmpty }  from "../../../../functions/common";


const ActionAppDataPrice = () => {

    const f_app_id       = 'id';           // id заявки
    const f_price        = 'price';        // сумма заказа
    const f_prepayment   = 'prepayment';   // % предоплаты
    const f_promocode_id = 'promocode_id'; // промо-код


    const data = (app) => ({
        [f_app_id]       : app[f_app_id      ] ?? null,
        [f_price]        : app[f_price       ] ?? 0,
        [f_prepayment]   : app[f_prepayment  ] ?? 0,
        [f_promocode_id] : app[f_promocode_id] ?? null,
    });

    const setUserAppPromocodesOptions = (success, fail, data) => Application.listUserAppPromocodesSelect({app_id: data[f_app_id] ?? null, hasUnset: 1}, ({data}) => success(data), fail);

    const formCols = (props) => ([
        { name: f_price       , title: 'сумма заказа, p.', type: LIT.number_positive, isValid: (val) => isNotEmpty(val) && (val >= 0)                 },
        { name: f_prepayment  , title: 'аванс'           , type: LIT.percent        , isValid: (val) => isNotEmpty(val) && (val >= 0) && (val <= 100) },
        { name: f_promocode_id, title: 'промокод'        , type: LIT.select         , getOptions: setUserAppPromocodesOptions },
    ]);

    return {
        data     : data,
        formCols : formCols
    }

};

export default ActionAppDataPrice;
