import React from "react";

import Subtitle2 from "../../UI-kit/Subtitle2";
import InputsGrid from "../../UI-kit/InputsGrid";

import FormFieldsList from "./FormFieldsList";

// prettier-ignore-start

/*
// ВВОДНЫЕ (ПРИМЕР)

{ type: fieldType.group, text: T.enterCompanyInformation, props: {useGrid: true,  contains: [  fieldsList.nameCompany, ..]} },
*/

const FormGroup = ({
    name = '',
    dataName = '',
    text = '',
    data = [],
    updateData = (newData) => {},
    onChange = () => {},
    onBlur = () => {},
    props = {},
}) => {

    const subFields =
        <FormFieldsList
            fieldsStruct = {props.contains ?? []}
            data         = {data}
            updateData   = {updateData}
            onChange     = {onChange}
            onBlur       = {onBlur}
        />;

    return (
        <>
            { (text !== '') && <Subtitle2>{text}</Subtitle2> }

            { (props.useGrid ?? false)
                ? <InputsGrid
                    key      = {name+'_grid'}
                    maxWidth = "710px"
                    columns  = "1fr 1fr"
                    gap      = "0"
                >
                    {subFields}
                </InputsGrid>
                : subFields
            }
        </>
    );

}

export default FormGroup;