import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput from '../components/UI-kit/FloatingInput';
import FloatingPassword from '../components/UI-kit/FloatingPassword';
import Text from '../components/UI-kit/Text';
import Title from '../components/UI-kit/Title';
import { Auth } from '../api/methods';
import { INPUT_CONFIGS } from '../constants/InputConfigs';
import { PATHS } from '../constants/RoutesPaths';
import { formDataFromInputInfoForState } from '../functions/common/common';
import { isValidEmailCheck } from '../functions/validators';
import { T } from '../lang/ru';
import { yellow } from '../constants/Colours';
import { useDispatch } from 'react-redux';
import { setIsHiddenMobileButton } from '../store/isHiddenMobileButton/action';
import { authSuccess } from '../functions/auth';


const Login: React.FC = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [data        , changeDataField] = useState( formDataFromInputInfoForState(INPUT_CONFIGS.login) );

    const [isValidEmail, setIsValidEmail] = useState<boolean>(true );
    const [isAuthError , setIsAuthError ] = useState<boolean>(false);

    useEffect(() => {
        changeDataField({ ...data, email: '', password: '' });
        dispatch( setIsHiddenMobileButton(false) );
        localStorage.clear();
    }, []);

    const onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void = (
        e
    ) => {
        const { value, name } = e.target;
        changeDataField({ ...data, [name]: value });
    };

    const authorize = () => Auth.login(data, authSuccess, authFail);

    const authFail          : () => void = () => setIsAuthError(true);
    const goToRegister      : () => void = () => history.push(PATHS.register      );
    const goToForgotPassword: () => void = () => history.push(PATHS.forgotPassword);

    const checkEmailValid: (e: React.ChangeEvent<HTMLInputElement>) => void = (
        e
    ) => {
        setIsValidEmail(isValidEmailCheck(e.target.value));
    };

    const handleEnter = (e: any) => (e.charCode === 13 ? authorize() : null);


    return <>
        <Title>{T.loginTitle}</Title>

        <FloatingInput
            wrongMessage = {T.inCorrectEmail}
            wrong        = {! isValidEmail}
            name         = "email"
            value        = {data.email}
            onBlur       = {checkEmailValid}
            onChange     = {onChangeHandler}
            placeholder  = {'Email'}
            required     = {true}
            onKeyPress   = {handleEnter}
        />

        <FloatingPassword
            name         = "password"
            value        = {data.password}
            onChange     = {onChangeHandler}
            placeholder  = {'Пароль'}
            required     = {true}
            onKeyPress   = {handleEnter}
        />

        {isAuthError
            ? <Text color={yellow} withoutMargin={true}>
                {T.loginError}
            </Text>
            : null
        }

        <Button style={{ marginRight: 20 }} onClick={authorize}>
            {T.login}
        </Button>
        <SecondOrderButton onClick={goToForgotPassword}>
            {'Забыли пароль?'}
        </SecondOrderButton>

        <Text>
            {T.firstTimeUsageMessage + ' '}
            <SecondOrderButton onClick={goToRegister}>
                {T.registerAndFillAppliaction}
            </SecondOrderButton>
        </Text>
    </>;
};

export default React.memo(Login);
