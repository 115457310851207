// prettier-ignore-start
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert               from "../Alert/Alert";
import ButtonsBlock        from "../Alert/ButtonsBlock";
import Button              from "../UI-kit/Buttons/Button";
import SecondOrderButton   from "../UI-kit/Buttons/SecondOrderButton";
import Text                from "../UI-kit/Text";

import { T }               from "../../lang/ru";
import { closePopup }      from "../../store/popups/actions";
import { getIsShowPopups } from "../../store/popups/selectors";
import { useSaving }       from "../../hooks/useSaving";


// prettier-ignore
const PopupDelete = ({ popupName, item, setItem, getData, action = (data, success, fail) => {}, getTextBody = (item) => ''  }) => {
    const dispatch = useDispatch();
    const saving   = useSaving("удаление...", "удалено", "сбой, не удалено");

    const isShow      = useSelector(getIsShowPopups)[popupName];
    const isShowPopup = isShow && (item != null);

    const onClickYes = () => {
        deleteItem();
    };

    const deleteItem = () => {
        saving.activate();
        action({ id: item.id }, deleteItemSuccess, deleteItemFail);
    };

    const deleteItemSuccess = () => {
        saving.deactivate();
        getData();
        onClickCancel();
    };

    const deleteItemFail = () => {
        saving.deactivate(false);
    };

    const onClickCancel = () => {
        dispatch(closePopup(popupName));
        setItem(null);
    };

    const buttons = (
        <ButtonsBlock>
            <Button
                onClick = {onClickYes}
                margin  = {"0px 0px"}
            >
                {T.yes}
            </Button>
            {saving.spinner()}
            <SecondOrderButton
                className = "delete-button"
                onClick   = {onClickCancel}
            >
                {T.cancel}
            </SecondOrderButton>
        </ButtonsBlock>
    );

    return (
        <Alert title={T.deleting} isShow={isShowPopup} buttons={buttons}>
            <Text>
                <br/>
                { (item !== null)
                    ? getTextBody(item)
                    : ''
                }
                <br/>
                <br/>
            </Text>
        </Alert>
    );
};

export default PopupDelete;
