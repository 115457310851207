import React, { useState } from "react";
import {WaitSpinner} from "../components/UI-kit/Preloader/WaitSpinner";

// prettier-ignore-start
const defState = {
    active  : false,
    success : null,
    text    : "",
}

export function useSaving(textInProgress = "сохранение...", textSuccess = "сохранено", textFail = "не сохранено") {
    const [state, setState] = useState( defState );

    const clear = () => setState(defState);

    const activate = () =>
        setState( {
            active    : true,
            isSuccess : null,
            text      : textInProgress,
        } );

    const deactivate = (isSuccess = true, text = '') => {
        setState({
            active  : false,
            success : isSuccess,
            text    : (text !== '')
                        ? text
                        : (isSuccess
                            ? textSuccess
                            : textFail
                        ),
        } );

        const timeout = isSuccess ? 400 : 1500

        setTimeout(clear, timeout);
    }

    const spinner = () => {
        if (state === defState) return null;

        const spinnerWait      = state.active && <WaitSpinner />;
        const spinnerText      = state.text;
        const spinnerTextColor = (state.success == null) ? "black" : ( state.success ? "darkgreen" : "red" );

        return <small style={{padding:5, margin:0, border:0, display: "inline-flex", color: spinnerTextColor }}>&nbsp;{spinnerWait}&nbsp;&nbsp;&nbsp;{spinnerText}</small>;
    };

    return {
        isActive   : state.active,
        text       : state.text,

        activate   : activate,
        deactivate : deactivate,

        spinner    : spinner,
    };
}

// prettier-ignore-end
