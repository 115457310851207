import React from "react";

import { connect, ConnectedProps } from "react-redux";
import { LoaderState } from "../../store/loader/types";

import { accent, green, lightgray, red, white } from "../../constants/Colours";
import loader from "../../assets/svg/loader.svg";
import check from "../../assets/svg/check.svg";
import close from "../../assets/svg/close.svg";
import ImgFluid from "./ImfFluid";

interface State {
  loaderReducer: LoaderState;
}

const mapState = (state: State) => ({
  loaderState: state.loaderReducer,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const Loader = (props: Props) => {
  const { loaderState } = props;
  return (
    <div
      style={{
        height: 20,
        width: 20,
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        bottom: 25,
        borderRadius: 1000,
        borderWidth: 1,
        borderStyle: "solid",
        backgroundColor: white,
        transition: "right 500ms ease-in-out",
        right: loaderState === "DEFAULT" ? -50 : 25,
        zIndex: 1000,

        borderColor:
          loaderState === "LOADING"
            ? accent
            : loaderState === "ERROR"
            ? red
            : loaderState === "SUCCESS"
            ? green
            : accent,
        color: "red",
      }}
    >
      {loaderState === "LOADING" ? (
        <ImgFluid src={loader} animation={"SPIN"} height={20} />
      ) : loaderState === "ERROR" ? (
        <ImgFluid src={close} height={15} />
      ) : loaderState === "SUCCESS" ? (
        <ImgFluid src={check} height={20} />
      ) : (
        <ImgFluid src={loader} animation={"SPIN"} height={20} />
      )}
    </div>
  );
};

export default connector(Loader);
