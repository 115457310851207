// prettier-ignore-start
import React, { useRef, useEffect } from 'react';

import styled             from 'styled-components';
import { accent, yellow } from '../../constants/Colours';


const Textarea = styled.textarea`
  box-sizing   : border-box;
  flex         : 1;
  margin       : 0;
  width        : 100%;
  margin-right : 10px;
  border-width : 2px;
  border-color : ${(props) => (props.private ? yellow : accent)};
  border-radius: 10px;
  resize       : none;
  outline      : none;
  padding      : 10px 35px 10px 20px;
  font-size    : 16px;
  line-height  : 190%;
  overflow-y   : hidden;
`;

export const TextareaAutoResize = (props) => {

  const ref = useRef();

  useEffect(() => {
    changeHeight();
  }, [ref]);

  const changeHeight = () => {
    if (ref) {
      const { current } = ref;
      current.style.height = 'auto';
      current.style.height =
        current.scrollHeight - 52 > 54 ? current.scrollHeight - 52 : 54 + 'px';
    }
  };
  function onChange(e) {
    changeHeight();

    if (props.onChange) props.onChange(e);
  }

  return (
    <Textarea
      {...props}
      ref      = {ref}
      onBlur   = {props.onBlur}
      onChange = {onChange}
      private  = {props.privateMsg}
    />
  );

};
