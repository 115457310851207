// prettier-ignore-start
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled                       from "styled-components";

import { T }                       from "../../lang/ru";
import { isValidEmailCheck }       from "../../functions/validators";
import { closePopup}               from "../../store/popups/actions";
import { getIsShowPopups }         from "../../store/popups/selectors";
import { useSaving }               from "../../hooks/useSaving";

import { Preloader }               from "../UI-kit/Preloader/Preloader";
import Button                      from "../UI-kit/Buttons/Button";
import SecondOrderButton           from "../UI-kit/Buttons/SecondOrderButton";
import { CheckBoxLabel }           from "../UI-kit/CheckBoxLabel";
import FloatingSelect              from "../UI-kit/FloatingSelect";
import FloatingInput, {Input, TextArea} from "../UI-kit/FloatingInput";
import Alert                       from "../Alert/Alert";
import ButtonsBlock                from "../Alert/ButtonsBlock";
import ListItemTypes               from "./ListItemTypes";


const Wrapper = styled.div`
  display   : block;
  max-height: 520px;
  margin    : 0 0 25px 0;
  padding   : 0 25px 0 0;
  overflow-y: scroll;
  overflow-y: overlay;
`;


const PopupEdit = ({
    title = null,
    doText = null,
    isNewPopup = false,
    popupName,
    item,
    setItem,
    getData = () => {},
    cols,
    action = (data, success, fail) => {}
}) => {

    const getDefaultState = (itemType, defaultValue) => {
        let result = {};

        cols.map( ( { name, type }) => {
            if (type === itemType)
                result = {...result, [name]: defaultValue};
        });
        return result;
    }

    const getDefaultValidEmailState    = () => getDefaultState(ListItemTypes.email , true)
    const getDefaultSelectOptionsState = () => getDefaultState(ListItemTypes.select, null)

    const [isDisabled   , setIsDisabled   ] = useState(true);
    const [isValidEmail , setIsValidEmail ] = useState(getDefaultValidEmailState   ());
    const [selectOptions, setSelectOptions] = useState(getDefaultSelectOptionsState());

    const dispatch       = useDispatch();
    const saving         = useSaving();
    const isShowSelector = useSelector(getIsShowPopups)[popupName];

    const isShow         = (isShowSelector !== undefined) && isShowSelector;
    const isShowPopup    = isShow && (item !== null);
    const isUpdateCols   = isShowPopup && (item?.updateCols ?? false);


    const getValidEmailState = () => {
        for (var isValid in isValidEmail)
            if (! isValidEmail[isValid])
                return false

        return true;
    }

    const isValid = () => {
        let res = (item !== null) && getValidEmailState();

        if (res) cols.forEach((col) => {
            const isValidItem = col.isValid ?? null;

            if (isValidItem) res = res && isValidItem(item[col.name ?? '']);
        });

        return res;
    };

    const checkEmailValid = (e) => {
        const { name, value } = e.target;
        setIsValidEmail({...isValidEmail, [name]: isValidEmailCheck(value) });
    };

    useEffect(() => {
        setIsDisabled(! isValid());
        checkChecks();
    }, [item]);

    useEffect(() => {
        if (isShow || isUpdateCols) loadSelectOptions();
    }, [isShow, isUpdateCols]);


    const checkChecks = () => {
        if (isSetItem) cols.map( ({ name, type }) => {
            if (type === ListItemTypes.check)
                initCheckValue(name);
        });
    }

    const loadSelectOptions = () => {
        setSelectOptions( getDefaultSelectOptionsState() );

        cols.map( ({ name, type, getOptions }) => {
            if (type === ListItemTypes.select) {
                getOptions(
                    (data) => { // success
                        if (isShow) {
                            setSelectOptions((prev) => ({...prev, [name]: data}));
                            initSelectValue(name, data);
                        }
                    },
                    () => { // fail

                    },
                    item // data
                );
            }
        });

        updateItem('updateCols', false);
    };

    const updateItem = (name, value) => {
        if (isShow) setItem((prev) => ({ ...prev, [name]: value }));
    }

    const onChangeInput = (e) => {
        let { name, value, type, files } = e.target;

        if (type === 'color') value = value.substring(1);
        if (type === 'file' ) {
            const [file] = files;

            value = file;
        }

        updateItem(name, value);
    };

    const onChangeCheck = (e) => {
        const { name, checked } = e.target;
        updateItem(name, checked ? 1 : 0);
    };

    const onChangeEmail = (e) => {
        checkEmailValid(e)
        onChangeInput(e);
    };

    const onClickAdd = () => {
        saving.activate();

        action(item, addItemSuccess, addItemFail);
    };

    const addItemSuccess = () => {
        getData();
        saving.deactivate();
        onClickCancel();
    };

    const addItemFail = (error) => {
        const messages = (error.response && (error.response.status === 422)) ? error.response.data?.errors ?? [] : ['Ошибка'];

        saving.deactivate(false, messages.join(<br />));
    }

    const onClickCancel = () => {
        setItem( null );
        dispatch(closePopup(popupName));
    };

    const textSave  = doText ?? ((isNewPopup) ? T.addNew : 'Сохранить');
    const textTitle = title  ?? ((isNewPopup) ? T.adding : 'Изменение');

    const buttons = (
        <ButtonsBlock style={{marginTop: '20px'}}>
            <Button
                onClick  = {onClickAdd}
                disabled = {isDisabled || saving.isActive}
                margin   = {"0px 0px"}
            >
                {textSave}
            </Button>
            {saving.spinner()}
            <SecondOrderButton
                className = "delete-button"
                onClick   = {onClickCancel}
            >
                {T.cancel}
            </SecondOrderButton>
        </ButtonsBlock>
    );

    const isSetItem = !!item;

    const getValue = (name) => isSetItem ? item[name] : '';

    const initSelectValue = (name, selectOptions) => {
        const value        = getValue(name);
        const hasOptions   = selectOptions.length > 0;
        const defaultValue = hasOptions ? selectOptions[0].id : null;

        const val = (value !== '') ? value : defaultValue;

        if (val !== value) updateItem(name, val);
    }

    const initCheckValue = (name) => {
        const value = getValue(name);
        const val   = (value === '') ? false : value;

        if (val !== value) updateItem(name, val);
    }

    const formElement = (element) => {
        const type        = element['type'   ] ?? null;
        const name        = element['name'   ] ?? '';
        const title       = element['title'  ];
        const isValidItem = element['isValid'] ?? null;

        let value = getValue(name);

        const isValid     = (isValidItem) ? isValidItem(value) : true;

        switch (type) {
            case ListItemTypes.check :
                return (
                    <CheckBoxLabel style={{width: '100%'}}>
                        <input
                            key        = {name}
                            name       = {name}
                            type       = 'checkbox'
                            checked    = {value}
                            onChange   = {onChangeCheck}
                            style      = {{width:'18px', height: '18px'}}
                        />&nbsp;{title}
                        {/*<Checkbox
                            checked  = {value}
                            onChange = {() => onChange(id, value) }
                        />*/}
                    </CheckBoxLabel>
                );

            case ListItemTypes.email :
                return (
                    <FloatingInput
                        key            = {name}
                        name           = {name}
                        placeholder    = {title}
                        onChange       = {onChangeEmail}
                        value          = {value}
                        wrong          = {! isValidEmail[name]}
                        wrongMessage   = {T.inCorrectEmail}
                        style          = {{width: '500px'}}
                    />
                );

            case ListItemTypes.select:
                const options    = selectOptions[name];
                const isLoaded   = options != null;

                if (! isLoaded) return (
                    <FloatingSelect
                        key            = {name}
                        name           = {name}
                        value          = {-1}
                        title          = {title}
                        placeholder    = {'загрузка...'}
                        onChange       = {() => {}}
                        disabled       = {true}
                        options        = {[]}
                        style          = {{width: '500px'}}
                    />
                );

                const hasOptions = (options.length > 0);

                return (
                    <FloatingSelect
                        key            = {name}
                        value          = {value}
                        title          = {title}
                        placeholder    = {(! hasOptions ? ' (список пуст)' : '')}
                        name           = {name}
                        onChange       = {onChangeInput}
                        disabled       = {! hasOptions}
                        options        = {options}
                        containerStyle = {{width: '500px'}}
                        style          = {{width: '500px'}}
                    />
                );

            case ListItemTypes.textarea:
                return (
                    <TextArea
                        key         = {name}
                        name        = {name}
                        placeholder = {title}
                        onChange    = {onChangeInput}
                        value       = {value}
                        style       = {{width: '500px'}}
                    />
                );

            case ListItemTypes.color   :
                return (
                    <div style={{marginTop: 5, marginBottom: 5}}>
                        <input
                            type     = "color"
                            id       = {name}
                            name     = {name}
                            key      = {name}
                            value    = {'#'+value}
                            style    = {{minHeight: 30}}
                            onChange = {onChangeInput}
                        />
                        <label htmlFor={name} style={{marginLeft: 20}}>
                            {title}
                        </label>
                    </div>
            );

            case ListItemTypes.file    :
                const inputFileElement = document.getElementById(name);
                const text = (inputFileElement?.files[0]) ? inputFileElement.files[0].name : 'Выберите файл...'

                return <>
                    <Button
                        disabled = {false}
                        onClick  = {(e) => {
                            inputFileElement.click();
                            // onClickUpload
                        }}
                        margin   = "0px"
                    >
                        {text}
                    </Button>

                    <input
                        type     = "file"
                        id       = {name}
                        name     = {name}
                        key      = {name}
                        style    = {{display: 'none'}}
                        onChange = {onChangeInput}
                    />

                </>

            case ListItemTypes.number          :
            case ListItemTypes.number_positive :
            case ListItemTypes.date            :
            case ListItemTypes.text            :
                return (
                    <FloatingInput
                        key         = {name}
                        name        = {name}
                        type        = {(type === ListItemTypes.number_positive) ? 'number' : type }
                        placeholder = {title}
                        onChange    = {onChangeInput}
                        value       = {value}
                        style       = {{width: '500px'}}
                        wrong       = {! isValid}

                        {...( (type === ListItemTypes.number_positive) ? {min: 0} : {} )}
                    />
                );

            case ListItemTypes.percent :
                return <>
                    {title}: {value}% <Input
                    key         = {name}
                    name        = {name}
                    value       = {value}
                    type        = 'range'
                    onChange    = {onChangeInput}
                    min         = {0}
                    max         = {100}
                    wrong       = {! isValid}
                    style       = {{padding: '0px', width: '500px'}}
                    />
                </>;

            case ListItemTypes.loader:
                return (
                    <Preloader />
                );

            default                    :
                return <p>{title}</p>;
        }
    }

    return (
        <Alert title={textTitle} isShow={isShowPopup} buttons={buttons}>
            <Wrapper>
                {(isShowPopup) && cols.map( (col) => formElement(col) )}
            </Wrapper>
        </Alert>
    );

};

export default PopupEdit;
