// prettier-ignore-start
import React from "react";


const ListFormat_2Lines = (
    text = '',
    textSub = '',
    isBold = false,
) => <>
    {isBold ? <b style={{paddingLeft: 0}}>{text}</b> : text}
    <br />
    <small style={{paddingLeft: 0}}><i style={{paddingLeft: 0}}>
        {textSub}
    </i></small>
</>;

export default ListFormat_2Lines;
