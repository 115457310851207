// prettier-ignore-start
import React from "react";
import styled from "styled-components";
import {accent, lightaccent, lightgray, megaUltraLightgray, white} from "../../../../constants/Colours";

type CardProps = {
    disabled?: boolean;
    selected?: boolean;
    onClick?: (e:any) => void;
};

export const Card = styled.div`
    font-size       : 16px;
    padding         : 15px 15px 15px 20px;
    margin          : 10px 0;
    display         : block;
    background-color: ${ (props: CardProps) => (
        props?.selected
            ? lightaccent
            : (props?.disabled
                ? megaUltraLightgray
                : 'white'
            )
    )};
    border          : 1px solid ${lightgray};
    border-radius   : 4px;
    text-align      : center;
    cursor          : pointer;
    transition      : all 0.3s;
    &:hover {
        ${ (props: CardProps) => (! props?.disabled) && `  
            background-color: ${accent};
            color           : ${white};
        `}
    }
`;

type ChoiceCardProps = {
    title: string;
    onClick: any;
    disabled?: boolean;
    selected?: boolean;
};

const ChoiceCard: React.FC<ChoiceCardProps> = ({ title = "", onClick = () => {}, disabled = false, selected = false }) => (
    ! disabled
        ? <Card disabled={false} selected={selected} onClick={onClick}>{title}</Card>
        : <Card disabled={true } selected={selected}                  >{title}</Card>
);

export default ChoiceCard;
