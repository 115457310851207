// prettier-ignore-start
import {T} from "../../../../../lang/ru";


export const SoftwareProduct = () => {

/*
  const handleSelectSoftwareProductChange = (e, aplicationId) => {
    const { value } = e.target;
    startUpdateApplication();
    Application.setSoftwareProduct(
      { app_id: aplicationId, software_product_id: value },
      finishUpdateApplication
    );
  };

  <th>{T.softwareProduct}</th>
      <td>
        <OrderTableSelect
          name          = "software_product_id"
          selectedValue = {software_product_id}
          options       = {softwareProducts}
          onChange      = {(e) => handleSelectSoftwareProductChange(e, id)}
          placeholder   = {T.empty.softwareProduct}
        />
      </td>
*/


    return {
        head : (rowData) => T.softwareProduct,
        body : (rowData) => rowData.software_product_name ?? '',
    };

};