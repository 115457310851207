// prettier-ignore-start
import {T} from "../../../../../lang/ru";


export const Source = () => {

/*
  <th>{T.filterNames.source}</th>
      <td>
        <OrderTableSelect
          name          = "source_id"
          selectedValue = {source_id}
          options       = {sources}
          onChange      = {updateInput}
          placeholder   = {T.empty.source}
        />
      </td>

*/


    return {
        head : (rowData) => T.filterNames.source,
        body : (rowData) => rowData.source_name ?? '',
    };

};