// prettier-ignore-start
import React, {useState} from 'react';

import {T}                  from "../../../lang/ru";
import {BonusesAdminStatus} from "../../../api/methods"

import {yellow}             from "../../../constants/Colours";
import List                 from "../../../components/Lists/List";
import LIF                  from "../../../components/Lists/ListItemFormats";
import LIT                  from "../../../components/Lists/ListItemTypes";
import Text                 from "../../../components/UI-kit/Text";
import BonusesSettings      from "./BonusesSettings";


function SettingsListsBonuses() {
    const [data, setData] = useState([])

    const isDataHasDuplicates = data.find(item1 => data.find(item2 => item1.id !== item2.id && item1.sum === item2.sum))

    const onSuccess = (success, data) => {
        success(data)
        setData(data.data)
    }

    return (
        <>
            {isDataHasDuplicates && <Text color={yellow} style={{paddingTop:'15px'}}>Осторожно! У вас есть дубликаты (совпадает порог суммы)!</Text>}
            <List
                //title        = {T.editBonuses}
                popupPrefix  = {'bonuses'}
                getListData  = {(data, success, fail) => BonusesAdminStatus.getList({}, (data) => onSuccess(success, data), fail)}
                cols         = {[
                    {name: 'name'       , title: 'название'             , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, isValid: (val) => val !== ""},
                    {name: 'value'      , title: 'значение скидки, %'   , format: LIF.text    , type: LIT.number, canAddAndEdit: true, isValid: (val) => val !== "" && +val>=0 && +val<=100},
                    {name: 'sum'        , title: 'Начальный порог'      , format: LIF.text    , type: LIT.number, canAddAndEdit: true, isValid: (val) => !isNaN(+val)},
                    {name: 'users_count', title: 'Количество участников', format: LIF.text    },
                    {name: 'is_active'  , title: 'Активный'             , format: LIF.check   , type: LIT.check , canAddAndEdit: true, default: 0 },
                ]}
                buttons      =  {[
                    {type: 'new'},
                ]}

                onNew        = {BonusesAdminStatus.createNewStatus}
                onEdit       = {BonusesAdminStatus.updateListItem}
                onDelete     = {BonusesAdminStatus.deleteListItem}

                canDelete    = {(item) => item["users_count"] === 0}

                textOnDelete = {(item) => <>{T.deleteBonusStatus}<b>{item?.name ?? ""}</b>?</>}
            />
            <BonusesSettings />
        </>

    );
}

export default SettingsListsBonuses;