import styled from "styled-components";
import { lightgray } from "../../../../../constants/Colours";
import TopBarItem from "./TopBarItem";

const ElementTopBar = styled.div`
  display: flex;
  & ${TopBarItem}:not(:last-child):after {
    content: " ";
    width: 4px;
    height: 4px;
    background-color: ${lightgray};
    border-radius: 100px;
    display: inline-block;
    margin: auto 10px;
  }
`;

export default ElementTopBar;
