// prettier-ignore-start
import React, { useEffect } from "react";

import ym                  from "react-yandex-metrika";
import ReactGA             from "react-ga";
import { useHistory }      from "react-router-dom";
import { Row }             from "styled-bootstrap-grid";
//import { useDispatch } from "react-redux";

import { getPath, Payers } from "../../../api/methods";
import { useInputSelect }  from "../../../hooks/useInputSelect";

import Title               from "../../../components/UI-kit/Title";
import Button              from "../../../components/UI-kit/Buttons/Button";

//import { setActiveStep } from "../../../../store/activeStep/actions";
import { T }               from "../../../lang/ru";
import { PATHS }           from "../../../constants/RoutesPaths";
import Subtitle2           from "../../../components/UI-kit/Subtitle2";
import BackButton          from "../BackButton";


const OrderNewSt1SetPayer: React.FC = () => {

    const history  = useHistory();
//    const dispatch = useDispatch();

    const inputSelect = useInputSelect({
        name  : 'orderPayer',
        title : 'Заказчик',
        getOptions : (d, s, f) => Payers.getAll(
            {},
            ({ data }) => {
                data = data.map((customerInfo: any) => {
                    const { id, type, surname, name, middlename, name_org } = customerInfo;
                    const customerName = type === 2 ? name_org : `${surname} ${name} ${middlename}`;

                    return { id, name: customerName };
                });

                s({ data });
            },
            f
        )
    });

    useEffect(() => {
      //inputSelect.load();
      //dispatch(setActiveStep(1));
    }, []);

    const goToRequestCategories: () => void = () => {
        ym("reachGoal", "farther");
        ReactGA.ga("send", "event", "farther", "click");

        history.push( getPath(PATHS.orderNewSt2SetCategory, {payer_id: inputSelect.value}) );
    };

    const goToAddNewCustomer: () => void = () => history.push(PATHS.orderNewSt1SetPayerNew);


    return <>
        <Title>{T.customerChoice}</Title>

        {(! inputSelect.hasOptions() && inputSelect.isLoaded)
            ? <>
                <Button onClick={goToAddNewCustomer}>
                    {T.addNewCustomerButton}
                </Button>
                <br /><Subtitle2>{T.noCustomers}</Subtitle2><br />
{/*
                <SecondOrderButton onClick={goToAddNewCustomer} marginLeft marginRight>
                    {T.addNewCustomerButton}
                </SecondOrderButton>
*/}
              </>
            : <Row style={{margin: 0}}>
                {inputSelect.select}

                <Button onClick={goToAddNewCustomer} style={{marginLeft:10}}>
                    +
                </Button>
            </Row>
        }

        <Row style={{margin: 0}}>
            <BackButton buttonType={0} to={PATHS.orders} />

            {inputSelect.hasOptions() && <Button onClick={goToRequestCategories} disabled={! inputSelect.hasSet} style={{marginLeft: 10}}>
                {T.next}
            </Button>}
        </Row>
    </>

};

export default OrderNewSt1SetPayer;
