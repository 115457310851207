// prettier-ignore-start
import React from "react";

import settingsTabType                      from "../tabType";
import SettingsSystemStatusesList           from "./SettingsSystemStatusesList";
import SettingsSystemStatusRoles            from "./SettingsSystemStatusRoles";
import SettingsSystemStatusRoleActions      from "./SettingsSystemStatusRoleActions";
import SettingsSystemStatusAccessRights     from "./SettingsSystemStatusAccessRights";
import SettingsSystemStatusRoleAccessRights from "./SettingsSystemStatusRoleAccessRights";
import SettingsSystemStatusMails            from "./SettingsSystemStatusMails";


const SettingsSystemStatuses = ({ sub = settingsTabType.systemStatuses }) => {

    if (sub === settingsTabType.systemStatusAccessRights    ) return <SettingsSystemStatusAccessRights     />
    if (sub === settingsTabType.systemStatusRoles           ) return <SettingsSystemStatusRoles            />
    if (sub === settingsTabType.systemStatusRoleActions     ) return <SettingsSystemStatusRoleActions      />
    if (sub === settingsTabType.systemStatusRoleAccessRights) return <SettingsSystemStatusRoleAccessRights />
    if (sub === settingsTabType.systemStatusMails           ) return <SettingsSystemStatusMails            />

    return <SettingsSystemStatusesList />

};

export default SettingsSystemStatuses;
