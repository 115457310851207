import {useEffect, useState} from "react";
import {useActive} from "./useActive";


// prettier-ignore-start
export function useDataRequest ({
    request       = (d, s, f) => {},
    requestData   = {},
    defaultResult = {},
    hasResult     = (data) => ( Array.isArray(data) ? data.length > 0 : data != null ),
    autoLoad = false,
}) {

    const loading = useActive(false);
    const loaded  = useActive(false);

    const [data, setData] = useState( defaultResult );

    useEffect(() => {
        if (autoLoad) loadData();
    }, []);


    const loadData = (data = {}) => {
        if (loading.isActive || loaded.isActive) return;

        reloadData(data);
    }

    const reloadData = (data = {}) => {
        loading.activate();
        request(
            {...requestData, ...data},
            ({data}) => { // success
                setData(data);
                loading.deactivate();
                loaded.activate();
            },
            () => { // fail
                loading.deactivate();
                loaded.activate();
            }
        );
    }


    return {
        isLoading : loading.isActive,
        isLoaded  : loaded.isActive,

        load      : loadData,
        reload    : reloadData,

        data      : data,
        setData   : setData,

        hasResult : () => hasResult(data),
    };
}

// prettier-ignore-end