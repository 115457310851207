// prettier-ignore-start
import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {Application,
      Delivery, getPath} from '../../../../api/methods';
import { PATHS }         from '../../../../constants/RoutesPaths';
import { green }         from '../../../../constants/Colours';
import { T }             from '../../../../lang/ru';
import {useDataRequest}  from "../../../../hooks/useDataRequest";

import Button            from '../../../../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../../../../components/UI-kit/Buttons/SecondOrderButton';
import FloatingInput     from '../../../../components/UI-kit/FloatingInput';
import FloatingSelect    from '../../../../components/UI-kit/FloatingSelect';
import Radio             from '../../../../components/UI-kit/Radio';
import Subtitle2         from '../../../../components/UI-kit/Subtitle2';
import Text              from '../../../../components/UI-kit/Text';
import Title             from '../../../../components/UI-kit/Title';
import {Preloader} from "../../../../components/UI-kit/Preloader/Preloader";
import {useUserAccessRightChecks} from "../../../../hooks/useUserAccessRightChecks";


const OrderDelivery = () => {

    const { order_id, back_type } = useParams();

    const history    = useHistory();
    const userAccess = useUserAccessRightChecks();

    const [isDisabledUpdateButton, setIsDisabledUpdateButton] = useState(true );
    const [saveSuccess           , setSaveSuccess           ] = useState(false);

    const allTypesDelivery = useDataRequest({
        request       : Delivery.getAll,
        defaultResult : [],
        autoLoad      : true,
    });

    const values = useDataRequest({
        request       : (d,s,f) => Application.getDeliveryById(d, ({ data }) => {
            const date = data[0].term ? data[0].term.split(' ')[0] : '';

            setIsDisabledUpdateButton(false);

            s({data:{...data[0], term: date }} );
        }, f),
        requestData   : { id: order_id },
        defaultResult : {
            delivery_cost        : '',
            delivery_is_needed   :  1,
            name                 : '',
            region               : '',
            delivery_term        : '',
            delivery_track_number: '',
            delivery_method_id   : -1,
            delivery_address     : '',
        },
        autoLoad : true,
    });

    const {
        delivery_method_id,
        //region,
        delivery_track_number,
        //name,
        delivery_term,
        delivery_cost,
        delivery_is_needed,
        delivery_address,
    } = values.data;

    const isBackToList = back_type === 'list';

    const getIsNeedDelivery_bool = (is_need_delivery) => (is_need_delivery === 1     ) ? 'true' : 'false';
    const getIsNeedDelivery_int  = (is_need_delivery) => (is_need_delivery === 'true') ? 1      : 0      ;

    const [isNeedDelivery, setIsNeedDelivery] = useState(
        getIsNeedDelivery_bool(delivery_is_needed)
    );

    useEffect(() => {
        setIsNeedDelivery(
            getIsNeedDelivery_bool(
                values.data.delivery_is_needed
            )
        );
    }, [values.data?.delivery_is_needed]);

    useEffect(() => {
        setIsDisabledUpdateButton(false);
        setSaveSuccess(false);

        values.setData((prev) => {
            return { ...prev, delivery_is_needed: getIsNeedDelivery_int(isNeedDelivery) };
        });
    }, [isNeedDelivery]);


    if (! userAccess.isAllowAppDataDelivery) return null;


    const onChange = (e) => {
        let { value, name } = e.target;
        setIsDisabledUpdateButton(false);
        setSaveSuccess(false);

        values.setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const onClickSave = () => {
        Application.updateDelivery({ ...values.data, id: order_id }, () => {
            setIsDisabledUpdateButton(true);
            setSaveSuccess(true);
            // goToDefaultPath();
        } );
    };

    const goToDefaultPath = () => {
        history.push( (! isBackToList)
            ? getPath(PATHS.orderInfo, {order_id})
            : PATHS.orders
        );
    }

    const delivery_track_number_text = ((delivery_track_number !== 'null') && (delivery_track_number !== ''))
        ? delivery_track_number
        : null

    const delivery_address_text = ((delivery_address !== null) && (delivery_address !== ''))
        ? delivery_address
        : null

    const delivery_term_text = ((delivery_term !== null) && (delivery_term !== ''))
        ? delivery_term
        : null

    const delivery_method_text = (delivery_method_id && (delivery_method_id > 0))
        ? allTypesDelivery.data.reduce((result, { id, name }) => id === delivery_method_id ? name : result, null )
        : null;


    return <>

        <Title>{T.filterNames.delivery}</Title>

        {values.isLoading && <Preloader />}

        {values.isLoaded && <>
            {userAccess.isAllowAppDataDeliveryEdit && <>
                <Subtitle2>{T.needDelivery}</Subtitle2>
                <Radio
                    radios     = {T.isNeedDeliveryRadio}
                    nameRadio  = "needDelivery"
                    setValue   = {setIsNeedDelivery}
                    radioValue = {isNeedDelivery}
                />
            </>}

            {(isNeedDelivery !== 'false') && <>
                <Subtitle2>{T.typeDeliveryTitle}</Subtitle2>
                {userAccess.isAllowAppDataDeliveryEdit
                    ? <FloatingSelect
                        title       = {T.chooseTypeDelivery}
                        name        = "delivery_method_id"
                        value       = {delivery_method_id ? delivery_method_id : -1}
                        onChange    = {onChange}
                        options     = {allTypesDelivery.data}
                        placeholder = 'загрузка...'
                    />
                    : delivery_method_text ?? 'не задан'
                }

                <Subtitle2>Адрес доставки</Subtitle2>
                {userAccess.isAllowAppDataDeliveryEdit
                    ? <FloatingInput
                        name     = "delivery_address"
                        onChange = {onChange}
                        value    = {delivery_address_text ?? ''}
                    />
                    : delivery_address_text ?? 'отсутствует'
                }

                {(delivery_method_id && (delivery_method_id !== -1)) && <>
                    <Subtitle2>{T.deliveryTime}</Subtitle2>
                    {userAccess.isAllowAppDataDeliveryEdit
                        ? <FloatingInput
                            name     = "delivery_term"
                            onChange = {onChange}
                            value    = {delivery_term_text}
                            type     = "date"
                        />
                        : delivery_term_text ?? 'не задан'
                    }

                    <Subtitle2>{T.deliveryPrice}</Subtitle2>
                    {userAccess.isAllowAppDataDeliveryEdit
                        ? <FloatingInput
                            name        = "delivery_cost"
                            placeholder = {T.deliveryPrice}
                            onChange    = {onChange}
                            value       = {delivery_cost}
                        />
                        : delivery_cost
                    }
                </>}

                <Subtitle2>{T.trackNumber}</Subtitle2>
                {userAccess.isAllowAppDataDeliveryEdit
                    ? <FloatingInput
                        name        = "delivery_track_number"
                        placeholder = {T.enterTrackNumber}
                        onChange    = {onChange}
                        value       = {delivery_track_number_text ?? ''}
                    />
                    : delivery_track_number_text ?? 'отсутствует'
                }
            </>}

            <br /><br />
        </>}

        {userAccess.isAllowAppDataDeliveryEdit &&
            <Button
                onClick={onClickSave}
                disabled={isDisabledUpdateButton || (!values.isLoaded)}
            >
                {T.save}
            </Button>
        }

        <SecondOrderButton
            onClick  = {goToDefaultPath}
            marginLeft
        >
            {(isBackToList) ? T.backToRequests : T.back}
        </SecondOrderButton>

        {saveSuccess && <Text style={{ marginTop: 10 }} color={green}>
            Изменения успешно сохранены!
        </Text>}
    </>

};

export default OrderDelivery;
