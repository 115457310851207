import styled from "styled-components";
import {
  gray,
  lightgray,
  ultraLightgray,
  green,
  accent,
  white,
} from "../../../constants/Colours";
import SecondOrderButton from "../Buttons/SecondOrderButton";
import ThirdOrderButton from "../Buttons/ThirdOrderButton";


const Table = styled.table`
  font-family: "Fraunces", serif;
  font-size: 125%;
  white-space: normal; //nowrap;
  margin: 0;
  border: none;
  border-collapse: separate; // collapse;
  border-spacing: 0;
  table-layout: fixed;
  // border: 1px solid black;
  font-size: 16px !important;
  line-height: 160%;
  margin-bottom: 6px;

  .noborder {
    * {
      margin: 0 !important;
      border: none !important;
      padding: 0 !important;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    border: none !important;
  }
  .short {
    max-width: 150px;

    input {
      widht: 150px;
      text-align: center;
    }
  }

  th.fixed-col {
    background: #f8f8f8;
    z-index: 101; 
  }
  .fixed-row {
    position: sticky !important;
    top: 0;
    z-index: 100; 
    //background: white;
  }
  td.fixed-col {
    background-color: #f8f8f8;
    //background-color: rgba(255, 255, 255, 0.5);
  }
  .fixed-col {
    position: sticky !important;
    left: 0;
    z-index: 101; 
    //background: white;

    outline: 1px solid ${lightgray} !important;
    // border-right: 1px solid ${lightgray} !important;
    &:nth-child(2) {
      left: 60px;
      min-width: 250px;
    }
  }

  & tr>* {
    padding: 8px;
    //padding: 8px 60px 8px 20px;
    border-bottom: 1px solid ${lightgray};
    & select {
      border: 1px solid ${lightgray};
      border-radius: 4px;
    }
  }

  & thead {
    & tr th {
      text-align: left;
      font-weight: normal;
      background-color: ${ultraLightgray};
      // color: ${gray};
    }
    & th {
      border-right: 4px solid ${white};
    }
  }
  & tbody {
    & * {
      font-weight: regular;
    }
  }
  &.miniPadding {
    max-width: 1300px;
    overflow: auto;
    input {
      padding: 0 !important;
    }
    & tr *,
    & td * {
      padding: 10px;
      width: fit-content;
      white-space: nowrap;
    }
  }
  &.smoothPadding {
    & tr > *,
    & td > * {
      padding: 8px 20px 8px 20px;
    }
  }
  & .right {
    text-align: right;
  }
  & tr .gray {
    color: ${gray};
    background-color: ${ultraLightgray};
  }
  & tr .green {
    color: ${green};
  }
  & input,
  textarea {
    font-size: 16px;
    color: ${accent};
    border: none;
  }

  textarea {
    width : 300px;
    height: fit-content;
  }
  
  ${ThirdOrderButton} {
    padding   : 0;
    width     : 100%;
    text-align: left;
  }
  ${SecondOrderButton} {
    border: none;
    padding: 0;
    margin-right: 15px;
  }
`;


export default Table;
