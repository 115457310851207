import T from './types';
import getParentElement from '../../functions/getParentType';
import getParent from '../../functions/getParentType';
import { cloneDeep } from 'lodash';
import { element, structItem } from 'models/elements.model';


const initialState: element[] = [];

const addStruct = (state: element[] = [], id: string, structElement: structItem) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      addStruct(elems, id, structElement);
    });
    if (el.id == id) el["struct"] = [...el.struct, structElement];
  });
};

const deleteStruct = (state: element[] = [], id: string, structId: number) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      deleteStruct(elems, id, structId);
    });

    if (el.id == id)

      el.struct = [
        ...el.struct.filter((structElement) => structElement.id !== structId),
      ];
  });
};

const changeField = (
  elements: element[],
  id: string,
  text: string | number,
  fieldName: 'name' | 'href' | 'tip' | 'repeat'
) => {
  // any here is hardcode
  // function updates object field
  // but different fields demend different types
  // so I don't know how to tell typescript that
  elements.forEach((el: any) => {
    if (el.id == id) {
      el[fieldName] = text;
    } else {
      el.childrens.map(({ elems }: { elems: element[] }) => {
        changeField(elems, id, text, fieldName);
      });
    }
    return;
  });
};

const setStruct = (state: element[] = [], id: string, struct: structItem[]) => {
  state.forEach((el) => {
    el.childrens.map(({ elems }) => {
      setStruct(elems, id, struct);
    });

    if (el.id == id) el["struct"] = [...struct];
  });
};

const sortElements = (elements: element[]) => {
  elements.map((element) =>
    element.childrens.map(({ elems }) => {
      sortElements(elems);
    })
  );

  elements.sort(function (a, b) {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;
    return 0;
  });
};

const getNewState = (elements: element[], deleteElements: element[]) => {
  let newElements = elements;
  const parentElement = getParent(newElements, deleteElements[0]);
    if (parentElement && parentElement.repeat) {

      parentElement.repeat = parentElement.repeat - 1;

      parentElement.childrens.forEach((child) => {
        const newElems: element[] = [];
        child.elems.forEach((elem) => {
          deleteElements.forEach(e => {
            const index = e.id;

            const lastElemIdNumber = +(elem.id.split("_").at(-1) as string)
            const lastDeleteElemIdNumber = +(index.split("_").at(-1) as string)
            const commonElementIdNumber = elem.id.split("_").slice(0, -1).join("_")
            const commonDeleteElemIdNumber = index.split("_").slice(0, -1).join("_")

            if (lastElemIdNumber > lastDeleteElemIdNumber  &&
                commonElementIdNumber == commonDeleteElemIdNumber) {
              elem.id = commonElementIdNumber + "_" + (lastElemIdNumber - 1)
              newElems.push(elem);
            } else if (lastElemIdNumber < lastDeleteElemIdNumber  &&
                commonElementIdNumber == commonDeleteElemIdNumber) {
              newElems.push(elem);
            }
          })

        });
        child.elems = newElems;
      });
    }

  return newElements;
};

const saveDeafultChildren = (elements: element[]) => {
  elements.forEach((element) => {
    let { type, id, childrens, defaultChildren } = element;
    const isSuperComplexList = type == 9;

    if (!defaultChildren) {
      childrens.forEach(({ elems }) => {
        saveDeafultChildren(elems);
      });
    }
    if (isSuperComplexList) {
      const childElements = cloneDeep(childrens[0].elems);

      element.defaultChildren = childElements;
    }
  });
};



const modificateComplexListIds = (elements: element[], staticPartOfId: string) => {
  elements.forEach((element) => {
    let { type, id, childrens, defaultChildren, repeat, parent_id } = element;
    const stringId = id.toString();
    const isIdWithStaticPart = stringId.includes("_");

    if (parent_id != null) {
      // element.parent_id = parent;
    }

    // Если элемент уже модифицирован, надо заменить старую доп часть на новую
    if (isIdWithStaticPart) {
      const partsOfId = stringId.split("_");
      const idWithoutPart = partsOfId.shift();
      element.id = idWithoutPart + staticPartOfId;
    }

    // Если еще нет, то просто добавляем статичную часть
    else {
      element.id = id + staticPartOfId;
    }

    const isSuperComplexList = type == 9;

    const isRepeatUndefined = typeof repeat == "undefined";

    if (isRepeatUndefined) {
      repeat = 1;
    }

    if (isSuperComplexList && defaultChildren && repeat) {
      const { elems } = childrens[0];

      const countOfChildrens = elems.length;
      const countOfDefaultChildren = defaultChildren.length;
      const startIndex = countOfChildrens / countOfDefaultChildren;
      for (let i = startIndex; i < repeat; i++) {

        let newElements = cloneDeep(defaultChildren);
        childrens[0].elems = [...childrens[0].elems, ...newElements];

      }

      for (let i = 0; i < repeat; i++) {
        let elemsToUpdate = [];

        const indexesOfGroups = {
          min: i * countOfDefaultChildren,
          max: (i + 1) * countOfDefaultChildren,
        };

        const newPart = staticPartOfId + "_" + i;
        for (let j = indexesOfGroups.min; j < indexesOfGroups.max; j++) {

          const elementToChangeId = childrens[0].elems[j];
          elemsToUpdate.push(elementToChangeId);
        }
        modificateComplexListIds(elemsToUpdate, newPart);
      }
    } else {
      childrens.map(({ elems }) => {
        modificateComplexListIds(elems, staticPartOfId);
      });
    }
  });

};


export default function elements(state = initialState, action: any) {
  const stateCopy = cloneDeep(state);
  switch (action.type) {
    case T.SET_EDITOR_ELEMENTS:
      return [...action.payload];
    case T.DELETE_ELEMENT_FROM_STRUCT:
      deleteStruct(stateCopy, action.id, action.structElementId);
      return stateCopy;
    case T.SET_STRUCT_ELEMENT:
      setStruct(stateCopy, action.id, action.struct);
      return stateCopy;
    case T.TYPE_NAME:
      changeField(stateCopy, action.id, action.text, 'name');
      return stateCopy;
    case T.TYPE_TIP:
      changeField(stateCopy, action.id, action.text, 'tip');
      return stateCopy;
    case T.CHANGE_HREF:
      changeField(stateCopy, action.id, action.text, 'href');
      return stateCopy;
    case T.SET_REPEAT_CHILDREN_COUNT_TO_ELEMENT:
      changeField(stateCopy, action.id, action.repeat, 'repeat');
      modificateComplexListIds(stateCopy, '');
      return stateCopy;
    case T.MODIFICATE_IDS_OF_SUPER_COMPLEXT_LISTS:
      saveDeafultChildren(stateCopy);
      modificateComplexListIds(stateCopy, '');
      return stateCopy;
    case T.DELETE_SUPERCOMPLEX_ELEMENTS:
      return getNewState(stateCopy, action.element);
    case T.CHANGE_REPEAT:
      changeField(stateCopy, action.id, action.count, 'repeat');
      return stateCopy;
    case T.CLEAR_STATE:
      return []
    default:
      return stateCopy;
  }
}
