import T from './types';
import { AnyAction } from '@reduxjs/toolkit';

const initialState: string[] = [];

export default function reducer(state = initialState, action: AnyAction) {
  let copyState: string[];
  switch (action.type) {
    case T.ADD_EMPTY_FIELDS_IDS:
      return action.payload;
    case T.REMOVE_FIELD:
      copyState = [...state];
      copyState = copyState.filter((i) => i !== action.payload);
      return copyState;
    case T.ADD_FIELD:
      copyState = [...state];
      copyState.push(action.payload);
      return copyState;
    default:
      return state;
  }
}
