// prettier-ignore-start
import React from "react";

import {Application             } from "../../../api/methods";
import {isEmpty                 } from "../../../functions/common";
import {getFileExtension        } from "../../../functions/getFileExtension";
import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import FileHandler                from "../../../components/File/FileHandler";
import {Empty                   } from "../../../components/Empty";


const OrderInfoPaymentOrder = ({applicationData}) => {

    const userAccess = useUserAccessRightChecks();

    const {
        id,
        contract_number,
        payment_order,
    } = applicationData;

    const userFileName = isEmpty(payment_order) ?  null : `Платежное поручение к Заявке №${contract_number}.${getFileExtension(payment_order)}`;

    const doUpload   = (data, success, fail, load) => Application.loadPaymentOrder  (data, success, fail, load);
    const doDownload = (data, success, fail, load) => Application.getPaymentOrder   (data, success, fail, load);
    const doDelete   = (data, success            ) => Application.deletePaymentOrder(data, success);

    //userCanDelete    = {false}
    //userCanUpload    = {true}
    //isDeleteBlocked  = {false}


    if (! userAccess.isAllowAppDataDocsPaymentOrder) return null;
    if (isEmpty(payment_order) && (! userAccess.isAllowAppDataDocsPaymentOrderCreate)) return <Empty text='отсутствует' />;
    return (
        <FileHandler
            data             = {{id}}
            fileName         = {userFileName}
            userCanDelete    = {userAccess.isAllowAppDataDocsPaymentOrderDelete}
            userCanUpload    = {userAccess.isAllowAppDataDocsPaymentOrderCreate}

            isDeleteBlocked  = {! userAccess.isAllowAppDataDocsPaymentOrderDelete}

            onUpload         = {doUpload}
            onDownload       = {doDownload}
            onDelete         = {doDelete}

            uploadButtonText = "Загрузить платежное поручение"
            subjectIm        = "платежное поручение"
            subjectRod       = "платежного поручения"
        />
    );

};

export default OrderInfoPaymentOrder;