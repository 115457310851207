import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Text from '../../../../components/UI-kit/Text';
import { accent, gray, red, yellow, white } from '../../../../constants/Colours';
import { setIsShowPopup } from '../../../../store/popups/actions';

import SecondOrderButton from '../../../../components/UI-kit/Buttons/SecondOrderButton';

import { Message as M } from '../../../../api/methods';

import check from '../../../../assets/svg/tick.svg';
import doubleTick from '../../../../assets/svg/double-tick.svg';
import editSquare from '../../../../assets/svg/edit-square.svg';
import deleteSquare from '../../../../assets/svg/delete-square.svg';

import { TextareaAutoResize } from '../../../../components/UI-kit/TextareaAutoResize';
import FileHandler from '../../../../components/File/FileHandler';
import {useUserAccessRightChecks} from "../../../../hooks/useUserAccessRightChecks";

const messageBlockStatusColor = {
    private: yellow,
    public : accent,
    default: 'transparent',
};

const MessageStatus = styled.span`
    color           : ${white};
    padding         : 5px 10px;
    background-color: ${(props) => messageBlockStatusColor[props.status]};
    font-size       : 13px;
    border-radius   : 15px;
`;
const MessageBlock = styled.div`
    background   : #fff;
    padding      : 20px 20px 10px 20px;
    margin       : 0 0 20px 0;
    // position    : relative;
    border-radius: 10px;
    border       : 2px solid ${(props) => messageBlockStatusColor[props.status]};
    max-width    : 800px;
`;

const DeleteButton = styled.div`
    position: absolute;
    right   : 20px;
    top     : 20px;
  
    width   : 20px;
    height  : 20px;
  
    cursor  : pointer;
  
    &::before,
    &::after {
        content   : '';
        display   : block;
        width     : 1px;
        height    : 18px;
        background: ${red};
        position  : absolute;
        right     : 50%;
    
        transition: all 0.4s linear;
    }
  
    &:hover {
        &::before,
        &::after {
          background: ${red};
        }
    }
  
    &::before {
        transform: rotate(45deg);
    }
  
    &::after {
        transform: rotate(-45deg);
    }
`;

const Tick       = () => <img src={check     } alt="" />;
const DoubleTick = () => <img src={doubleTick} alt="" />;

const EditSquare = (props) =>
    <img
        {...props}
        src   = {editSquare}
        style = {{ cursor: 'pointer', marginRight: 6 }}
        alt   = "изменить"
    />

const DeleteSquare = (props) =>
    <img
        {...props}
        src   = {deleteSquare}
        style = {{ cursor: 'pointer' }}
        alt   = "удалить"
    />

const formatDate = (dateString) => {
    try {
        dateString = new Date(dateString).toLocaleString();

        let [date, time] = dateString.split(',');

        const [hour, min] = time.split(':');

        return { time: `${hour}:${min}`, date };
    } catch (error) {
        return { time: '', date: '' };
    }
};

const Message = ({ message, setDeleteMessage, index }) => {

    const dispatch   = useDispatch();
    const userAccess = useUserAccessRightChecks();

    const [newMessage   , setNewMessage   ] = useState(message.message);
    const [isEditMessage, setIsEditMessage] = useState(false);

    const edit     = () => setIsEditMessage(true );
    const stopEdit = () => setIsEditMessage(false);

    const { time, date } = formatDate(message.created_at);

    const {
      file_id,
      file_name,
      read
    } = message;

    const isNewMessageEqualOld = newMessage === message.message;
    const isMyMessage          = message.sender === 'Вы';
    const isRead               = read === 1;

    useEffect(() => {
        setDefaultMessage();
    }, [message]);


    const messageStatus =
        (! userAccess.isAllowChatPrivate)
            ? 'default'
            : (message.private)
                ? 'private'
                : 'public'

    const setDefaultMessage = () => {
        setNewMessage(message.message);
    };

    const changeMessage = (e) => {
        setNewMessage(e.target.value);
    };

    const onClickDelete = () => {
        setDeleteMessage(message.id);
        dispatch(setIsShowPopup('isShowDeleteMessage', true));
    };

    const doDownload = (data, success, fail, load) => {
        M.loadAttachedFile(data, success, fail, load);
    };

    const updateMessage = () => {
        M.update({ id: message.id, message: newMessage }, stopEdit);
    };

    return (
        <MessageBlock
            style  = {{ marginLeft: isMyMessage ? 'auto' : null }}
            status = {messageStatus}
        >
            <div
                style={{
                display       : 'flex',
                alignItems    : 'center',
                justifyContent: 'space-between',
                }}
            >
                <MessageStatus status={messageStatus}>
                    {userAccess.isAllowChatPrivate && messageStatus}
                </MessageStatus>

                <div>
                    {(userAccess.isAllowChatEditAll || (userAccess.isAllowChatEditCreatedBy && isMyMessage)) && (
                        (isEditMessage)
                            ? <SecondOrderButton
                                  style   = {{ marginRight: 10 }}
                                  onClick = {isEditMessage ? updateMessage : edit}
                              >
                                  Сохранить
                              </SecondOrderButton>
                            : <EditSquare style={{ marginRight: 4 }} onClick={edit} />
                    )}

                    {(userAccess.isAllowChatDeleteAll || (userAccess.isAllowChatDeleteCreatedBy && isMyMessage)) &&
                        <DeleteSquare onClick={onClickDelete} />
                    }
                </div>
            </div>

            {(! isMyMessage) &&
                <Text style={{ fontWeight: 600 }} rightMargin withoutMargin>
                    {message.sender}
                </Text>
            }

            {(userAccess.isAllowChatEdit && isEditMessage)
                ? <div style={{ margin: '10px 0 10px 0' }}>
                      <TextareaAutoResize
                          value       = {newMessage}
                          placeholder = {'Введите текст сообщения'}
                          onChange    = {changeMessage}
                          style       = {{
                              borderColor: isNewMessageEqualOld ? '#eee' : accent,
                              width      : '90%',
                          }}
                      />
                  </div>
                : <Text style={{ whiteSpace: 'pre-wrap' }}>{newMessage}</Text>
            }

            {file_id && <FileHandler
                data               = {{ file_id }}
                fileName           = {file_name}
                isDeleteBlocked    = {true}
                onDownload         = {doDownload}
                downloadButtonText = "Прикрепленный файл"
                subjectIm          = "файл"
                subjectRod         = "файла"
            />}

            <Col>
                <Row>
                    <Text
                        color = {gray}
                        style = {{
                            marginLeft  : 'auto',
                            marginRight : 10,
                            fontSize    : 13,
                            lineHeight  : '200%',
                        }}
                        withoutMargin
                    >
                        {date}&nbsp;&nbsp;{time}
                    </Text>

                    <Text color={gray} rightMargin withoutMargin>
                        {isRead
                            ? <DoubleTick />
                            : <Tick />
                        }
                    </Text>
                </Row>
            </Col>
        </MessageBlock>
    );
};

export default Message;
