// prettier-ignore-start
import {useInputSelect} from "../../hooks/useInputSelect";


const ElementSelect = ({name, title, value, onChange, props}) => {
    const select = useInputSelect({
        ...props,

        title         : title,
        name          : name,
        defaultValue  : value,
        onChanged     : onChange,
    });

    return select.select;
}

export default ElementSelect;
