import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { gray } from '../../../../../constants/Colours';
import { getPreviewMode } from '../../../../../store/previewMode/selectors';
import {
  saveAnswers,
  setUserAnswer,
} from '../../../../../store/userAnswers/actions';
import { getUserAnswers } from '../../../../../store/userAnswers/selectors';
import Button from '../../../../../components/UI-kit/Buttons/Button';
import SecondOrderButton from '../../../../../components/UI-kit/Buttons/SecondOrderButton';
import { DeleteButton } from '../../../../../components/UI-kit/Buttons/DeleteButton';
import Card from '../../../../../components/UI-kit/Cards/Card';
import { CardsWrapper } from '../../../../../components/UI-kit/CardWrapper';
import FloatingInput from '../../../../../components/UI-kit/FloatingInput';
import InputButtonGroup from '../../../../../components/UI-kit/InputButtonGroup';
import { API_URL, Application } from '../../../../../api/methods';
import { useParams } from 'react-router-dom';
import { Divider } from '../../../../../components/UI-kit/Divider';
import Text from '../../../../../components/UI-kit/Text';
import { LinkButton } from '../../../../../components/UI-kit/Buttons/LinkButton';
import Wrapper from '../Wrapper';
import questionnaireFunc from '../../../../../functions/questionnaireFunc';
import { EmptyFieldWrapper } from '../../../../../components/UI-kit/EmptyFieldWrapper';
import hooks from '../../../../../functions/hooks';
import {getAutosave} from "../../../../../store/autosave/selectors";

const RepeatListPart = ({ struct, element, addStruct, deleteStruct }) => {

  const dispatch   = useDispatch();
  const isAutosave = useSelector(getAutosave);

  const { id, name } = element;
  const key = id;

  const { application_id } = useParams();

  const [selectedValue, setSelectedValue] = useState();
  const [state, setState] = useState({ value: '' });
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const isEmpty = hooks.useIsFieldEmpty(id);
  const [newFields, setNewFields] = useState({});

  const isPreviewMode = useSelector(getPreviewMode);
  const userAnswers = useSelector(getUserAnswers);
  const listValues = userAnswers[key]?.answer;

  useEffect(() => {
    setIsAddButtonDisabled(state.value === '');
  }, [state.value]);

  const onClickRadioButton = (selectedValue) => setSelectedValue(selectedValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleAddStruct = () => {
    addStruct(element.id, state.value);
    setState({ ...state, value: '' });
  };

  const changeNewFieldsValue = (e) => {
    const { name, value } = e.target;
    setNewFields({ ...newFields, [name]: value });
  };

  const addUserAnswerBlock = () => {
    let userAnswer = questionnaireFunc.getAnswerInfo(element, [{}]);

    if (listValues) {
      userAnswer = questionnaireFunc.getAnswerInfo(element, [
        ...listValues,
        {},
      ]);
      dispatch(setUserAnswer(key, userAnswer));
    } else {
      dispatch(setUserAnswer(key, userAnswer));
    }
  };

  const removeUserAnswer = (index) => {
    const buf = listValues;
    buf.splice(index, 1);

    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf);

    dispatch(setUserAnswer(key, userAnswer));
  };

  const isEmptyNewFields = () => newFields == {};

  const changeUserAnswer = (e, index) => {
    const { name, value } = e.target;
    const buf = [...listValues];
    buf[index][name] = value;
    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf);
    dispatch(setUserAnswer(key, userAnswer, false));
  };

  const handleLoadFile = (e, index) => {
    const { files, name } = e.target;
    const buf = [...listValues];
    const [file] = files;

    Application.uploadFile({ file, application_id }, ({ data }) => {
      buf[index][name] = data.link;
      const userAnswer = questionnaireFunc.getAnswerInfo(element, buf);
      dispatch(setUserAnswer(key, userAnswer));
    });
  };

  const saveAnswer = () => {
    if (isAutosave) dispatch(saveAnswers());
  }

  function onClickAddFile(index) {
    const file_key = getFileKey(index);
    document.getElementById(file_key).click();
  }

  const handleCancelUploadFile = (index) => {
    const buf = [...listValues];
    buf[index].file = '';

    const file_key = getFileKey(index);

    // ToDo нужно сделать удаление файла с сервера.

    const userAnswer = questionnaireFunc.getAnswerInfo(element, buf);

    dispatch(setUserAnswer(file_key, userAnswer));
  };

  const getFileKey = (index) => key + '_file_' + index;

  const checkIsAvailableAddNewInfo = () => {
    if (!listValues) return true;
    const buf = [...listValues];
    const lastInfo = buf?.pop();
  };

  checkIsAvailableAddNewInfo();

  return (
    <>
      {isPreviewMode ? (
        <EmptyFieldWrapper empty={isEmpty} id={id}>
          <CardsWrapper>
            {listValues
              ? listValues.map((cardInfo, index) => {
                  const file_href = cardInfo.file;

                  const isFileExist = typeof file_href !== 'undefined';

                  let isFileNotEmpty = false;
                  if (isFileExist) isFileNotEmpty = file_href.length > 0;

                  return (
                    <Card key={index} style={{ marginBottom: 20 }}>
                      {element.struct.map(({ id, name }, structIndex) => {
                        const isFieldTitle = structIndex === 0;

                        const isShowFileBlock = isFileNotEmpty && !isFieldTitle;
                        if (isShowFileBlock && structIndex === 1) {
                          return (
                            <Wrapper>
                              <LinkButton
                                style={{ marginRight: 10 }}
                                href={API_URL.replace('/api/', '') + file_href}
                                download
                              >
                                Файл прикреплен
                              </LinkButton>
                              <SecondOrderButton
                                className={'delete-button'}
                                onClick={() => handleCancelUploadFile(index)}
                              >
                                Отменить загрузку
                              </SecondOrderButton>
                            </Wrapper>
                          );
                        }

                        if (isShowFileBlock) {
                          return null;
                        }

                        return (
                          <>
                            <FloatingInput
                              key={id}
                              name={name}
                              onChange={(e) => changeUserAnswer(e, index)}
                              onBlur={saveAnswer}
                              value={cardInfo[name]}
                              style={isFieldTitle ? { fontWeight: 'bold' } : {}}
                              placeholder={name}
                              label={name}
                            />
                            {isFieldTitle ? <Divider /> : null}
                          </>
                        );
                      })}
                      {isFileNotEmpty ? null : (
                        <>
                          <Text
                            style={{ textAlign: 'center' }}
                            withoutMargin
                            color={gray}
                          >
                            или
                          </Text>
                          <Button onClick={() => onClickAddFile(index)}>
                            Прикрепить файл
                            <input
                              id={key + '_file_' + index}
                              name="file"
                              onChange={(e) => handleLoadFile(e, index)}
                              type="file"
                              style={{ display: 'none' }}
                            />
                          </Button>
                        </>
                      )}
                      <DeleteButton onClick={() => removeUserAnswer(index)}>
                        &times;
                      </DeleteButton>
                    </Card>
                  );
                })
              : null}
            <Button onClick={addUserAnswerBlock} margin={'10px 0'}>
              Добавить элемент
            </Button>
          </CardsWrapper>{' '}
        </EmptyFieldWrapper>
      ) : (
        <>
          {element.struct.map(({ id, name }, index) => {
            const isFieldTitle = index === 0;
            return (
              <InputButtonGroup key={id}>
                <FloatingInput
                  name     = "value"
                  onChange = {handleChange}
                  value    = {state.value}
                  disabled
                  style    = {isFieldTitle ? { fontWeight: 'bold' } : {}}
                  label    = {isFieldTitle ? 'Название сущности' : 'Поле сущности'}
                  /*value     = {name}*/
                />
                <Button
                  onClick={() => deleteStruct(element.id, id)}
                  margin={'10px 0'}
                >
                  Удалить поле
                </Button>
              </InputButtonGroup>
            );
          })}
          <Col>
            <Row>
              <InputButtonGroup>
                <FloatingInput
                  name="value"
                  onChange={handleChange}
                  value={state.value}
                  label={'Название элемента'}
                />
                <Button
                  disabled={isAddButtonDisabled}
                  onClick={handleAddStruct}
                  margin={'10px 0'}
                >
                  Добавить поле
                </Button>
              </InputButtonGroup>
            </Row>
          </Col>
        </>
      )}
    </>
  );
};

export default RepeatListPart;
