import T from "./types";

export const setIsShowPopup = (name, value) => ({
  type: T.SET_IS_SHOW_POPUP,
  name,
  value,
});

export const openPopup  = (name) => setIsShowPopup(name, true );
export const closePopup = (name) => setIsShowPopup(name, false);
