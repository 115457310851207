import T from "./types";

const initialState = false;


export default function isAutosave(state = initialState, action) {

    switch (action.type) {
        case T.AUTOSAVE_ON : return true;
        case T.AUTOSAVE_OFF: return false;
        case T.AUTOSAVE_SET: return action.payload;

        default            : return state;
    }

}
