// prettier-ignore-start
import React from 'react';

import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";

import OrderEditButton   from "./OrderEditButton";
import OrderDeleteButton from "./OrderDeleteButton";
import OrderNewButton    from "./OrderNewButton";

type Props = {
    application_id: string,
    form_id       : string,
}


const OrderButtons: React.FC<Props> = ({
    application_id = '',
    form_id = '',
}) => {

    const userAccess:any = useUserAccessRightChecks();

    if (! (userAccess.isAllowAppNewButton || userAccess.isAllowAppEdit || userAccess.isAllowAppDelete) ) return null;


    return <>
        {userAccess.isAllowAppNewButton && <OrderNewButton />}

        {userAccess.isAllowAppEdit && <OrderEditButton
            application_id = {application_id}
            form_id        = {form_id}
        />}

        {userAccess.isAllowAppDelete && <OrderDeleteButton
            application_id = {application_id}
        />}
    </>

};

export default OrderButtons;
