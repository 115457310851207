import styled from 'styled-components'
import { device } from '../../constants/Breakpoints'
import { black } from '../../constants/Colours'

const Subtitle2 = styled.h3`
    font-size: 16px;
    line-height: 160%;
    color: ${black};
    font-weight: bold;
    margin-bottom: 6px;
    @media ${device.tablet} {
        font-size: 18px;
    }
`

export default Subtitle2