import styled from 'styled-components';
import { device } from '../../constants/Breakpoints';
import { black } from '../../constants/Colours';

type Props = {
  margin?: string;
};

const Title = styled.h1`
  font-size: 32px;
  line-height: 120%;
  margin: ${(props: Props) => {
    return props.margin ? props.margin : '30px 0px 15px';
  }};

  font-weight: bold;
  //max-width: 740px;

  @media ${device.tablet} {
    font-size: 24px;
    margin: 15px 0;
  }
`;

export default Title;
