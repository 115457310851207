// prettier-ignore-start

const listItemTypes = {
    text            : 'text',
    textarea        : 'textarea',
    number          : 'number',
    number_positive : 'number_positive',
    percent         : 'percent',
    date            : 'date',
    email           : 'email',
    select          : 'select',
    check           : 'check',
    color           : 'color',
    file            : 'file',

    loader          : 'loader',
};

export default listItemTypes;