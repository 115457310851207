// prettier-ignore-start
import React from "react";

import { useParams }      from "react-router-dom";
import { getPath, RatingScore,
    RatingScoreValue }    from "../../../api/methods";
import { T }              from "../../../lang/ru";
import List               from "../../../components/Lists/List";
import LIF                from "../../../components/Lists/ListItemFormats";
import LIT                from "../../../components/Lists/ListItemTypes";
import { PATHS }          from "../../../constants/RoutesPaths";
import { useDataRequest } from "../../../hooks/useDataRequest";


const SettingsListsRatingScoreValues = () => {

    const { rating_score_id } = useParams ();

    const rating_score = useDataRequest({
        autoLoad    : true,
        request     : RatingScore.read,
        requestData : { id: rating_score_id },
    });


    return (
        <List
            title        = {'Значения рейтинга: ' + (rating_score.data.rating?.title ?? '-') + ' / ' + (rating_score.data.title ?? '-')}
            popupPrefix  = {'settingsRatingScoreValues'}
            getListData  = {(data, s, f) => RatingScoreValue.list({ rating_score_id }, s, f)}

            cols         = {[
                {name: 'is_active'  , title: 'активен'      , format: LIF.check   , type: LIT.check , canAddAndEdit: true, default: 0 , align: 'center' },
                {name: 'order_id'   , title: 'порядок'      , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true, default: 0 },
                {name: 'title'      , title: 'название'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, default: '', align: 'left'   },
                {name: 'value'      , title: 'значение'     , format: LIF.linkEdit, type: LIT.number, canAddAndEdit: true, default: 0 },
                {name: 'description', title: 'описание'     , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, default: '', align: 'left'   },
                {name: 'sysname'    , title: 'системное имя', format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: true, default: '', align: 'left'   },
            ]}

            buttons      =  {[
                {type: 'group', buttons: [
                    {type: 'back', to: getPath(PATHS.settings.listsRatingScores, {rating_id: rating_score.data.rating_id})},
                ]},
                {type: 'new'},
            ]}

            onNew       = {(data, success, fail) => RatingScoreValue.create({...data, rating_score_id }, success, fail)}
            onEdit       = {RatingScoreValue.update}
            onDelete     = {RatingScoreValue.delete}

            //canDelete    = {(item) => (item['applications_count'] === 0)}

            textOnDelete = {(item) => <>{T.deleteRatingScoreValue}<b>{item?.title ?? ""}</b>?</>}
        />
    );
};

export default SettingsListsRatingScoreValues;
