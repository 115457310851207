import styled from "styled-components";
import { device } from "../../constants/Breakpoints";

type Props = {
  maxWidth?: string;
  columns?: string;
  gap?: string;
  margin?: string;
};

const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props: Props) => props.columns};
  max-width: ${(props: Props) => props.maxWidth};
  grid-gap: ${(props: Props) => props.gap};
  margin: ${(props: Props) => props.margin};
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export default InputsGrid;
