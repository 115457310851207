// prettier-ignore-start
import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {useHistory}  from "react-router-dom";

import styled                  from "styled-components";
import {Application}           from "../../api/methods";
import {closePopup, openPopup} from "../../store/popups/actions";
import {useSaving}             from "../../hooks/useSaving";
import ButtonFixedGroup        from "../../components/UI-kit/Buttons/ButtonsFixedGroup";
import Button                  from "../../components/UI-kit/Buttons/Button";

import ActionFormType                           from "./Actions/ActionFormType";
import ActionSplitter                           from "./Actions/ActionSplitter";
import ActionMessageConfirm                     from "./Actions/ActionMessageConfirm";
import ActionMessageFormComment                 from "./Actions/ActionMessageFormComment";
import ActionMessageFormAssignExpert            from "./Actions/ActionMessageFormAssignExpert";
import ActionMessageFormCommentChatPrivate      from "./Actions/ActionMessageFormCommentChatPrivate";
import ActionMessageFormCommentChat             from "./Actions/ActionMessageFormCommentChat";
import ActionMessageFormAssignExecutor          from "./Actions/ActionMessageFormAssignExecutor";
import ActionMessageFormAssignExpertAndExecutor from "./Actions/ActionMessageFormAssignExpertAndExecutor";
import ActionMessageFormAppDataPrice            from "./Actions/ActionMessageFormAppDataPrice";
import ActionMessageFormAppDataPayment          from "./Actions/ActionMessageFormAppDataPayment";
import ActionMessageFormAppDataContract         from "./Actions/ActionMessageFormAppDataContract";
import ActionMessageFormAppDataAnswers          from "./Actions/ActionMessageFormAppDataAnswers";
import ActionMessageFormAppDataAnswersContinue  from "./Actions/ActionMessageFormAppDataAnswersContinue";
import ActionMessageFormAppDataFile             from "./Actions/ActionMessageFormAppDataFile";
import ActionMessageFormAppDataAct              from "./Actions/ActionMessageFormAppDataAct";
import ActionMessageFormExecutorRating          from "./Actions/ActionMessageFormExecutorRating";


const Buttons = styled.div`
  display: flex;
`;


const ActionsList = ({
    appId = null,
    app = {},
    reload = () => {},
}) => {
    const history  = useHistory();
    const dispatch = useDispatch();
    const loading  = useSaving('загрузка действий по заявке...', '', '[сбой загрузки действий]');

    const [actions       , setActions          ] = useState(null);
    const [actionData    , setActionData       ] = useState(null);

    const isAppLoaded     = app?.id !== undefined;
    const isActionsLoaded = (actions !== null) && (! loading.isActive);

    useEffect(() => {
        loadApplicationActions();
    }, []);


    const actionHandlerslist = {
        '_splitter'                               : ActionSplitter,
        'set_status'                              : ActionMessageConfirm,
        'set_status.comment'                      : ActionMessageFormComment,
        'set_status.comment_chat'                 : ActionMessageFormCommentChat,
        'set_status.comment_chat_private'         : ActionMessageFormCommentChatPrivate,
        'app.assign_expert'                       : ActionMessageFormAssignExpert,
        'app.assign_executor'                     : ActionMessageFormAssignExecutor,
        'app.assign_expert_and_executor'          : ActionMessageFormAssignExpertAndExecutor,
        'app.assign_executor_distribution'        : ActionMessageFormCommentChatPrivate,
        'app.assign_executor_distribution_break'  : ActionMessageFormCommentChatPrivate,
        'app.assign_executor_distribution_accept' : ActionMessageConfirm,
        'app.assign_executor_distribution_reject' : ActionMessageConfirm,
        'app.data.set.price'                      : ActionMessageFormAppDataPrice,
        'app.data.set.payment'                    : ActionMessageFormAppDataPayment,
        'app.data.set.payment.full'               : ActionMessageFormAppDataPayment,
        'app.data.set.contract'                   : ActionMessageFormAppDataContract,
        'app.data.set.act'                        : ActionMessageFormAppDataAct,
        'app.edit'                                : ActionMessageFormAppDataAnswers,
        'app.new.continue'                        : ActionMessageFormAppDataAnswersContinue,

        'app.accepted_by_executor'                : ActionMessageConfirm,
        'app.accepted_by_expert'                  : ActionMessageConfirm,

        'app.doc.upload'                          : ActionMessageFormAppDataFile,
        'app.doc.upload.payed_full_check'         : ActionMessageFormAppDataFile,

        'app.doc.accept.act'                      : ActionMessageConfirm,

        'app.rating'                              : ActionMessageFormExecutorRating,
        //'app.register'                    : '',
    }

    const getActionHandler = (action) => {
        const sysname = (action.sysname in actionHandlerslist) ? action.sysname : 'set_status';

        return actionHandlerslist[sysname]();
    }

    const getActionsWithHandlers = (data) => data.map( (action) => ({
        handler: getActionHandler(action),
        ...action
    }) )

    const loadApplicationActions = () => {
        loading.activate();

        Application.actions({ id: appId }, ( {data} ) => {
            loading.deactivate(true);
            setActions( getActionsWithHandlers(data) );
        }, () => {
            loading.deactivate(false);
        } );
    }

    const getPopupName = (action) => 'popupActionID_'+action.id;

    const setPopupData = (action) => {
        const data = action.handler.data(app);

        setActionData( data );

        if (action.handler.requestData)
            action.handler.requestData( app, setActionData )
    }

    const onClickAction = (action) => {
        switch (action.handler.type) {
            case ActionFormType.popupForm:
                setPopupData(action);
                dispatch( openPopup ( getPopupName(action) ));
                break;

            case ActionFormType.gotoLocation:
                history.push( action.handler.location(app) );
                break;

            default:
        }
    }

    const outPopupForms = () =>
        actions.map( (action) =>
            (action.handler.type === ActionFormType.popupForm) && action.handler.form({
                popupName    : getPopupName(action),
                key          : getPopupName(action),
                title        : action.title,
                props        : action.props,
                app          : app,
                data         : actionData,
                setData      : setActionData,
                run          : (d, s, f) => doRunAction        (action, d, s, f),
                close        : (       ) => onClickActionCancel(action),
            })
        )

    const doRunAction = (action, data, success, fail) => {
        Application.runAction({id: appId, action_id: action.id, ...data}, (data) => {
            reload();
            loadApplicationActions();
            success(data);
        }, (data) => fail(data));
    }

    const onClickActionCancel = (action) => {
        setActionData(null);
        dispatch( closePopup( getPopupName(action) ));
    }

    const outActionButton = (action, style={}) => (
        <Button
            key      = {action.id}
            onClick  = {() => onClickAction(action)}
            disabled = {!isAppLoaded}
            style    = {style}
        >
            {action.title}
        </Button>
    );

    const outActions = () => {
        let groupIndex      = 0;
        let actionsGroupped = [];

        actions.map( (action) => {
            if (action.sysname === ActionFormType._spliter) {
                groupIndex ++;
            } else {
                if (actionsGroupped[groupIndex] === undefined) actionsGroupped[groupIndex] = []
                actionsGroupped[groupIndex].push(action);
            }
        });

        return actionsGroupped.map( (actionGroup, index) =>
            (actionGroup.length === 0)
                ? null
                : (actionGroup.length === 1)
                    ? outActionButton(actionGroup[0], {marginRight: 16})
                    : <ButtonFixedGroup margin="0 16px 0 0">
                        {actionGroup.map( (action) => outActionButton(action) )}
                    </ButtonFixedGroup>
        );
    }


    return (
        <Buttons>
            {isActionsLoaded
                ? <>
                    {outActions   ()}
                    {outPopupForms()}
                </>
                : (isAppLoaded &&
                    <Button
                        disabled = {true}
                        style    = {{maxHeight: '56px', paddingTop: '14px'}}
                    >
                        {loading.spinner()}
                    </Button>
                )
            }
        </Buttons>
    );

};

export default ActionsList;
