// prettier-ignore-start
import React from "react";

import settingsTabType                from "./tabType";
import SettingsExecutorGroupUsersList from "./SettingsExecutorGroupUsersList";
import SettingsExecutorGroupsList     from "./SettingsExecutorGroupsList";


const SettingsExecutorGroups = ({
    sub = settingsTabType.executorGroupsList
}) => {

    if (sub === settingsTabType.executorGroupUsersList) return <SettingsExecutorGroupUsersList />

    return <SettingsExecutorGroupsList />

};

export default SettingsExecutorGroups;
