// prettier-ignore-start
import ActionMessageForm       from "./Base/Forms/ActionMessageForm";
import ActionAppExecutorRating from "./Base/ActionAppExecutorRating";
import ActionFormType          from "./ActionFormType";


const ActionMessageFormExecutorRating = () => {

    const common = ActionAppExecutorRating();


    return {
        type       : ActionFormType.popupForm,

        data       : common.data,
        formCols   : common.formCols,
        form       : (props) => ActionMessageForm().form({...props, onRun: common.onSave}, common.formCols), // , update: common.dataUpdate

        requestData: common.requestData,
    }

};

export default ActionMessageFormExecutorRating;
