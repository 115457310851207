import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setRepeatChildrenCountToElement,
} from "../../../store/elements/actions";
import styled from "styled-components";

import { lightgray } from "../../../constants/Colours";
import hooks from "../../../functions/hooks";
import { getAnswerCount } from "../../../store/userAnswers/selectors";
import { EmptyFieldWrapper } from "../../../components/UI-kit/EmptyFieldWrapper";
import Button from "../../../components/UI-kit/Buttons/Button";

const GroupChildWrapper = styled.div`
  padding: ${(props) => (props.isPreviewMode ? "10px" : "0 40px")};
  border: ${(props) => (props.isPreviewMode ? "" : `1px solid ${lightgray}`)};
  background-color: ${(props) => (props.isPreviewMode ? "" : "#eeeeee88")};
`;

export default function PullElementsChildren({
  child,
  index,
  element,
  isShowChild,
  PullElements,
  ChildPullWrapper,
  elements,
}) {
  const id = element.id;
  const dispatch = useDispatch();
  const isEmptyElems = child.elems?.length === 0;
  const type = element.type;

  const isEmpty = hooks.useIsFieldEmpty(id, element);

  const isSuperComplextList = type === 9;
  const isGroup = type === 5;

  const answersCount = useSelector(getAnswerCount(id));

  const isChangeStyleChildWrapper = !isGroup && !isSuperComplextList;

  const isShowChildren = isShowChild(element, child);

  const duplicate = (repeat) => {
    dispatch(setRepeatChildrenCountToElement(id, repeat));
  }

  const getChildren = () => {
    return isShowChildren ? <PullElements elements={child.elems} /> : null;
  };

  const setAnswersDuplicates = () => {
    if (!element?.repeat && answersCount === 0) {
      duplicate(1)
    } else if (element.repeat >= answersCount) {
      duplicate(element.repeat);
    } else {
      duplicate(answersCount);
    }
    return null;
  };

  useEffect(() => {
    setAnswersDuplicates();
  }, [answersCount, element?.repeat]);


  if (isEmptyElems && type !== 9) {
    return null;
  }
  return (
    <ChildPullWrapper isPreviewMode={isChangeStyleChildWrapper}>
      {isGroup ? (
        <EmptyFieldWrapper empty={isEmpty} id={element.id}>
          <GroupChildWrapper>{getChildren(elements)}</GroupChildWrapper>
        </EmptyFieldWrapper>
      ) : (
        getChildren(elements)
      )}
      {isSuperComplextList ? (
        <>
          <Button
            style={{
              marginBottom: 40,
              marginRight: 20,
            }}
            onClick={() => duplicate(element.repeat + 1)}
          >
            {element.button_name
              ? element.button_name
              : "Добавить группу ответов"}
          </Button>
        </>
      ) : null}
    </ChildPullWrapper>
  );
}
