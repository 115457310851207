import T from "./types";

// prettier-ignore-start

const popupInitialState = false

const initialState = {
  isShowAddDelivery      : false,
  isShowEditDelivery     : false,
  isShowDeleteDelivery   : false,
  isShowAddSource        : false,
  isShowEditSource       : false,
  isShowDeleteSource     : false,
  promocodeAdd           : popupInitialState,
  promocodeEdit          : popupInitialState,
  promocodeDelete        : popupInitialState,
  isShowDeleteMessage    : false,
  isShowDeleteApplication: false,
};

export default function popups(state = initialState, action) {
  switch (action.type) {
    case T.SET_IS_SHOW_POPUP:
      return { ...state, [action.name]: action.value };

    default:
      return state;
  }
}
