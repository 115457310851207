import React from 'react';
import { yellow } from '../../constants/Colours';

export default function LockedSvg() {
  return (
    <svg
      fill={yellow}
      viewBox="243.202 305.99 20 26.588"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 253.369 305.99 C 251.158 305.99 249.247 306.908 248.056 308.397 C 246.865 309.885 246.369 311.815 246.369 313.897 L 246.369 316.99 L 249.369 316.99 L 249.369 313.897 C 249.369 312.319 249.759 311.076 250.4 310.272 C 251.04 309.467 251.912 308.99 253.369 308.99 C 254.829 308.99 255.697 309.44 256.337 310.24 C 256.978 311.041 257.369 312.299 257.369 313.897 L 257.369 318.185 L 260.369 318.642 L 260.369 313.897 C 260.369 311.803 259.841 309.854 258.65 308.365 C 257.458 306.877 255.576 305.99 253.369 305.99 Z M 246.202 316.578 C 244.546 316.578 243.202 317.922 243.202 319.578 L 243.202 329.578 C 243.202 331.234 244.546 332.578 246.202 332.578 L 260.202 332.578 C 261.858 332.578 263.202 331.234 263.202 329.578 L 263.202 319.578 C 263.202 317.922 261.858 316.578 260.202 316.578 L 246.202 316.578 Z M 253.202 321.578 C 254.307 321.578 255.202 322.473 255.202 323.578 C 255.202 324.316 254.8 324.949 254.202 325.297 L 254.202 327.578 C 254.202 328.129 253.753 328.578 253.202 328.578 C 252.651 328.578 252.202 328.129 252.202 327.578 L 252.202 325.297 C 251.604 324.949 251.202 324.316 251.202 323.578 C 251.202 322.473 252.097 321.578 253.202 321.578 Z" />
    </svg>
  );
}
