import React, {useState, useEffect} from "react";

import {BonusesAdminStatus}       from "api/methods";

import {useUserAccessRightChecks} from "hooks/useUserAccessRightChecks";
import {green, red}               from "constants/Colours";
import {bonusesStatusesItem}      from "models/bonuses.model";

import CustomerInfoTable          from "components/UI-kit/Table/CustomerInfoTable";
import { SmallInput }             from "components/UI-kit/SmallInput";
import Button                     from "components/UI-kit/Buttons/Button";
import Text                       from "components/UI-kit/Text";
import InputCheckbox              from "components/UI-kit/InputCheckbox";
import MiniSelect                 from "components/UI-kit/MiniSelect";


type Props = {
  titles              : any;
  infoCustomer        : {[i: string]: any};
  handleChange?       : any;
  saveFunc            : (...rest: any[]) => void;
  listDisabledToUpdate: any;
  listToHide?         : any;
  listToShow?         : any;
  loadUserInfo?       : any;
};

const OrderInfoSpecialistAdminTable: React.FC<Props> = ({
  handleChange = () => {},
  titles = {},
  saveFunc = () => {},
  loadUserInfo,
  infoCustomer = {},
  listDisabledToUpdate = [],
  listToHide = [],
  listToShow = [],
}) => {

  const userAccess:any = useUserAccessRightChecks();

  const [isUpdateProccess, setIsUpdateProccess] = useState<boolean>(false);
  const [isEditUserInfo  , setIsEditUserInfo  ] = useState<boolean>(false);
  const [isSuccessUpdate , setIsSuccessUpdate ] = useState<boolean | null>(null);
  const [userInfo        , setUserInfo        ] = useState<{[i: string]: any}>({})
  const [bonusStatuses   , setBonuseStatuses  ] = useState<bonusesStatusesItem[]>([])

  const startEdit = () => setIsEditUserInfo(true );
  const endEdit   = () => setIsEditUserInfo(false);

  const saveNewInfo = () => {
    startUpdate();
    Object.keys(userInfo).forEach((key) => {
      const value = userInfo[key];
      if (value == null) {
        userInfo[key] = "";
      }
    });
    saveFunc(userInfo,
        finishUpdate, updateError);
  };

  const startUpdate = () => {
    setIsUpdateProccess(true);
  };

  const updateError = () => {
    setIsEditUserInfo (false)
    setIsSuccessUpdate(false);
  }

  const finishUpdate = () => {
    setIsUpdateProccess(false);
    setIsSuccessUpdate (true );
    endEdit();
    loadUserInfo();
  };

  useEffect(() => {
    BonusesAdminStatus.getBonusStatuses({}, (data) => setBonuseStatuses(data.data))
  }, [])

  useEffect(() => {
    setUserInfo(infoCustomer)
  }, [infoCustomer])

  const isShow = (key: any, value: any) => {
    if (key === "is_bonus_program_autoset" && userInfo.is_bonus_program_member === 0) {
      return false
    }
    return (Object.keys(titles).includes(key) && infoCustomer[key] && !listToHide.includes(key))
        || listToShow.includes(key);
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    if (!isNaN(+value)) {
      setUserInfo((prev: any) => ({...prev, [name]: +value}))
    } else {
      setUserInfo((prev: any) => ({...prev, [name]: value}))
    }
  }

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name} = e.target
    setUserInfo((prev: any) => ({ ...prev, [name]: +!prev[name] }))
  }

  const cancelChanges = () => {
    setUserInfo(infoCustomer)
    setIsEditUserInfo(false)
  }

  return (
      <>
        <CustomerInfoTable>
          <tbody>
            {Object.entries(userInfo).map(([key, value]) => {
              const isKeyDate  = key === "created_at"               || key === "updated_at"
              const isCheckBox = key === "is_bonus_program_autoset" || key === "is_bonus_program_member"
              const isDisabled = !isEditUserInfo || listDisabledToUpdate.includes(key)
              const autosetBonusStatus = bonusStatuses.find(i => i.id === infoCustomer["bonus_program_status_id_autoset"])?.name

              return (
                isShow(key, value)
                  ? <tr key={key}>
                      <td className="gray right" style={{ paddingRight: 25, maxWidth: 250 }}>
                        {titles[key]}
                      </td>
                      <td>
                        { isCheckBox
                          ? <div style={{display: "flex", alignItems: "center"}}>
                              <InputCheckbox
                                  onChange = {handleCheckBoxChange}
                                  checked  = {!!value}
                                  name     = {key}
                                  disabled = {isDisabled}/>
                              {key === "is_bonus_program_autoset" ? userInfo[key] ?
                                  (<span>
                                    {autosetBonusStatus ? autosetBonusStatus : "(не задано)"}
                                  </span>) :
                                  (<MiniSelect
                                      value        = {userInfo?.bonus_program_status_id}
                                      name         = {"bonus_program_status_id"}
                                      onChange     = {handleInput}
                                      options      = {bonusStatuses}
                                      disabled     = {isDisabled}
                                      defaultValue = {1}/>
                              ) : null}
                            </div>
                          : <SmallInput
                                style        = {{ padding: 0, width: 400 }}
                                name         = {key}
                                onChange     = {handleInput}
                                defaultValue = {value}
                                placeholder  = {isKeyDate ? "ДД.ММ.ГГГГ" : ""}
                                disabled     = {isDisabled}
                            />
                        }
                      </td>
                    </tr>
                  : null
              );
            })}
          </tbody>
        </CustomerInfoTable>

        {userAccess.isAllowPayerEdit && <>
          <Button
              onClick  = {isEditUserInfo ? saveNewInfo : startEdit}
              disabled = {isUpdateProccess}
              style    = {{marginRight: 10}}
          >
            {isUpdateProccess
                ? 'Обновление...'
                : isEditUserInfo
                    ? 'Сохранить'
                    : 'Изменить'}
          </Button>

          {(isEditUserInfo && (! isUpdateProccess)) && <Button onClick={cancelChanges}>Отменить</Button>}

          {(isSuccessUpdate === true ) && <Text color={green}>Информация о заказчике успешно обновлена</Text>}
          {(isSuccessUpdate === false) && <Text color={red}>Произошла ошибка</Text>}
        </>}
      </>

  );
};
export default OrderInfoSpecialistAdminTable;
