import {useUserAccessRights} from "./useUserAccessRights";
import {UseAccessRightsType} from "./useAccessRights";
import {
  accessRightName,
  accessRightValue,
  useAccessRightChecks,
  UseAccessRightChecksType
} from "./useAccessRightChecks";

// TYPES

// CONSTS
export const userAccessRightName = {
  ...accessRightName,

  appChat                 : 'app.chat',
  appChatEdit             : 'app.chat.edit',
  appChatDelete           : 'app.chat.delete',
  appChatFileAttach       : 'app.chat.file.attach',

  // поля в реестре заявок
  appList                 : 'app.list',          // тип списка
  appListFilter           : 'app.list.filter',   // наличие фильтра
  appListColored          : 'app.list.colored',  // раскраска строк списка
  appListUsers            : 'app.list.users',    // кнопка списка пользователей

  appListDeadlines        : 'app.list.content.deadlines',
  appListWishes           : 'app.list.content.wishes',
  appListPrice            : 'app.list.content.price',
  appListPaymentStatus    : 'app.list.content.price.payment.status',
  appListDelivery         : 'app.list.content.delivery',
  appListContactPerson    : 'app.list.content.contact_person',
  appListWorkers          : 'app.list.content.assigned.workers',
  appListPayer            : 'app.list.content.payer',
  appListSoftwareProduct  : 'app.list.content.software_product',
  appListRoomsCount       : 'app.list.content.rooms_count',
  appListComments         : 'app.list.content.comments',
  appListPromocode        : 'app.list.content.promocode',
  appListStatusOld        : 'app.list.content.status.old',

  appListStatusInWorkDate : 'app.list.content.status.in_work_date',
  appListSource           : 'app.list.content.source',

  // поля в информации по заявке
  appDataQuestionnaire    : 'app.data.questionnaire', // кнопка данных анкеты
  appDataDeadlines        : 'app.data.deadlines',
  appDataWishes           : 'app.data.wishes',
  appDataPrice            : 'app.data.price',
  appDataPricePaymentsList: 'app.data.price.payments.list',
  appDataPaymentStatus    : 'app.data.price.payment.status',
  appDataDelivery         : 'app.data.delivery',
  appDataContactPerson    : 'app.data.contact_person',
  appDataWorkers          : 'app.data.assigned.workers',
  appDataPayer            : 'app.data.payer',
  appDataUser             : 'app.data.assigned.user',
  appDataProps            : 'app.data.props',
  appDataSoftwareProduct  : 'app.data.software_product',
  appDataRoomsCount       : 'app.data.rooms_count',
  appDataExecutorCompany  : 'app.data.executor_company',
  appDataCommentsCommon   : 'app.data.comments.common',
  appDataCommentsPrivate  : 'app.data.comments.private',
  appDataSource           : 'app.data.source',
  appDataActDate          : 'app.data.act_date',

  appDataDocsGeneration         : 'app.data.docs.generation',
  appDataDocsPaymentOrder       : 'app.data.docs.payment_order',
  appDataDocsPaymentOrderDelete : 'app.data.docs.payment_order.delete',
  appDataDocsSigned             : 'app.data.docs.signed',
  appDataDocsSignedDelete       : 'app.data.docs.signed.delete',
  appDataDocsPublished          : 'app.data.docs.published',
  appDataDocsResult             : 'app.data.docs.result',

  appHistoryLog           : 'app.history.log', // кнопка истории изменения статусов по заявке
  appNewButton            : 'app.new.button',  // кнопка новой заявки
  appEdit                 : 'app.edit',        // кнопка изменения заявки
  appDelete               : 'app.delete',      // кнопка удаления заявки

  payer                   : 'payer',           // доступ к информации о заказчике
  payerUserInfo           : 'payer.user',      // инфо о пользователе в инфе о заказчике

  userLoginAs             : 'user.login.as',       // вход под пользователем
  userPasswordReset       : 'user.password.reset', // сброс пароля
  userBonusProgram        : 'user.bonus_program',  // участие в бонусной программе

  sysUpdateAccessButton   : 'system.update.access_rights.button', // кнопка обновления прав текущего пользователя
  sysShowUserName         : 'system.show.user.name', // отобразить имя пользователя
  sysShowUserRole         : 'system.show.user.role', // отобразить название роли пользователя

  sysSettings             : 'system.settings',           // настройки
  sysSettingsFormEdit     : 'system.settings.form.edit', // изменение форм
  sysSettingsCore         : 'system.settings.core',      // системные настройки

  sysHeadTitle            : 'system.head.title',         // заголовок системы
  sysHeadPhone            : 'system.head.phone',
  sysHeadYoutubeLink      : 'system.head.youtube_link',
}

export const userAccessRightValue = {
  ...accessRightValue,

  common               : 'common',
  private              : 'private',
  all                  : 'all',
  createdBy            : 'created_by',

  full                 : 'full',
  short                : 'short',
  total                : 'total',

  appListAdministrator : 'administrator',
  appListCustomer      : 'customer',
  appListExecutor      : 'executor',
}

const defaultAccessRightValue = userAccessRightValue.deny;


// MAIN
export const useUserAccessRightChecks: () => UseAccessRightChecksType = () => {

  const userAccessRights: UseAccessRightsType      = useUserAccessRights (defaultAccessRightValue);
  const check           : UseAccessRightChecksType = useAccessRightChecks(userAccessRights       );


  return {
    ...check,

    // константы
    names        : userAccessRightName,
    values       : userAccessRightValue,

    // результирующие проверки
    isAllowChat                          : check.isNotDeny      ( userAccessRightName.appChat                 ),
    isAllowChatPrivate                   : check.accessRights.is( userAccessRightName.appChat                 , userAccessRightValue.private   ),
    isAllowChatEdit                      : check.isNotDeny      ( userAccessRightName.appChatEdit             ),
    isAllowChatEditAll                   : check.accessRights.is( userAccessRightName.appChatEdit             , userAccessRightValue.all       ),
    isAllowChatEditCreatedBy             : check.accessRights.is( userAccessRightName.appChatEdit             , userAccessRightValue.createdBy ),
    isAllowChatDelete                    : check.isNotDeny      ( userAccessRightName.appChatDelete           ),
    isAllowChatDeleteAll                 : check.accessRights.is( userAccessRightName.appChatDelete           , userAccessRightValue.all       ),
    isAllowChatDeleteCreatedBy           : check.accessRights.is( userAccessRightName.appChatDelete           , userAccessRightValue.createdBy ),
    isAllowChatAttachFile                : check.isNotDeny      ( userAccessRightName.appChatFileAttach       ),

    isAllowHistoryLog                    : check.isNotDeny      ( userAccessRightName.appHistoryLog           ),
    isAllowQuestionnaire                 : check.isNotDeny      ( userAccessRightName.appDataQuestionnaire    ),

    isAllowAppNewButton                  : check.isNotDeny      ( userAccessRightName.appNewButton            ),
    isAllowAppEdit                       : check.isNotDeny      ( userAccessRightName.appEdit                 ),
    isAllowAppDelete                     : check.isNotDeny      ( userAccessRightName.appDelete               ),

    isAllowAppList                       : check.isNotDeny      ( userAccessRightName.appList                 ),
    isAppListAdmin                       : check.accessRights.is( userAccessRightName.appList                 , userAccessRightValue.appListAdministrator ),
    isAppListCustomer                    : check.accessRights.is( userAccessRightName.appList                 , userAccessRightValue.appListCustomer      ),
    isAppListExecutor                    : check.accessRights.is( userAccessRightName.appList                 , userAccessRightValue.appListExecutor      ),
    isAppListShort                       : check.accessRights.is( userAccessRightName.appList                 , userAccessRightValue.short                ),

    isAllowAppListUsers                  : check.isNotDeny      ( userAccessRightName.appListUsers            ),


    isAllowAppListFilter                 : check.isNotDeny      ( userAccessRightName.appListFilter           ),

    isAllowAppListColored                : check.isNotDeny      ( userAccessRightName.appListColored          ),
    isAllowAppListPayer                  : check.isNotDeny      ( userAccessRightName.appListPayer            ),
    isAllowAppListWorkers                : check.isNotDeny      ( userAccessRightName.appListWorkers          ),
    isAllowAppListDeadlines              : check.isNotDeny      ( userAccessRightName.appListDeadlines        ),
    isAllowAppListPrice                  : check.isNotDeny      ( userAccessRightName.appListPrice            ),
    isAllowAppListPriceTotal             : check.accessRights.is( userAccessRightName.appListPrice            , userAccessRightValue.total ),
    isAllowAppListPaymentStatus          : check.isNotDeny      ( userAccessRightName.appListPaymentStatus    ),
    isAllowAppListComments               : check.isNotDeny      ( userAccessRightName.appListComments         ),
    isAllowAppListCommentsPrivate        : check.accessRights.is( userAccessRightName.appListComments         , userAccessRightValue.private ),
    isAllowAppListWishes                 : check.isNotDeny      ( userAccessRightName.appListWishes           ),
    isAllowAppListContactPerson          : check.isNotDeny      ( userAccessRightName.appListContactPerson    ),
    isAllowAppListRoomsCount             : check.isNotDeny      ( userAccessRightName.appListRoomsCount       ),
    isAllowAppListPromocode              : check.isNotDeny      ( userAccessRightName.appListPromocode        ),
    isAllowAppListSoftwareProduct        : check.isNotDeny      ( userAccessRightName.appListSoftwareProduct  ),
    isAllowAppListDelivery               : check.isNotDeny      ( userAccessRightName.appListDelivery         ),
    isAllowAppListSource                 : check.isNotDeny      ( userAccessRightName.appListSource           ),
    isAllowAppListStatusInWorkDate       : check.isNotDeny      ( userAccessRightName.appListStatusInWorkDate ),
    isAllowAppListStatusOld              : check.isNotDeny      ( userAccessRightName.appListStatusOld        ),


    isAllowAppDataDeadlines              : check.isNotDeny      ( userAccessRightName.appDataDeadlines        ),
    isAllowAppDataWishes                 : check.isNotDeny      ( userAccessRightName.appDataWishes           ),
    isAllowAppDataPrice                  : check.isNotDeny      ( userAccessRightName.appDataPrice            ),
    isAllowAppDataPriceAll               : check.accessRights.is( userAccessRightName.appDataPrice            , userAccessRightValue.all   ),
    isAllowAppDataPriceTotal             : check.accessRights.is( userAccessRightName.appDataPrice            , userAccessRightValue.total ),
    isAllowAppDataPricePaymentsList      : check.isNotDeny      ( userAccessRightName.appDataPricePaymentsList),
    isAllowAppDataPaymentStatus          : check.isNotDeny      ( userAccessRightName.appDataPaymentStatus    ),
    isAllowAppDataDelivery               : check.isNotDeny      ( userAccessRightName.appDataDelivery         ),
    isAllowAppDataDeliveryEdit           : check.accessRights.is( userAccessRightName.appDataDelivery         , userAccessRightValue.edit ),
    isAllowAppDataContactPerson          : check.isNotDeny      ( userAccessRightName.appDataContactPerson    ),
    isAllowAppDataWorkers                : check.isNotDeny      ( userAccessRightName.appDataWorkers          ),
    isAllowAppDataPayer                  : check.isNotDeny      ( userAccessRightName.appDataPayer            ),
    isAllowAppDataUser                   : check.isNotDeny      ( userAccessRightName.appDataUser             ),
    isAllowAppDataProps                  : check.isNotDeny      ( userAccessRightName.appDataProps            ),
    isAllowAppDataSoftwareProduct        : check.isNotDeny      ( userAccessRightName.appDataSoftwareProduct  ),
    isAllowAppDataRoomsCount             : check.isNotDeny      ( userAccessRightName.appDataRoomsCount       ),
    isAllowAppDataExecutorCompany        : check.isNotDeny      ( userAccessRightName.appDataExecutorCompany  ),
    isAllowAppDataSource                 : check.isNotDeny      ( userAccessRightName.appDataSource           ),
    isAllowAppDataActDate                : check.isNotDeny      ( userAccessRightName.appDataActDate          ),

    isAllowAppDataComments               : check.isNotDenyOr    ( [ userAccessRightName.appDataCommentsCommon, userAccessRightName.appDataCommentsPrivate ] ),
    isAllowAppDataCommentsCommon         : check.isNotDeny      ( userAccessRightName.appDataCommentsCommon   ),
    isAllowAppDataCommentsPrivate        : check.isNotDeny      ( userAccessRightName.appDataCommentsPrivate  ),
    isAllowAppDataCommentsCommonEdit     : check.accessRights.is( userAccessRightName.appDataCommentsCommon   , userAccessRightValue.edit ),
    isAllowAppDataCommentsPrivateEdit    : check.accessRights.is( userAccessRightName.appDataCommentsPrivate  , userAccessRightValue.edit ),

    isAllowAppDataDocsGeneration         : check.isNotDeny      ( userAccessRightName.appDataDocsGeneration   ),
    isAllowAppDataDocsPublished          : check.isNotDeny      ( userAccessRightName.appDataDocsPublished    ),
    isAllowAppDataDocsPublishedCreate    : check.accessRights.is( userAccessRightName.appDataDocsPublished    , userAccessRightValue.create ),
    isAllowAppDataDocsPaymentOrder       : check.isNotDeny      ( userAccessRightName.appDataDocsPaymentOrder ),
    isAllowAppDataDocsPaymentOrderCreate : check.accessRights.is( userAccessRightName.appDataDocsPaymentOrder , userAccessRightValue.create ),
    isAllowAppDataDocsPaymentOrderDelete : /*check.accessRights.is( userAccessRightName.appDataDocsPaymentOrder , userAccessRightValue.create ) &&*/ check.isNotDeny( userAccessRightName.appDataDocsPaymentOrderDelete ),
    isAllowAppDataDocsSigned             : check.isNotDeny      ( userAccessRightName.appDataDocsSigned       ),
    isAllowAppDataDocsSignedCreate       : check.accessRights.is( userAccessRightName.appDataDocsSigned       , userAccessRightValue.create ),
    isAllowAppDataDocsSignedDelete       : /*check.accessRights.is( userAccessRightName.appDataDocsSigned       , userAccessRightValue.create ) &&*/ check.isNotDeny( userAccessRightName.appDataDocsSignedDelete       ),
    isAllowAppDataDocsResult             : check.isNotDeny      ( userAccessRightName.appDataDocsResult       ),
    isAllowAppDataDocsResultCreate       : check.accessRights.is( userAccessRightName.appDataDocsResult       , userAccessRightValue.create ),


    isAllowPayer                         : check.isNotDeny      ( userAccessRightName.payer                   ),
    isAllowPayerEdit                     : check.accessRights.is( userAccessRightName.payer                   , userAccessRightValue.edit ),
    isAllowPayerUserInfo                 : check.isNotDeny      ( userAccessRightName.payerUserInfo           ),

    isAllowUserLoginAs                   : check.isNotDeny      ( userAccessRightName.userLoginAs             ),
    isAllowUserBonusProgram              : check.isNotDeny      ( userAccessRightName.userBonusProgram        ),
    isAllowUserPasswordReset             : check.isNotDeny      ( userAccessRightName.userPasswordReset       ),

    isAllowSysUpdateAccessButton         : check.isNotDeny      ( userAccessRightName.sysUpdateAccessButton   ),
    isAllowSysShowUserName               : check.isNotDeny      ( userAccessRightName.sysShowUserName         ),
    isAllowSysShowUserRole               : check.isNotDeny      ( userAccessRightName.sysShowUserRole         ),

    isAllowSysSettingsCore               : check.isNotDeny      ( userAccessRightName.sysSettingsCore         ),
    isAllowSysSettingsFormEdit           : check.isNotDeny      ( userAccessRightName.sysSettingsFormEdit     ),
    isAllowSysSettings                   : check.isNotDeny      ( userAccessRightName.sysSettings             ),

    isAllowSysHeadTitle                  : check.isNotDeny      ( userAccessRightName.sysHeadTitle            ),
    isAllowSysHeadPhone                  : check.isNotDeny      ( userAccessRightName.sysHeadPhone            ),
    isAllowSysHeadYoutubeLink            : check.isNotDeny      ( userAccessRightName.sysHeadYoutubeLink      ),

    // PROPS
    propAppList                          : check.accessRights.getProp    ( userAccessRightName.appList        ),
    propAppListUsersRoles                : check.accessRights.getPropList( userAccessRightName.appListUsers   ),

    propSysHeadTitle                     : check.accessRights.getProp    ( userAccessRightName.sysHeadTitle   , 'Фабрика'),
    propSysHeadPhone                     : check.accessRights.getProp    ( userAccessRightName.sysHeadPhone   ),
  };

};
