import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Element } from "../../../api/methods";
import {accent, lightgray, ultraLightgray} from "../../../constants/Colours";
import { setFavoriteElements } from "../../../store/favoriteElements/actions";
import { getFavoriteElements } from "../../../store/favoriteElements/selectors";
import SecondOrderButton from "../../../components/UI-kit/Buttons/SecondOrderButton";
import Text from "../../../components/UI-kit/Text";
import Tooltip from "../../../components/UI-kit/Tooltip";
import { T } from "../../../lang/ru";
import {useWait} from "../../../hooks/useWait";

const PanelWrapper = styled.div`
    position: fixed;
    right: 0;
    top: 20;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid ${lightgray};
    border-radius: 14px 0 0 14px;
    padding: 20px 0 30px 0;
    z-index: 1000;
    transition: all 300ms linear;
    -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
    ${(props) => props.isShowContent
        ? `
            width: 250px;
            max-height:600px;  
            height:600px;  
        `
        : `
            width: 55px;
            max-height:15px;
        `
    }
`;
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: static;
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;
    ${(props) => props.isShowContent
        ? `
            overflow-y: auto;
            visibility: visible;
        `
        : `
            overflow-y: hidden;
            visibility: collapse;
        `
    }
`;

const FavoriteItem = styled.div`
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    //overflow:hidden;
    &:nth-child(2n) {
        background-color: ${ultraLightgray};
    }
    &:hover {
        background-color: ##f5f5f5;
    }
    ${SecondOrderButton} {
        text-align: left;
    }
`;

const ToggleFavoriteButton = styled(Text)`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 20px;
    color: #737373;
    &:hover {
        color: ${accent};
    }
`;

export const FavoritesPanel = ({
    addElementFromFavorite,
    deleteFromFavorite,
}) => {
    const dispatch = useDispatch();
    const favoriteElements = useSelector(getFavoriteElements);
    const [isShowContent, setIsShowContent] = useState(true);
    const preloader = useWait();

    useEffect(() => {
        loadFavorites();
    }, []);

    const loadFavoritesSuccess = ({ data }) => {
        dispatch(setFavoriteElements(data));
        preloader.deactivate();
    };

    const loadFavorites = () => {
        preloader.activate();
        Element.getAllFavorites({}, loadFavoritesSuccess, () => preloader.deactivate() );
    };

    const toggleIsShowContent = () => setIsShowContent(!isShowContent);

    const handleDeleteFromFavorite = (element) => {
        const isWantToDelete = window.confirm(
            "Вы действительно хотите удалить элемент из избранного?"
        );
        if (isWantToDelete) deleteFromFavorite(element);
    };

    return (
        <PanelWrapper isShowContent={isShowContent}>
            <ToggleFavoriteButton onClick={toggleIsShowContent}>
                {isShowContent
                    ? <><span>&#9734;&nbsp;&nbsp;&nbsp;{T.favorites}</span><span>&gt;</span></>
                    : <Tooltip label={T.favorites} place={'left'}><span style={{color: accent}}>&#9733;</span></Tooltip>
                }
            </ToggleFavoriteButton>
            <ListWrapper isShowContent={isShowContent}>
                {preloader.isActive
                    ? <FavoriteItem>{preloader.wait()}</FavoriteItem>
                    : favoriteElements.map((element) => (
                        <FavoriteItem key={element.id}>
                            <SecondOrderButton onClick={() => addElementFromFavorite(element)} style={{maxHeight: 'none'}}>
                                {element.name}
                            </SecondOrderButton>
                            <SecondOrderButton onClick={() => handleDeleteFromFavorite(element)}>
                                &times;
                            </SecondOrderButton>
                        </FavoriteItem>
                    ))
                }
            </ListWrapper>
        </PanelWrapper>
    );
};
