// prettier-ignore-start
import React from "react";

import { T }                        from "../../../../../lang/ru";
import {getApplicationId }          from "../../../../../functions/getters";
import { formatDate }               from "../../../../../functions/common/common";
import { useUserAccessRightChecks } from "../../../../../hooks/useUserAccessRightChecks";
import ListFormat_2Lines            from "../../../../../components/Lists/Parts/ListFormat_2Lines";
import { TopRightLabel }            from "../TopRightLabel";
import { defaultResult }            from "./Common";


export const OrderNumber = () => {

    const userAccess = useUserAccessRightChecks();

    return {
        head : (rowData) => ListFormat_2Lines(T.num, T.contractDate),

        body : (rowData) => {
            const {contract_number='', new_chat_messages='', application_date=''} = rowData;

            return <>
                {ListFormat_2Lines(getApplicationId(contract_number), formatDate(application_date), true )}

                {(userAccess.isAllowChat && (new_chat_messages > 0)) &&
                    <TopRightLabel>+{new_chat_messages}</TopRightLabel>
                }
            </>
        },

        headProps : (rowData) => ({ className: "fixed-row fixed-col" }), //fixed-col

        bodyProps : (rowData) => ({
            ...defaultResult.bodyProps(rowData),

            isFixed: true,
            isNoBr : true,
        })
    };

};