import styled from "styled-components";
import { device } from "../../../constants/Breakpoints";
import { white } from "../../../constants/Colours";
import Button from "./Button";
import ButtonGroup from "./ButtonGroup";

const ButtonFixedGroup = styled(ButtonGroup)`
  margin: ${(props) => props.margin};
  @media ${device.tablet} {
    position: fixed;
    left    : 0;
    bottom  : 0;
    right   : 0;
    margin  : 0;

    & ${Button} {
      font-size       : 13px;
      margin          : 0;
      border-radius   : 0 0 0 0 !important;
      background-color: ${white} !important;
    }
  }
`;
export default ButtonFixedGroup;
