// prettier-ignore-start
import React, { useEffect, useState }   from "react";
import { useHistory }                   from "react-router-dom";

import {Auth, getPath, SystemRoles, User} from "../../../../api/methods";
import { useInputSelect }               from "../../../../hooks/useInputSelect";
import { useUserAccessRightChecks }     from "../../../../hooks/useUserAccessRightChecks";

import { PATHS }                        from "../../../../constants/RoutesPaths";
import { authSuccess }                  from "../../../../functions/auth";
import { dateTranslate, timeTranslate } from "../../../../functions/common";

import Tooltip                          from "../../../../components/UI-kit/Tooltip";
import LIF                              from "../../../../components/Lists/ListItemFormats";
import LIT                              from "../../../../components/Lists/ListItemTypes";
import List                             from "../../../../components/Lists/List";


const UsersList = () => {

    const userAccess    = useUserAccessRightChecks();
    const history       = useHistory();

    const [key, setKey] = useState(1);

    const filterRoles = (rolesList) => {
        return rolesList.filter( (item) => (item.sysname === '*') || userAccess.propAppListUsersRoles.includes(item.sysname))
    }

    const role = useInputSelect({
        name        : 'systemUserRole',
        title       : 'роль',
        defaultValue: 0,
        getOptions  : (data, success, fail) => SystemRoles.select(
            {hasUnset: 0, onlyActive: 1},
            (data) => {
                data.data.unshift({id: 0, name: '(все роли)', sysname: '*'});
                data.data = filterRoles(data.data);

                success(data);
            },
            fail
        ),
        width: 350
    });

    useEffect(() => {
        if (! role.isLoading && role.isLoaded) setKey((prev) => prev + 1);
    }, [role.value]);


    const commonFormat = (text1, subText) => <>{text1}<br /><small><i>{subText}</i></small></>;

    const fioTitle  = commonFormat('фамилия имя отчество', 'email, телефон');
    const fioJoin   = (item) => [item['surname'], item['name'], item['middlename']].join(' ');

    const fioFormat = (id, item, itemProps) => commonFormat(<b>{fioJoin(item)}</b>, [item['email'], item['phone']].filter((val) => (val && val !== '')).join(', '));

    const roleFormat  = (id, item, itemProps) => (item['role'] === null) ? '' : commonFormat(item['role']['title'], item['role']['sysname']);
    const dateFormat  = (id, item, itemProps) => commonFormat(dateTranslate(item['created_at']), timeTranslate(item['created_at']));
    const bonusFormat = (id, item, itemProps) =>
        (! item?.role?.has_bonus_program)
            ? ''
            : (! item?.bonus_program_status_id)
                ? <span style={{padding: 0, color: "lightgrey"}}>(не задан)</span>
                : commonFormat(
                    <>{item?.bonus_program_status_name}{(! item?.is_bonus_program_autoset) && <sup style={{padding: 0}}>*</sup>}</>,
                    item?.bonuses_count
                );
    const ratingsFormat = (id, item, itemProps) =>
        <>{(item) && <>{(item.ratings.length > 0)
            ? item.ratings.map( ({value, rating}) => <Tooltip label={rating?.title}>{value}</Tooltip>)
            : ( (item?.executor_group) ? '-' : null )
        }</> }</>

    const groupFormat = (id, item, itemProps) =>
        <>{(item?.executor_group) && <>{item.executor_group.name}</>}</>

    const roleOptions = (success) => SystemRoles.select( {hasUnset: 0, onlyActive: 1}, ({data}) => success(filterRoles(data)) );

    const loginAsUser   = (user_id) => {
        if (window.confirm(`Вы действительно хотите осуществить вход от имени пользователя?`))
            User.loginAs({id: user_id}, authSuccess, () => alert('Не удалось войти под пользователя'));
    }

    const resetPassword = (user_id) => {
        if (window.confirm(`Вы действительно хотите сбросить пароль пользователя?`))
            Auth.setNewPassword({ id: user_id }, (result) =>
                window.alert('Новый пароль: ' + result.data.password)
            );
    };

    const gotoCategories = (user_id) => history.push(getPath(PATHS.userCategories, {user_id}));
    const gotoBonuses    = (user_id) => history.push(getPath(PATHS.userBonuses   , {user_id}));
    const gotoRatings    = (user_id) => history.push(getPath(PATHS.userRatings   , {user_id}));


    return <List
        key         = {'Users'+key}
        popupPrefix = {'UsersList'}
        getListData = {(d, s, f) => User.listUsersByListRoleId({id: role.value}, s, f)}

        cols        = {[
            {name: 'id'        , title: 'id'                           , format: LIF.hidden  , type: LIT.number, canAddAndEdit: false},
            {name: 'created_at', title: <small>дата регистрации</small>, format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'left'  , customFormatHandler: dateFormat   },
            {name: 'fio'       , title: fioTitle                       , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'left'  , customFormatHandler: fioFormat    },
            {name: 'role'      , title: 'роль'                         , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'left'  , customFormatHandler: roleFormat   },
            {name: 'bonus'     , title: 'Бонусный статус'              , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'left'  , customFormatHandler: bonusFormat  },
            {name: 'group'     , title: 'Группа'                       , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'center', customFormatHandler: groupFormat },
            {name: 'ratings'   , title: 'Рейтинг'                      , format: LIF.linkEdit, type: LIT.text  , canAddAndEdit: false, align: 'center', customFormatHandler: ratingsFormat },

            {name: 'surname'   , title: 'фамилия'        , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'name'      , title: 'имя'            , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'middlename', title: 'отчество'       , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'email'     , title: 'email'          , format: LIF.hidden  , type: LIT.email , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'phone'     , title: 'телефон'        , format: LIF.hidden  , type: LIT.text  , canAddAndEdit: true , default: ''  , isValid: (val) => val !== ''},
            {name: 'role_id'   , title: 'роль'           , format: LIF.hidden  , type: LIT.select, canAddAndEdit: true , default: null, align: 'left', getOptions: roleOptions },

/*
            {name: 'applications_executor_count'         , title: 'испл', format: LIF.usageCounter},
            {name: 'applications_expert_count'           , title: 'эксп', format: LIF.usageCounter},
            {name: 'applications_count'                  , title: 'пльз', format: LIF.usageCounter},
            {name: 'payers_count'                        , title: 'плат', format: LIF.usageCounter},
            {name: 'access_rights_count'                 , title: 'прав', format: LIF.usageCounter},
*/
        ]}

        buttons     =  {[
            //{type: 'new'},
            {type: 'group', buttons: [
                    ...((role.options.length < 3) ? [] : [{type: 'custom', out: role.select}]),
            ]},
        ]}

        actions = {[
            {title: 'войти'       , onClick: loginAsUser   , canDo: () => userAccess.isAllowUserLoginAs       },
            {title: 'сброс пароля', onClick: resetPassword , canDo: () => userAccess.isAllowUserPasswordReset },
            {title: 'категории'   , onClick: gotoCategories, canDo: (item) => item?.role?.has_rating        ?? false },
            {title: 'бонусы'      , onClick: gotoBonuses   , canDo: (item) => item?.role?.has_bonus_program ?? false },
            {title: 'рейтинги'    , onClick: gotoRatings   , canDo: (item) => (item?.role.ratings ?? []).length > 0 },
        ]}

        //onNew       = {User.add   }
        onEdit      = {User.update}
        //onDelete    = {User.delete}

        canDelete   = {(item) => false }

        textOnDelete = {(item) => <>Удалить пользователя <b>{fioJoin(item)}</b>?</>}
    />

};

export default UsersList;
