// prettier-ignore-start
import ActionMessageForm     from "./Base/Forms/ActionMessageForm";
import ActionAppDataContract from "./Base/ActionAppDataContract";
import ActionFormType        from "./ActionFormType";


const ActionMessageFormAppDataContract = () => {

    const data     = ActionAppDataContract().data;
    const formCols = ActionAppDataContract().formCols;

    const form = (props) => ActionMessageForm().form({...props, onRun: ActionAppDataContract().onSave}, formCols);

    return {
        type    : ActionFormType.popupForm,

        data    : data,
        formCols: formCols,
        form    : form,
    }

};

export default ActionMessageFormAppDataContract;
