// prettier-ignore-start
const types = {
  SET_USER_ANSWER: 'SET_USER_ANSWER',
  SET_ALL_ANSWERS: 'SET_ALL_ANSWERS',
  SAVE_ANSWERS   : 'SAVE_ANSWERS',
  DELETE_9_ANSWER: 'DELETE_9_ANSWER',
  CLEAR_ANSWERS  : 'CLEAR_ANSWERS',
  IS_IN_VIEWPORT : "IS_IN_VIEWPORT"
};

export default types;
