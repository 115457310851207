// prettier-ignore-start
import React, { useState, useEffect } from 'react';

import { Col, Row }                            from 'styled-bootstrap-grid';

import { T }                                   from '../../../../lang/ru';
import { isValidEmailCheck,isValidPhoneCheck } from '../../../../functions/validators';
import { checkFullnessFields }                 from '../../../../functions/common/common';

import FloatingInput                           from '../../../../components/UI-kit/FloatingInput';
import Subtitle2                               from '../../../../components/UI-kit/Subtitle2';


const IndividualForm = ({
    setInputsValue,
    inputsValue = {},
    setIsDisabled,
}) => {
    const [isValid, setIsValid] = useState({
        inn     : false,
        kpp     : false,
        bik_bank: false,
        ras     : false,
        kor     : false,
        email   : false,
        phone   : false,
    });

    const checkValid = (e) => {
        const { value, name } = e.target;
        const validator = validators[name];
        if (validator) {
            setIsValid({ ...isValid, [name]: validator(value) });
        }
    };

    const validators = {
        inn     : (value) => !value || !value.match('^([0-9]{10,12})$'),
        kpp     : (value) => !value || !value.match('^([0-9]{9})$'),
        bik_bank: (value) => !value || !value.match('^([0-9]{9})$'),
        ras     : (value) => !value || !value.match('^([0-9]{20})$'),
        kor     : (value) => !value || !value.match('^([0-9]{20})$'),
        email   : (value) => !isValidEmailCheck(value),
        phone   : (value) => !isValidPhoneCheck(value),
    };

    const {
        name_org,
        inn,
        kpp,
        bank,
        bik_bank,
        ras,
        phone,
        email,
        name,
        surname,
        middlename,
        legal_adress,
        kor,
        base,
        position,
        deliveryAddressMatches,
    } = inputsValue;

    const requiredFields = [
        name_org,
        inn,
        kpp,
        bank,
        bik_bank,
        ras,
        phone,
        email,
        name,
        surname,
        middlename,
    ];

    useEffect(() => {
        checkDisabledButton();
    }, requiredFields);

    const checkDisabledButton = () => {
        // Есть ли пустые поля/ false – нет пустых, true есть пустые
        const isFullnessFields = checkFullnessFields(requiredFields);

        const isHasWrongValue = Object.values(isValid).includes(true);

        const isDisabled = isFullnessFields || isHasWrongValue;
        setIsDisabled(isDisabled);
    };

    const onChangeInput = (e) => {
        let { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
          value = checked;
        }
        checkValid(e);
        setInputsValue({ ...inputsValue, [name]: value });
    };


    return <>
        <Subtitle2 style={{ marginTop: 30 }}>
            {T.enterOrganizatioInformation}
        </Subtitle2>
        <Row>
            <Col>
                <FloatingInput
                    name        = "name_org"
                    value       = {name_org}
                    required    = {true}
                    placeholder = {T.nameOrganization}
                    onChange    = {onChangeInput}
                    tabIndex    = {101}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "legal_adress"
                    value       = {legal_adress}
                    placeholder = {T.legalAdress}
                    onChange    = {onChangeInput}
                    tabIndex    = {102}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name         = "inn"
                    value        = {inn}
                    required     = {true}
                    placeholder  = {T.INN}
                    wrong        = {isValid.inn}
                    wrongMessage = {'Формат (Например, 998877777766)'}
                    onChange     = {onChangeInput}
                    tabIndex     = {103}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name         = "kor"
                    value        = {kor}
                    placeholder  = {T.corNumber}
                    wrong        = {isValid.kor}
                    wrongMessage = {'Формат (Например, 30101643600000000957)'}
                    onChange     = {onChangeInput}
                    tabIndex     = {107}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name         = "kpp"
                    value        = {kpp}
                    required     = {true}
                    placeholder  = {T.KPP}
                    wrong        = {isValid.kpp}
                    wrongMessage = {'Формат (Например, 773101001)'}
                    onChange     = {onChangeInput}
                    tabIndex     = {104}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name         = "ras"
                    value        = {ras}
                    required     = {true}
                    placeholder  = {T.raschNumber}
                    wrong        = {isValid.ras}
                    wrongMessage = {'Формат (Например, 40817810099910004312)'}
                    onChange     = {onChangeInput}
                    tabIndex     = {108}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "bank"
                    value       = {bank}
                    required    = {true}
                    placeholder = {T.bank}
                    onChange    = {onChangeInput}
                    tabIndex    = {105}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name         = "phone"
                    value        = {phone}
                    required     = {true}
                    placeholder  = {T.phone}
                    wrong        = {isValid.phone}
                    wrongMessage = {T.inCorrectPhone}
                    onChange     = {onChangeInput}
                    tabIndex     = {109}
                />
            </Col>
        </Row>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name         = "bik_bank"
                    value        = {bik_bank}
                    required     = {true}
                    placeholder  = {T.bikBank}
                    wrong        = {isValid.bik_bank}
                    wrongMessage = {'Формат (Например, 044525957)'}
                    onChange     = {onChangeInput}
                    tabIndex     = {106}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name         = "email"
                    placeholder  = {T.email}
                    required     = {true}
                    onChange     = {onChangeInput}
                    value        = {email}
                    wrong        = {isValid.email}
                    wrongMessage = {T.inCorrectEmail}
                    tabIndex     = {110}
                />
            </Col>
        </Row>
        <Subtitle2 style={{ marginTop: 40 }}>
            {T.enterLeaderInformation}
        </Subtitle2>
        <Row>
            <Col col="auto">
                <FloatingInput
                    name        = "position"
                    value       = {position}
                    placeholder = {T.position}
                    onChange    = {onChangeInput}
                    tabIndex    = {111}
                />
            </Col>
            <Col col="auto">
                <FloatingInput
                    name        = "base"
                    value       = {base}
                    placeholder = {T.actingOnTheBasis}
                    onChange    = {onChangeInput}
                    tabIndex    = {112}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name        = "surname"
                    value       = {surname}
                    required    = {true}
                    placeholder = {T.surname}
                    onChange    = {onChangeInput}
                    tabIndex    = {113}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name        = "name"
                    value       = {name}
                    required    = {true}
                    placeholder = {T.name}
                    onChange    = {onChangeInput}
                    tabIndex    = {114}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingInput
                    name        = "middlename"
                    value       = {middlename}
                    required    = {true}
                    placeholder = {T.middleName}
                    onChange    = {onChangeInput}
                    tabIndex    = {115}
                />
            </Col>
        </Row>
    </>

};

export default IndividualForm;
