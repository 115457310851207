import {AccessRightsType, UseAccessRightsType} from "./useAccessRights";


// TYPES
export type AccessRightCheck      = (name: string   ) => boolean;
export type AccessRightCheckValue = (               ) => boolean;
export type AccessRightCheckList  = (names: string[]) => boolean;

export type AccessRightCheckValueList = {
  [key: string]: boolean;
};

export type UseAccessRightChecksType = {
  // права
  accessRights : UseAccessRightsType,

  // константы
  names        : AccessRightsType,
  values       : AccessRightsType,

  // проверки на стандартных значениях
  isDeny       : AccessRightCheck,
  isNotDeny    : AccessRightCheck,

  isAllow      : AccessRightCheck,
  isNotAllow   : AccessRightCheck,

  isDenyOr     : AccessRightCheckList,
  isNotDenyOr  : AccessRightCheckList,

  // результирующие проверки
  // +
};


// CONSTS
export const accessRightName = {
}

export const accessRightValue = {
  deny                : 'deny',
  allow               : 'allow',

  create              : 'create',
  read                : 'read',
  view                : 'view',
  edit                : 'edit',
  delete              : 'delete',
}


// MAIN
export const useAccessRightChecks: (accessRights: UseAccessRightsType) => UseAccessRightChecksType = (accessRights) => {

  const isDeny     : AccessRightCheck = (name) => accessRights.is   (name, accessRightValue.deny);
  const isNotDeny  : AccessRightCheck = (name) => accessRights.isNot(name, accessRightValue.deny);

  const isAllow    : AccessRightCheck = (name) => accessRights.is   (name, accessRightValue.allow);
  const isNotAllow : AccessRightCheck = (name) => accessRights.isNot(name, accessRightValue.allow);

  const isDenyOr   : AccessRightCheckList = (names) => names.reduce( (result, name) => result || accessRights.is   (name, accessRightValue.deny), false);
  const isNotDenyOr: AccessRightCheckList = (names) => names.reduce( (result, name) => result || accessRights.isNot(name, accessRightValue.deny), false);


  return {
    accessRights : accessRights,

    // константы
    names        : accessRightName,
    values       : accessRightValue,

    // проверки на стандартных значениях
    isDeny       : isDeny,
    isNotDeny    : isNotDeny,

    isAllow      : isAllow,
    isNotAllow   : isNotAllow,

    isDenyOr     : isDenyOr,
    isNotDenyOr  : isNotDenyOr,

    // результирующие проверки
    // +
  };

};
