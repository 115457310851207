import { useState } from "react";


// prettier-ignore
export function useActive(state) {
    const [isActive, setIsActive] = useState( state );

    const activate   = () => setIsActive(true );
    const deactivate = () => setIsActive(false);

    return {
        isActive   : isActive,
        activate   : activate,
        deactivate : deactivate,
    };
}
