import styled from 'styled-components'

interface Props {
    gap?: number
    justifyContent?: "center" | "space-between" | "space-around" | "evenly"
    stretchItems?: boolean
}

const Stack = styled.div<Props>`
    display: flex;
    gap: ${props => props?.gap ? props.gap : 15}px;
    justify-content: ${props => props?.justifyContent ? props.justifyContent : 'left'};
    align-items: center;
    ${props => props.stretchItems ? "& > * {flex: 1 1;}" : null}
`

export default Stack