// prettier-ignore-start
import {formatDate} from "../../../../../functions/common/common";


export const StatusInWorkDate = () => {

/*
  <th>Переведена в статус в работе</th>
      <td>{formatDate(work_status_date)}</td>
*/


    return {
        head : (rowData) => 'В работе с',
        body : (rowData) => formatDate(rowData.work_status_date ?? ''),
    };

};