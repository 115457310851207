// prettier-ignore-start
import React, {useState} from "react";

import {useDispatch}     from "react-redux";

import { Application }   from "../../../../api/methods";
import { openPopup }     from "../../../../store/popups/actions";
import { reloadPage }    from "../../../../functions/reloadPage";

import SecondOrderButton from "../../../../components/UI-kit/Buttons/SecondOrderButton";
import PopupEdit         from "../../../../components/Lists/PopupEdit";


const FormElement = ({
    canChange = true,
    popupName = 'changeElementPopup',
    title = '',
    name = '',
    value = '',
    text = '',
    action = Application.update,
    actionData = {},
    cols = [],
}) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(null);

    const openForm = () => {
        setData( {...actionData, [name]: value} );
        dispatch( openPopup ( popupName ));
    }

    const outText = () => {
        return (canChange)
            ? <SecondOrderButton
                  disabled = {false}
                  style    = {{ padding: 0 }}
                  onClick  = {openForm}
              >
                  {text}
              </SecondOrderButton>
            : text;
    }


    return {
        openForm : openForm,

        text     : outText(),

        form : <PopupEdit
            isNewPopup = {false}
            popupName  = {popupName}
            key        = {name}
            title      = {title}
            doText     = 'Сохранить'
            item       = {data}
            setItem    = {setData}
            cols       = {cols}
            action     = {(d, s, f) => action( {...actionData, ...d}, (d) => {
                s(d);
                reloadPage();
            }, f )}
        />
    }

};

export default FormElement;
