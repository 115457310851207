// prettier-ignore-start
import React from "react";

import FloatingInput    from "../../components/UI-kit/FloatingInput";


const ElementInput = ({name, title, value, onBlur, onChange, props}) => <FloatingInput
    {...props}
    name        = {name}
    value       = {value}
    placeholder = {title}
    onBlur      = {onBlur}
    onChange    = {onChange}
/>

export default ElementInput;
