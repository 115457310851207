// prettier-ignore-start
import React from "react";

import { useParams } from "react-router-dom";

import { SystemAccessRightsAssigned, User }    from "../../../api/methods";
import { useDataRequest }                      from "../../../hooks/useDataRequest";
import { PATHS }                               from "../../../constants/RoutesPaths";
import SettingsSystemAccessRightValuesAssigned from "./SettingsSystemAccessRightValuesAssigned";


const SettingsSystemUserAccessRights = () => {

    const { user_id } = useParams();

    const user = useDataRequest({
        autoLoad    : true,
        request     : User.getById,
        requestData : { id: user_id },
    });


    return <SettingsSystemAccessRightValuesAssigned
        type        = {'user'}

        title       = {<>Права пользователя "{user.data.id ? [user.data.surname, user.data.name, user.data.middlename].join(' ') : ' - '}"</>}
        backTo      = {PATHS.settings.systemUsers}

        getListData = {(data, success, fail) => SystemAccessRightsAssigned.getByUserId({         user_id}, success, fail)}
        onNew       = {(data, success, fail) => SystemAccessRightsAssigned.addToUser  ({...data, user_id}, success, fail)}
    />

};

export default SettingsSystemUserAccessRights;
