// prettier-ignore-start
import LIT             from "../../../../components/Lists/ListItemTypes";
import FormElementApp  from "./FormElementApp";


const FormElementAppText = ({
    canChange = true,
    title = '',
    name = '',
    value = '',
    text = '',
    id = 0,
}) => {

    const formElement = FormElementApp({
        id, canChange, title, name, value, text,
        cols : [{ name: name, title: title, type: LIT.textarea }],
    });

    return {
        openForm : formElement.openForm,
        text     : formElement.text,
        form     : formElement.form,
    }

};

export default FormElementAppText;
