// prettier-ignore-start
import React from 'react';
import { useHistory }             from 'react-router-dom';

import { PATHS }                  from '../../../constants/RoutesPaths';
import {getPath}                  from "../../../api/methods";
import {useUserAccessRightChecks} from "../../../hooks/useUserAccessRightChecks";
import {isEmpty}                  from "../../../functions/common";

import SecondOrderButton          from "../../../components/UI-kit/Buttons/SecondOrderButton";

type Props = {
    application_id: string,
    form_id       : string,
}


const OrderEditButton: React.FC<Props> = ({
    application_id = '',
    form_id = '',
}) => {
    const userAccess:any = useUserAccessRightChecks();

    const history  = useHistory();

    if (! userAccess.isAllowAppEdit) return null;

    const onClickChangeApplication = () => history.push( getPath(PATHS.orderEdit, { form_id, application_id }) );


    return <SecondOrderButton
        onClick   = {onClickChangeApplication}
        marginTop = "15px"
        className = "edit-button"
        marginRight
        disabled  = {isEmpty(application_id) || isEmpty(form_id)}
    >
        Изменить
    </SecondOrderButton>

};

export default OrderEditButton;
