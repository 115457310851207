// prettier-ignore-start
import React from "react";

import {Settings}       from "../../api/methods";

import {useDataRequest} from "../../hooks/useDataRequest";

import InputsGrid       from "../../components/UI-kit/InputsGrid";
import Subtitle2        from "../../components/UI-kit/Subtitle2";
import {Preloader}      from "../../components/UI-kit/Preloader/Preloader";
import ElementInput     from "../../components/Fields/ElementInput";


const defaultSettings = null;


const SettingsCommon = () => {

    const settings = useDataRequest({
        autoLoad     : true,
        request      : Settings.get,
        defaultResult: defaultSettings
    })

    const saveSettings = (e) => {
        const { value, name } = e.target;
        Settings.update( { [name]: value } );
    };

    const onChange = (e) => {
        const { value, name } = e.target;
        settings.setData({ ...settings.data, [name]: value });
    };


    if (settings.isLoading) return <Preloader />;
    return <>
        <Subtitle2>Системные настройки</Subtitle2>

        <InputsGrid maxWidth="710px" columns="1fr" gap="0">
            <wrapper disabled={false}>
                <ElementInput  title='Почта для чата'                       name='email_chat_events'     value={settings.data?.email_chat_events}     onChange={onChange} onBlur={saveSettings} />
                <ElementInput  title='Сроки для исполнения'                 name='deadline_days'         value={settings.data?.deadline_days}         onChange={onChange} onBlur={saveSettings} />
                <ElementInput  title='Общий телефон для связи'              name='phone'                 value={settings.data?.phone}                 onChange={onChange} onBlur={saveSettings} />
            </wrapper>
        </InputsGrid>
    </>

};

export default SettingsCommon;
