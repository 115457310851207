// prettier-ignore-start


export const formMode = {
    new       : 'new',
    edit      : 'edit',
}

export const fieldType = {
    group     : 'group',
    input     : 'input',
    fileImage : 'file_image',
}

export const isFileType = (type) => type === fieldType.fileImage;
