import styled from "styled-components";
import { accent, darkaccent, gray } from "../../../constants/Colours";
import Button from "./Button";
import SecondOrderButton from "./SecondOrderButton";

const ThirdOrderButton = styled(SecondOrderButton)`
  color: ${accent};
  padding: 5px 5px;
  border: none !important;
  :hover {
    background: transparent;
    color: ${darkaccent};
  }
  :active,
  :focus {
    background-color: "transparent";
    background-size: 100%;
    color: ${darkaccent};
    transition: background 0s;
  }
`;

export default ThirdOrderButton;
