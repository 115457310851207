// prettier-ignore-start
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled           from "styled-components";

import { lightgray }    from "../../../constants/Colours";
import getParent        from "../../../functions/getParentType";
import {
  deleteAnswer,
  saveAnswers
}                       from "../../../store/userAnswers/actions";
import {
  deleteSuperComplexElements,
}                       from "../../../store/elements/actions";
import { getAutosave }  from "../../../store/autosave/selectors";

import { DeleteButton } from "../../../components/UI-kit/Buttons/DeleteButton";
import GetContent       from "./getContent";

const PullWrapper = styled.div``;

const CrossWrapper = styled.div`
  cursor   : pointer;
  position : absolute;
  right    : -20px;
  top      : -35px;
  font-size: 34px;
  color    : #999;
  padding  : 10px;

  &:hover {
    color: #333;
  }
`;

const Cross = ({ onClick }) => <CrossWrapper onClick={onClick}>&times;</CrossWrapper>


const ComplexListWrapper = styled.div`
  position        : relative;
  border          : 1px solid green;
  margin          : 15px 0;
  padding         : ${(props) => (props.isPreviewMode ? "10px" : "0 40px")};
  border          : ${(props) => (props.isPreviewMode ? "" : `1px solid ${lightgray}`)};
  background-color: ${(props) => (props.isPreviewMode ? "" : "#eeeeee88")};
`;

const PullElements = ({ elements = [], systemNames = [] }) => {
  const dispatch = useDispatch();

  const isAutosave   = useSelector(getAutosave);
  const fullElements = useSelector((state) => state.elements);

  const getOrganizedElements = (parentElement) => {
    const result = [];
    if (parentElement) {
      if (parentElement.type === 9) {
        for (let i = 0; i < parentElement.repeat; i++) {
          let wrappedElems = [];
          for (let j = 0; j < parentElement?.defaultChildren?.length; j++) {
            wrappedElems.push(
              elements[j + i * parentElement?.defaultChildren?.length]
            );
          }

          result.push(wrappedElems);
        }
      }
    }

    return result;
  };

  const deleteGroupFunc = (elements) => {
    if (elements?.length) {
      dispatch(deleteAnswer(elements));
      dispatch(deleteSuperComplexElements(elements));
      elements.forEach(elem => {
        elem.childrens.forEach(child => {
          if (child.elems?.length) {
            deleteGroupFunc(child.elems)
          }
        })
      })

      if (isAutosave) dispatch(saveAnswers());
    }
    
  }

  const deleteGroup = (elements) => {
    if (window.confirm("Вы действительно хотите удалить блок?")) {
      dispatch(deleteAnswer(elements));
      dispatch(deleteSuperComplexElements(elements));

      if (isAutosave) dispatch(saveAnswers());
    }
  };

  const parentElement = getParent(fullElements, elements[0]);
  const organizedElements = getOrganizedElements(parentElement)
  
  
  // useMemo(
  //   () => getOrganizedElements(parentElement),
  //   [parentElement?.id, parentElement?.repeat]
  // );
  return (
    <PullWrapper>
      {parentElement?.type === 9 && parentElement?.defaultChildren?.length
        ? organizedElements.map((i, arrayIndex) => {
            return (
              <ComplexListWrapper key={arrayIndex}>
                <DeleteButton onClick={() => deleteGroup(i)}>
                  &times;
                </DeleteButton>

                {i.map((element, elementIndex) => {
                  return (
                    <GetContent
                      key          = {element.id}
                      element      = {element}
                      index        = {elementIndex + arrayIndex * i.length}
                      elements     = {elements}
                      systemNames  = {systemNames}
                      PullElements = {PullElements}
                    />
                  );
                })}
              </ComplexListWrapper>
            );
          })
        : elements.map((element, i) => {
            return (
              <GetContent
                key          = {element.id}
                element      = {element}
                index        = {i}
                elements     = {elements}
                systemNames  = {systemNames}
                PullElements = {PullElements}
              />
            );
          })}
    </PullWrapper>
  );
};

export default PullElements;
