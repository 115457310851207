// prettier-ignore-start
import LIT from "../../../../components/Lists/ListItemTypes";


const ActionComment = () => {

    const fieldName = 'comment';

    const data = (app, field = null) => ({
        [field ?? fieldName] : '',
    });

    const formCols = (props, field = null, titleComment = null) => ([
        { name: (field ?? fieldName), title: (titleComment ?? 'комментарий'), type: LIT.textarea },
    ]);

    return {
        data     : data,
        formCols : formCols
    }

};

export default ActionComment;
